import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { forkJoin, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TournamentsService } from '../core-services/tournaments/tournaments.service';
import { BaseComponent } from '../base.component';
import { Competition, Contest, LeaderBoardRequest } from '../core-services/tournaments/tournaments.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { UserStore } from '../core-services/user/user.store';
import { PromotionEnum } from '../promotions-today/promotions-today.model';

export const TOURNAMENTS_SLIDER_SELECTOR = 'whg-tournaments-slider';
const TABLE_WIDTH = 556;

@Component({
  selector: TOURNAMENTS_SLIDER_SELECTOR,
  templateUrl: './tournaments-slider.component.html',
  styleUrls: ['./tournaments-slider.component.scss'],
})
export class TournamentsSliderComponent extends BaseComponent implements OnInit {
  @ViewChild('slider') slider!: ElementRef;
  public arrowLeft = '/assets/icons/arrow-left.svg';
  public arrowRight = '/assets/icons/arrow-right.svg';
  public sliderScrollLeft = 0;
  public sliderScrollRight = 1;
  public isLoading = false;
  public tournaments: any[] = [];
  public contests = [];
  public isLoggedIn = false;
  public userId!: number | undefined;

  constructor(
    private tournamentsService: TournamentsService,
    private userStore: UserStore,
    private visitorStore: VisitorStore
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeUser();
    this.getCompetitions();
  }

  public scrollRight(): void {
    this.slider.nativeElement.scrollTo({
      left: this.slider.nativeElement.scrollLeft + TABLE_WIDTH,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.slider.nativeElement.scrollTo({
      left: this.slider.nativeElement.scrollLeft - TABLE_WIDTH,
      behavior: 'smooth',
    });
  }

  private getCompetitions(): void {
    this.tournamentsService.getCompetitions().subscribe(({ data }) => this.getContests(data));
  }

  private getContests(competitions: Competition[]): void {
    forkJoin([
      ...competitions.map(competition => this.tournamentsService.getCompetition(competition.id)),
    ]).subscribe(contestsData => {
      let contests: (Contest & { tournament: Competition })[] = [].concat.apply(
        [],
        contestsData.map(contest => contest.data.contests.filter(c => c.statusCode === 2) as any)
      );

      forkJoin([
        ...contests.map((contest: Contest) =>
          this.tournamentsService.getTournamentLeaderboard(contest.id)
        ),
      ]).subscribe((leaderboards: LeaderBoardRequest[]) => {
        contests = contests.map((contest: Contest, index: number) => {
          let hasOptIn = false;
          const tournament = contestsData.map(tour => tour.data).find(tour => tour.id === contest.competitionId) as Competition;

          if (this.userId && tournament.optinRequired) {
            this.tournamentsService.getOptedInUsers(this.userId, tournament.id).subscribe(
              ({ data: { memberRefId } }) => {
                hasOptIn = memberRefId === (this.userId && this.userId.toString());
                tournament.optinRequired = hasOptIn ? false : tournament.optinRequired;
              },
              err => console.log('err', err)
            );
          }
          return {
            ...contest,
            leaderboard: leaderboards[index].data,
            scheduledEnd: contestsData[index].data.scheduledEnd,
            tournament
          };
        });

        const validContests = contests.filter(contest => Object.values(PromotionEnum).includes(contest.tournament.metadata.promo));
        this.tournaments = validContests;
        this.subscribeSlider();
      });
    });
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this.userStore.userDetails$
          .pipe(takeUntil(this.destroy$))
          .subscribe(details => (this.userId = details?.userid));
      }
    });
  }

  private subscribeSlider(): void {
    fromEvent(this.slider.nativeElement, 'scroll', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getSliderScrollPosition());
  }

  private getSliderScrollPosition(): void {
    this.sliderScrollLeft = this.slider.nativeElement.scrollLeft;
    this.sliderScrollRight =
      this.slider.nativeElement.scrollWidth -
      this.slider.nativeElement.scrollLeft -
      this.slider.nativeElement.getBoundingClientRect().width;
  }
}
