<footer class="container">
  <ul class="links">
    <ng-container *ngFor="let link of links">
      <li *ngIf="isVisible(link)" [classList]="link?.class ? link.class : ''">
        <a [href]="link.url" [target]="link.target" class="">{{ link.text | vegastranslate }}</a>
      </li>
    </ng-container>
  </ul>

  <whg-payment-methods-list></whg-payment-methods-list>

  <ul class="country-container">
    <li *ngFor="let item of country$ | async">
      <a [href]="item.url">
        <span class="country-logo" [inlineSVG]="item.logo"></span>
        <span class="country-text small">{{ item.text | vegastranslate }}</span>
      </a>
    </li>
  </ul>

  <div class="company-logo-container">
    <a [href]="companyLogo.url">
      <img [src]="companyLogo.logo" />
    </a>
    <p class="copyright">{{ copyright }}</p>
  </div>

  <div class="bottom-container">
    <div class="mga-container logo-item">
      <a [href]="mgaLogo.url" [target]="mgaLogo.target">
        <img class="mga-logo" [src]="mgaLogo.logo" />
      </a>
    </div>
    <div class="managed-text">
      <p>{{ managedText }}}</p>
    </div>
    <div class="gambling-commission-container logo-item">
      <a [href]="gamblingCommission.url" [target]="gamblingCommission.target">
        <img class="gambling-commission-logo" [src]="gamblingCommission.logo" />
      </a>
    </div>
    <div class="licence-text">
      <p [innerHTML]="licenseText"></p>
    </div>

    <div class="responsble-gambling-text item">
      <p [innerHTML]="responsibleGambling"></p>
    </div>
    <div class="warning-container logo-item">
      <ng-container *ngFor="let item of gamblingWarning">
        <a [href]="item.url" [target]="item.target">
          <img [class]="item.style" [src]="item.logo" />
        </a>
      </ng-container>
    </div>
  </div>
</footer>
