export interface HighlightsState {
  highlights?: Highlights[];
}

export interface HighlightsJson {
  compHighlights: Highlights[];
}

export interface Highlights {
  title: string;
  uid: number;
  enabled: boolean;
  subtitle: string;
  dateStart: string;
  dateEnd: string;
  languages: Language[];
  id: number;
}

export interface Language {
  lang: string;
  name: string;
  subtitle: string;
  details: string;
  type: string;
  termsUk: string;
  termsRow: string;
  salientUk: string;
  salientRow: string;
  sliderImage: string;
  modalImage: string;
  files: string[];
}
