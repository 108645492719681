import { PaymentOption } from '../core-services/payment-options/payment-options.model';

interface PaymentMethodType {
  [key: string]: PaymentOption[] | undefined;
}

export const PaymentMethodsGeotargettingOrder: PaymentMethodType = {
  at: [
    PaymentOption.PaySafeCard,
    PaymentOption.Sofort,
    PaymentOption.EPS,
    PaymentOption.Mastercard,
    PaymentOption.Visa,
    PaymentOption.Maestro,
    PaymentOption.RapidTransfer,
    PaymentOption.Skrill,
  ],
  ch: [
    PaymentOption.Neteller,
    PaymentOption.Skrill,
    PaymentOption.Visa,
    PaymentOption.PaySafeCard,
    PaymentOption.MuchBetter,
    PaymentOption.Mastercard,
    PaymentOption.BancoEstado,
    PaymentOption.CajaVecina,
  ],
  cl: [
    PaymentOption.Neteller,
    PaymentOption.Skrill,
    PaymentOption.Visa,
    PaymentOption.PaySafeCard,
    PaymentOption.MuchBetter,
    PaymentOption.Mastercard,
    PaymentOption.BancoEstado,
    PaymentOption.CajaVecina,
  ],
  ca: [
    PaymentOption.InteracEtransfer,
    PaymentOption.Visa,
    PaymentOption.Interac,
    PaymentOption.Mastercard,
    PaymentOption.MuchBetter,
    PaymentOption.PaySafeCard,
    PaymentOption.Neosurf,
    PaymentOption.ApplePay,
  ],
  de: [
    PaymentOption.OpenBanking,
    PaymentOption.OnlineUberwesiung,
    PaymentOption.Mastercard,
    PaymentOption.MuchBetter,
    PaymentOption.Visa,
    PaymentOption.Klarna,
  ],
  fi: [
    PaymentOption.EBankingFI,
    PaymentOption.Visa,
    PaymentOption.Mastercard,
    PaymentOption.PaySafeCard,
    PaymentOption.ApplePay,
    PaymentOption.Neteller,
    PaymentOption.Skrill,
  ],
  gb: [
    PaymentOption.Visa,
    PaymentOption.Paypal,
    PaymentOption.Mastercard,
    PaymentOption.ApplePay,
    PaymentOption.PaySafeCard,
    PaymentOption.Skrill,
    PaymentOption.Neteller,
  ],
  ie: [
    PaymentOption.Visa,
    PaymentOption.Mastercard,
    PaymentOption.PaySafeCard,
    PaymentOption.ApplePay,
    PaymentOption.Skrill,
    PaymentOption.Neosurf,
    PaymentOption.Neteller,
    PaymentOption.Paypal,
  ],
  in: [
    PaymentOption.Neteller,
    PaymentOption.Mastercard,
    PaymentOption.Visa,
    PaymentOption.Skrill,
    PaymentOption.Rupay,
    PaymentOption.MuchBetter,
  ],
  no: [PaymentOption.Visa, PaymentOption.Mastercard, PaymentOption.Neosurf],
  nz: [
    PaymentOption.Visa,
    PaymentOption.PaySafeCard,
    PaymentOption.Mastercard,
    PaymentOption.ApplePay,
    PaymentOption.Skrill,
    PaymentOption.Neosurf,
    PaymentOption.Maestro,
  ],
  se: [
    PaymentOption.Visa,
    PaymentOption.Mastercard,
    PaymentOption.Paypal,
    PaymentOption.Neosurf,
    PaymentOption.PaySafeCard,
    PaymentOption.ApplePay,
    PaymentOption.Skrill,
  ],
};
