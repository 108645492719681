import { Component } from '@angular/core';

export const ACCORDION_SELECTOR = 'whg-accordion';

@Component({
  selector: ACCORDION_SELECTOR,
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss']
})
// wrapper component, if we need additional logic later to control accordion items, we can implement here
export class AccordionComponent {

  constructor() {
  }
}
