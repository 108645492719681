<div *ngIf="!isLoading" class="whg-tournaments-slider">
  <div class="whg-tournaments-slider__header">
    <h2>{{ isLoggedIn ? ('Your ' | vegastranslate) : null }} {{ 'Active Tournaments' | vegastranslate }}</h2>
    <span class="whg-tournaments-slider__actions">
      <button
        [ngClass]="{ 'whg-tournaments-slider__actions--disable': sliderScrollLeft === 0 }"
        [inlineSVG]="arrowLeft"
        (click)="scrollLeft()"
      ></button>
      <button
        [ngClass]="{ 'whg-tournaments-slider__actions--disable': sliderScrollRight === 0 }"
        [inlineSVG]="arrowRight"
        (click)="scrollRight()"
      ></button>
    </span>
  </div>

  <div #slider class="whg-tournaments-slider__tournaments">
    <ng-container *ngFor="let tournament of tournaments">
      <whg-tournament class="position-relative" [contest]="tournament" [userId]="userId" [isLoggedIn]="isLoggedIn">
      </whg-tournament>
    </ng-container>
  </div>
</div>
