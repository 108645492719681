import { IconConfig, HeaderConfig, HeaderItemType, HeaderTypes, LogoConfig, LoginConfig } from './header.models';

export const logoConfig: LogoConfig = {
  url: '/',
  img: '/assets/header/logo.svg',
};

export const accountIconConfig: IconConfig = {
  url: '/account',
  icon: '/assets/icons/account.svg',
};

export const messageIconConfig: IconConfig = {
  url: '/inbox',
  icon: '/assets/header/messages.svg',
};
export const logoutIconConfig: IconConfig = {
  url: '/logout',
  icon: '/assets/icons/logout.svg',
};

export const loginConfig: LoginConfig = {
  url: '/login',
};

export const headerConfig: HeaderConfig = {
  logout: logoutIconConfig,
  message: messageIconConfig,
  logo: logoConfig,
  account: accountIconConfig,
  login: loginConfig,
  menu: {
    [HeaderTypes.LoggedOut]: [
      {
        url: '/',
        text: 'Home',
        icon: '/assets/icons/home.svg',
        styles: 'header-icons-tablet home on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/games',
        text: 'Games',
        icon: '/assets/icons/games.svg',
        styles: 'header-icons-tablet games on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/promotions',
        text: 'Promotions',
        icon: '/assets/icons/promotions.svg',
        styles: 'header-icons-tablet promotions on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/loyalty',
        text: 'Loyalty',
        icon: '',
        styles: 'no-icon-header loyalty on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/vip',
        text: 'VIP',
        icon: '',
        styles: 'no-icon-header vip on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/support',
        text: 'Support',
        icon: '/assets/icons/support.svg',
        styles: 'header-icons-tablet support on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '#chat-now',
        text: 'Live Chat',
        icon: '',
        styles: 'no-icon-header chat on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/create-account',
        text: 'Register',
        icon: '/assets/icons/wallet.svg',
        styles: 'header-icons-tablet register on-hover',
        type: HeaderItemType.Anchor,
      },
    ],
    [HeaderTypes.LoggedIn]: [
      {
        url: '/',
        text: 'Home',
        icon: '/assets/icons/home.svg',
        styles: 'header-icons-tablet home on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/games',
        text: 'Games',
        icon: '/assets/icons/games.svg',
        styles: 'header-icons-tablet games on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/promotions',
        text: 'Promotions',
        icon: '/assets/icons/promotions.svg',
        styles: 'header-icons-tablet promotions on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/loyalty',
        text: 'Loyalty',
        icon: '',
        styles: 'no-icon-header loyalty on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/vip',
        text: 'VIP',
        icon: '',
        styles: 'no-icon-header vip on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '/support',
        text: 'Support',
        icon: '/assets/icons/support.svg',
        styles: 'header-icons-tablet support on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '#chat-now',
        text: 'Live Chat',
        icon: '',
        styles: 'no-icon-header chat on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '',
        text: 'Deposit',
        icon: '/assets/icons/wallet.svg',
        styles: 'cashier-link header-icons-tablet deposit',
        type: HeaderItemType.Button,
      },
    ],
    [HeaderTypes.Restricted]: [
      {
        url: '/',
        text: 'Home',
        icon: '/assets/icons/home.svg',
        styles: 'header-icons-tablet home on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '',
        text: 'Games',
        icon: '/assets/icons/games.svg',
        styles: 'header-icons-tablet games on-hover',
        type: HeaderItemType.Disabled,
      },
      {
        url: '',
        text: 'Promotions',
        icon: '/assets/icons/promotions.svg',
        styles: 'header-icons-tablet promotions on-hover',
        type: HeaderItemType.Disabled,
      },
      {
        url: '',
        text: 'Loyalty',
        icon: '',
        styles: 'no-icon-header loyalty on-hover',
        type: HeaderItemType.Disabled,
      },
      {
        url: '',
        text: 'VIP',
        icon: '',
        styles: 'no-icon-header vip on-hover',
        type: HeaderItemType.Disabled,
      },
      {
        url: '',
        text: 'Support',
        icon: '/assets/icons/support.svg',
        styles: 'header-icons-tablet support on-hover',
        type: HeaderItemType.Disabled,
      },
      {
        url: '#chat-now',
        text: 'Live Chat',
        icon: '',
        styles: 'no-icon-header chat on-hover',
        type: HeaderItemType.Anchor,
      },
      {
        url: '',
        text: 'Deposit',
        icon: '/assets/icons/wallet.svg',
        styles: 'deposit-cta cashier-link deposit',
        type: HeaderItemType.Disabled,
      },
    ],
  },
};
