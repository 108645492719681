import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Ics } from './ics.model';

@Injectable({
  providedIn: 'root',
})
export class IcsService {
  public createIcs(inputs: Ics): void {
    const { fileName } = inputs;

    const file = this.generateFile(inputs);
    this.download(file, fileName);
  }

  private generateFile(inputs: Ics): File {
    const { summary, description, eventId, fileName, startDate, endDate } = inputs;

    const start = moment(startDate);
    const end = moment(endDate);
    const startDateFormatted = `${start.format('YYYYMMDTHHmmss')}`;
    const endDateFormatted = `${end.format('YYYYMMDTHHmmss')}`;

    const ics: BlobPart =
      'BEGIN:VCALENDAR\n' +
      'CALSCALE:GREGORIAN\n' +
      'METHOD:PUBLISH\n' +
      'PRODID:-//Test Cal//EN\n' +
      'VERSION:2.0\n' +
      'BEGIN:VEVENT\n' +
      `UID:${eventId}\n` +
      `DTSTART;VALUE=DATE:${startDateFormatted}\n` +
      `DTEND;VALUE=DATE:${endDateFormatted}\n` +
      `SUMMARY:${summary}\n` +
      `DESCRIPTION:${description}\n` +
      'END:VEVENT\n' +
      'END:VCALENDAR';

    return new File([ics], fileName, { type: 'text/calendar' });
  }

  private download(ics: File, fileName: string): void {
    const objectURL: string = URL.createObjectURL(ics);
    const anchor: HTMLAnchorElement = document.createElement('a');

    anchor.href = objectURL;
    anchor.setAttribute('download', fileName);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
