import { PromotionEnum } from './promotions-today.model';

export const getPromoColor = (promo: PromotionEnum) => {
  if (promo === PromotionEnum.CashDrop) {
    return 'var(--lightish-blue)';
  }
  if (promo === PromotionEnum.Cashback) {
    return 'var(--lightish-green)';
  }
  if (promo === PromotionEnum.LoyaltyBooster) {
    return 'var(--pink-gold)';
  }
  if (promo === PromotionEnum.MonthEndDoubleTake) {
    return 'var(--purple)';
  }
  if (promo === PromotionEnum.NewGameSpin) {
    return 'var(--pink)';
  }
  if (promo === PromotionEnum.RealWorldRewards) {
    return 'var(--dull-orange)';
  }
  if (promo === PromotionEnum.RushHour) {
    return 'var(--light-sky-blue)';
  }
  if (promo === PromotionEnum.ScratchCard) {
    return 'var(--dark-rose)';
  }
  if (promo === PromotionEnum.SpinTheWheel) {
    return 'var(--redish)';
  }
  if (promo === PromotionEnum.SuperSlotsSunday) {
    return 'var(--sea)';
  }
  if (promo === PromotionEnum.VegasLoungeVault) {
    return 'var(--warm-grey)';
  }
  if (promo === PromotionEnum.WinAnExperience) {
    return 'var(--yellow)';
  }
  return 'transparent';
}