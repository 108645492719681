// Assign values to this enum as those values are also used by the BE
export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum Jurisdiction {
  UKGC = 'ukgc',
  SGA = 'sga',
  MGA = 'mga',
  DE = 'de',
}

export interface PaymentMethods {
  name: string;
  icon: string;
}

export interface VisitorState {
  deviceType: DeviceType | undefined;
  jurisdiction: Jurisdiction;
  currencyCode: string;
  currencySymbol?: string;
  countryCode: string;
  paymentMethods?: Map<string, PaymentMethods>;
  loggedIn: boolean;
}
