import { TileInterface } from '../tile/tile.model';

export interface TileSlider {
  category: string;
  title: string;
  subTitle: string;
  link: string;
  truncate?: boolean;
  tiles: TileInterface[];
}

export interface TileSliderConfig {
  category: string;
  title: string;
  subTitle: string;
  link: string;
  truncate?: boolean;
  contentType: TileSliderContentType;
}

export enum TileSliderContentType {
  GAMES = 'games',
  ROOMS = 'rooms',
  WINS = 'wins',
  FAVOURITES = 'favourites',
}
