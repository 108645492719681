import { Component, Input } from '@angular/core';

export const ACCORDION_ITEM_SELECTOR = 'whg-accordion-item';

@Component({
  selector: ACCORDION_ITEM_SELECTOR,
  templateUrl: 'accordion-item.component.html',
  styleUrls: ['accordion-item.component.scss']
})
export class AccordionItemComponent {
  @Input() title: string = '';
  public isAccordionItemOpened = false;

  public toggleAccordionItem(): void {
    this.isAccordionItemOpened = !this.isAccordionItemOpened;
  }
}
