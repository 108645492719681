<header>
  <nav>
    <ul class="header-list">
      <li *ngIf="isShowHeader" class="menu-icon">
        <button (click)="openMenu()" [inlineSVG]="menuIcon"></button>
      </li>
      <li class="logo">
        <a href="{{ config.logo.url }}">
          <img src="{{ config.logo.img }}" />
        </a>
      </li>
      <ul *ngIf="isLoggedIn$ | async" class="status-bar-list d-none d-lg-none">
        <li>Loyalty Points: {{ balance?.loyalty }}</li>
        <li>Cash: {{ currencyCode }}{{ balance?.cash }}</li>
        <li>Bonus: {{ currencyCode }}{{ balance?.bonus }}</li>
      </ul>
      <ng-container *ngIf="isShowHeader">
        <ng-container *ngFor="let item of menu">
          <!--        links-->
          <li [ngClass]="[page === item.text.toLowerCase() ? 'items item-active' : 'items', item.styles]">
            <a *ngIf="item.type === headerItemTypes.Anchor" [href]="item.url">
              <ng-template *ngTemplateOutlet="HeaderItemRef; context: { $implicit: item }"></ng-template>
            </a>
            <!--          button-->
            <button *ngIf="item.type === headerItemTypes.Button">
              <ng-template *ngTemplateOutlet="HeaderItemRef; context: { $implicit: item }"></ng-template>
            </button>
            <!--          restricted/disabled-->
            <button *ngIf="item.type === headerItemTypes.Disabled" disabled>
              <ng-template *ngTemplateOutlet="HeaderItemRef; context: { $implicit: item }"></ng-template>
            </button>
          </li>
        </ng-container>
        <li
          class="message-icon xtreme-push-trigger-custom"
          (click)="openMessages()"
          [ngClass]="{ 'message-icon__new-message': hasNewMessages }"
          *ngIf="loggedIn$ | async"
        >
          <button class="icon" [inlineSVG]="config.message.icon"></button>
        </li>
      </ng-container>
      <li class="account-icon" *ngIf="loggedIn$ | async">
        <a [inlineSVG]="config.account.icon" [href]="config.account.url"></a>
      </li>
      <li class="logout-icon" *ngIf="loggedIn$ | async">
        <a [inlineSVG]="config.logout.icon" [href]="config.logout.url"></a>
      </li>
      <li class="account-icon" *ngIf="!(loggedIn$ | async)">
        <a [inlineSVG]="config.account.icon" [href]="config.login.url"></a>
      </li>
    </ul>
  </nav>
</header>
<ng-template #HeaderItemRef let-item>
  <span [ngClass]="['header-icons']" [inlineSVG]="item.icon"></span>
  <span
    class="header-texts"
    [ngClass]="{
      'register-item-text': item.text === 'Register',
      'btn btn-primary': item.text === 'Deposit'
    }"
  >
    {{ item.text | vegastranslate }}
  </span>
</ng-template>
