<div class="whg-tournament">
  <div>
    <div class="whg-tournament__header">
      <h3>{{ contest.label | vegastranslate }}</h3>
      <div class="whg-tournament__header__subtitle">
        <div class="whg-tournament__header__subtitle__remaining">
          <div>{{ remainingTime }} {{ 'remaining' | vegastranslate }}</div>
        </div>
        <div>
          <button
            class="btn btn-sm mr-1 btn-white"
            [ngClass]="{ invisible: !isTodayPromotion }"
            (click)="openTournamentDetails(contest.competitionId)"
          >
            {{ 'Details' | vegastranslate }}
          </button>

          <ng-container *ngIf="!contest.tournament.optinRequired && isLoggedIn">
            <span class="whg-tournament__header__subtitle__entered">• {{ 'Entered' | vegastranslate }}</span>
          </ng-container>
          <ng-container *ngIf="contest.tournament.optinRequired && isLoggedIn">
            <button class="btn btn-primary btn-sm" [ngClass]="{ invisible: !isTodayPromotion }" (click)="takePart()">
              {{ 'Take Part' | vegastranslate }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>{{ 'Rank' | vegastranslate }}</th>
        <th>{{ 'Name' | vegastranslate }}</th>
        <th></th>
        <th>{{ 'Points' | vegastranslate }}</th>
        <th>{{ 'Prize' | vegastranslate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of contest.leaderboard; let i = index"
        [ngClass]="{ 'whg-tournament--user': isLoggedIn && userId?.toString() === row.memberRefId }"
      >
        <td>
          <span
            [ngClass]="{
              'whg-tournament--first': i === 0,
              'whg-tournament--second': i === 1,
              'whg-tournament--third': i === 2
            }"
          >
            {{ row.rank }}
          </span>
        </td>
        <td>{{ row.name }}</td>
        <td>
          <span *ngIf="row.change > 0" class="whg-tournament__arrow whg-tournament__arrow--up"></span>
          <span *ngIf="row.change === 0">•</span>
          <span *ngIf="row.change < 0" class="whg-tournament__arrow whg-tournament__arrow--down"></span>
        </td>
        <td>{{ row.points }}</td>
        <td>{{ rewards[i] && rewards[i].value }}</td>
      </tr>
    </tbody>
  </table>
  <div class="whg-tournament__footer">
    <a href="/playerprotection">18+.</a>
    <a (click)="openTournamentDetails(contest.competitionId)">{{ 'Full terms apply' | vegastranslate }}.</a>
    {{ 'Please play responsibly' | vegastranslate }}.
    <a target="_blank" href="https://www.gamblingtherapy.org">gamblingtherapy.org</a>
  </div>
</div>
