export const countryCurrencyCodes = {
  AD: 'EUR',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AX: 'EUR',
  BE: 'EUR',
  BL: 'EUR',
  BQ: 'USD',
  BR: 'BRL',
  BV: 'NOK',
  CA: 'CAD',
  CC: 'AUD',
  CH: 'CHF',
  CK: 'NZD',
  CL: 'CLP',
  CX: 'AUD',
  CY: 'EUR',
  DE: 'EUR',
  EC: 'USD',
  EE: 'EUR',
  ES: 'EUR',
  FI: 'EUR',
  FM: 'USD',
  FR: 'EUR',
  GB: 'GBP',
  GF: 'EUR',
  GG: 'GBP',
  GP: 'EUR',
  GR: 'EUR',
  GU: 'USD',
  IE: 'EUR',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IT: 'EUR',
  JE: 'GBP',
  KI: 'AUD',
  LI: 'CHF',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  MC: 'EUR',
  ME: 'EUR',
  MF: 'EUR',
  MH: 'USD',
  MP: 'USD',
  MQ: 'EUR',
  MT: 'EUR',
  NF: 'AUD',
  NL: 'EUR',
  NO: 'NOK',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  PE: 'PEN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PT: 'EUR',
  PW: 'USD',
  RE: 'EUR',
  SE: 'SEK',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SM: 'EUR',
  TC: 'USD',
  TF: 'EUR',
  TK: 'NZD',
  TL: 'USD',
  TV: 'AUD',
  UM: 'USD',
  US: 'USD',
  VA: 'EUR',
  VG: 'USD',
  VI: 'USD',
  WS: 'USD',
  YT: 'EUR',
  ZA: 'ZAR',
  HM: 'AUD',
  AQ: 'USD',
} as const;

export type CountryCodesType = keyof typeof countryCurrencyCodes;
export type CurrencyCodesType = typeof countryCurrencyCodes[CountryCodesType];
