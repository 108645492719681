<div *ngIf="!showSms || !showEmail" class="whg-promotion-notifier">
  <div class="d-flex justify-content-start">
    <div>
      <h3>{{ 'Get Notified' | vegastranslate }}</h3>
    </div>
  </div>
  <div class="d-flex justify-content-start marketing-preferences-container">
    <div *ngIf="!showSms" class="custom-control custom-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="channelSMS"
        [checked]="promoMarketing.sms"
        (click)="toggleChannel(channelType.sms)"
      />
      <label class="custom-control-label" for="channelSMS">SMS</label>
    </div>
    <div *ngIf="!showEmail" class="custom-control custom-switch">
      <input
        type="checkbox"
        class="custom-control-input"
        id="channelEmail"
        [checked]="promoMarketing.email"
        (click)="toggleChannel(channelType.email)"
      />
      <label class="custom-control-label" for="channelEmail">Email</label>
    </div>
  </div>
  <p>{{ 'Switch on to be the first to receive updates on new promotions.' | vegastranslate }}</p>
  <p class="text2">
    {{ 'You can edit your communication preferences at any time in the My Account section.' | vegastranslate }}
  </p>
</div>
