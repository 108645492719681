import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';

import { VisitorStore } from '../visitor/visitor.store';
import { FavoritesStore } from './favorites.store';
import { GamesStore } from '../games/games.store';
import { Game } from '../games/games.model';

const LOCAL_STORAGE_KEY = 'vlc';

@Injectable({
  providedIn: 'root',
})
export class FavouritesService {
  constructor(
    private favouritesStore: FavoritesStore,
    private gamesStore: GamesStore,
    private visitorStore: VisitorStore
  ) {}

  getLocalFavourites(): string[] {
    const localStorageItem = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!localStorageItem) {
      return [];
    }

    try {
      const favouriteKey: Record<string, any> = JSON.parse(localStorageItem);
      if (typeof favouriteKey === 'object' && favouriteKey.hasOwnProperty('favourites')) {
        return favouriteKey.favourites.split(',').filter((f: string) => f !== '');
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  updateLocalFavourites(value: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  }

  /**
   * Merges Favourite games from logged out users into the user account after users loges in
   */
  mergeFavouriteGames(): void {
    this.visitorStore.loggedIn$.pipe(first()).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        const localFavourites = this.getLocalFavourites();

        this.gamesStore
          .getGamesByLaunchCode(localFavourites)
          .pipe(filter((x: Game[]) => !!x && x.length > 0))
          .subscribe(games => {
            this.favouritesStore
              .addFavourites(games)
              .pipe(first())
              .subscribe(() => {
                this.cleanLocalFavourites();
              });
          });
      }
    });
  }

  isLocalFavourite(launchCode: string): boolean {
    return this.getLocalFavourites().includes(launchCode);
  }

  private cleanLocalFavourites(): void {
    const localStorageItem = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!localStorageItem) {
      return;
    }

    this.updateLocalFavourites(JSON.stringify({ ...JSON.parse(localStorageItem), favourites: '' }));
  }
}
