<div class="d-flex">
  <div class="d-flex flex-column flex-grow-1">
    <h2>{{ title | vegastranslate }}</h2>
    <div class="text-muted subtitle-text">{{ subtitle | vegastranslate }}</div>
  </div>
</div>
<div
  class="whg-game-tiles__wrapper"
  [class.full-size]="_componentSize === 'full-size'"
  [class.half-size]="_componentSize === 'half-size'"
>
  <ng-container *ngIf="!isLoading; else empty">
    <ng-container *ngFor="let gameTile of gameTileList">
      <whg-tile
        *ngIf="!gameTile.isPlaceholder"
        #tiles
        class="position-relative"
        [tile]="gameTile"
        [isLoggedIn]="true"
      ></whg-tile>
      <whg-empty-tile *ngIf="gameTile.isPlaceholder"></whg-empty-tile>
    </ng-container>
  </ng-container>
</div>
<ng-template #empty>
  <ng-container *ngFor="let item of [].constructor(this._entries)">
    <whg-empty-tile></whg-empty-tile>
  </ng-container>
</ng-template>
