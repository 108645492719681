import { Component, OnInit, HostBinding, ElementRef } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { UserStore } from '../core-services/user/user.store';
import { BaseComponent } from '../base.component';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { SeoService } from '../core-services/seo-service/seo.service';

export const GAME_PAGE_SELECTOR = 'whg-game-page';
@Component({
  selector: GAME_PAGE_SELECTOR,
  templateUrl: './games-page.component.html',
  styleUrls: ['./games-page.component.scss'],
})
export class GamesPageComponent extends BaseComponent implements OnInit {
  image?: string;
  hideBanner = false;
  currency = '';
  seoJSONfileName = 'search.json';
  jsonHTMLSEO$: Observable<string[]>;
  isLoggedIn = false;

  @HostBinding('class.container') container = true;

  constructor(
    private visitorStore: VisitorStore,
    private router: Router,
    private el: ElementRef<HTMLElement>,
    private user: UserStore,
    private seoService: SeoService
  ) {
    super();
    this.jsonHTMLSEO$ = this.seoService.getHTMLJSON(this.seoJSONfileName);
  }

  ngOnInit() {
    const route$ = this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event): event is RoutesRecognized => event instanceof RoutesRecognized),
      map(event => {
        let snapshot = event.state.root;
        while (snapshot.firstChild) {
          snapshot = snapshot.firstChild;
        }
        return snapshot;
      })
    );
    this.visitorStore.currencySymbol$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.currency = res as string;
    });
    route$.subscribe(snapshot => {
      window.scrollTo(0, 0);
      this.image = snapshot.data.backgroundSrc;
      // TODO: Move to HostBinding() after angular upgrade.
      this.el.nativeElement.style.setProperty('--background-image', `url(${this.image})`);
    });

    combineLatest([this.user.userDetails$, route$]).subscribe(([userDetails, route]) => {
      let hideBanner = !!route.data.hideBanner;
      if (!hideBanner && userDetails) {
        hideBanner = userDetails.depositInfo.count > 3;
      }
      this.hideBanner = hideBanner;
      if (userDetails) {
        this.isLoggedIn = true;
      }
    });
    this.router.initialNavigation(); // Manually triggering initial navigation for @angular/elements.
  }
}
