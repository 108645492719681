import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HighlightsStore } from '../core-services/highlights/highlights.store';
import { Highlights, Language } from '../core-services/highlights/highlights.model';
import { Moment } from 'moment';
import * as moment from 'moment';
import { map, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { BaseComponent } from '../base.component';
import { HighlightsTile } from '../highlights-tile/highlights-tile.model';

export const HIGHLIGHTS_SELECTOR: string = 'whg-highlights';
const TILE_WIDTH: number = 180;
const SLIDER_SCROLL_LEFT: number = 0;
const SLIDER_SCROLL_RIGHT: number = 1;

@Component({
  selector: HIGHLIGHTS_SELECTOR,
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
})
export class HighlightsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() public path!: string;
  @Input() public title?: string;
  @Input() public subtitle?: string;
  @ViewChild('slider') public slider?: ElementRef;

  public highLights?: HighlightsTile[];
  public today: Moment = moment();
  public arrowLeft: string = '/assets/icons/arrow-left.svg';
  public arrowRight: string = '/assets/icons/arrow-right.svg';
  public sliderScrollLeft: number = SLIDER_SCROLL_LEFT;
  public sliderScrollRight: number = SLIDER_SCROLL_RIGHT;

  constructor(private highlightsStore: HighlightsStore) {
    super();
  }
  public ngAfterViewInit(): void {
    this.subscribeSlider();
  }

  public ngOnInit(): void {
    this.highlightsStore.getHighlights(this.path);
    this.highlightsStore.highlights$
      .pipe(
        map((res: Highlights[] | undefined) =>
          res?.filter((value: Highlights) => this.getCurrentHighLights(value.dateStart, value.dateEnd))
        ),
        map((res: Highlights[] | undefined) => {
          if (res) {
            return this.highlightsStore.getHighLightsInLanguage(res);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res: (Language | undefined)[] | undefined) => {
        this.highLights = res as HighlightsTile[];
      });
  }

  public getCurrentHighLights(startDate: string, endDate: string): boolean {
    return moment(this.today).isBetween(startDate, endDate);
  }

  public scrollRight(): void {
    this.slider?.nativeElement.scrollTo({
      left: this.slider?.nativeElement.scrollLeft + TILE_WIDTH,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.slider?.nativeElement.scrollTo({
      left: this.slider?.nativeElement.scrollLeft - TILE_WIDTH,
      behavior: 'smooth',
    });
  }

  private subscribeSlider(): void {
    fromEvent(this.slider?.nativeElement, 'scroll', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getSliderScrollPosition());
  }

  private getSliderScrollPosition(): void {
    this.sliderScrollLeft = this.slider?.nativeElement.scrollLeft;
    this.sliderScrollRight =
      this.slider?.nativeElement.scrollWidth -
      this.slider?.nativeElement.scrollLeft -
      this.slider?.nativeElement.getBoundingClientRect().width;
  }
}
