import { Component, Input, OnInit } from '@angular/core';

import { Promotion } from '../promotions-today/promotions-today.model';
import { WidgetItemTypeEnum } from '../gamification-widget/gamification-widget.model';
import { GamificationWidgetService } from '../gamification-widget/gamification-widget.service';
import * as moment from 'moment';

export const PROMOTIONS_TODAY__CARD_SELECTOR = 'whg-promotions-today-card';

@Component({
  selector: PROMOTIONS_TODAY__CARD_SELECTOR,
  templateUrl: './promotions-today-card.component.html',
  styleUrls: ['./promotions-today-card.component.scss'],
})
export class PromotionsTodayCardComponent implements OnInit {
  @Input() promo!: Promotion;
  currentPromo: Promotion[] = [];

  isTodayPromotion!: boolean;

  constructor(private readonly gamificationWidgetService: GamificationWidgetService) {}

  ngOnInit() {
    this.isTodayPromotion = this.isATodayPromotion(this.promo.scheduledStart, this.promo.scheduledEnd);
  }

  public openWidget(): void {
    if (this.promo.type === 'tournament') {
      this.gamificationWidgetService.openWidget(WidgetItemTypeEnum.tournaments, this.promo.id);
      return;
    }
    if (this.promo.type === 'challenge') {
      this.gamificationWidgetService.openWidget(WidgetItemTypeEnum.challenges, this.promo.id);
      return;
    }
  }

  private isATodayPromotion(start: Date, end: Date) {
    const today = moment();
    const startD = moment(start).startOf('day');
    const endD = moment(end).endOf('day');
    return today.isBetween(startD, endD);
  }
}
