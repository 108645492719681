<div class="whg-tile__img-wrapper">
  <button (click)="tileClick()" class="whg-tile__img-button">
    <img class="mb-1 w-100" [src]="tile.sliderImage" [alt]="tile.name" imageFallback />
  </button>
</div>
<div class="whg-tile__info d-flex justify-content-between align-items-center">
  <div class="d-flex flex-column">
    <h3>{{ tile.name }}</h3>
    <span class="text-muted"> {{ tile.details.substring(0, 135) }}... </span>
  </div>
</div>
