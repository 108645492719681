<section class="card h-100 border-0" *ngIf="parsedConfig">
  <span *ngIf="vipStatus" class="vip-status"></span>
  <div class="card-header border-bottom-0 {{ parsedConfig.header.title }} {{ currentLevel ? 'current-level' : '' }}">
    <span [ngStyle]="{ color: parsedConfig.header.styles }" [inlineSVG]="parsedConfig.header.iconUrl"></span>
    <h1 class="mb-0">{{ parsedConfig.header.title | vegastranslate }}</h1>
  </div>
  <div class="card-body">
    <ng-container *ngIf="parsedConfig.list.length > 0">
      <dl *ngFor="let item of parsedConfig.list">
        <dt class="mr-2">{{ item.term | vegastranslate }}</dt>
        <dd *ngIf="item.definition">{{ item.definition | vegastranslate }}</dd>
      </dl>
    </ng-container>
    <ng-container *ngIf="parsedConfig.placeholder">
      <span class="d-block mx-auto card-text text-center font-weight-bold">{{
        parsedConfig.placeholder | vegastranslate
      }}</span>
    </ng-container>
  </div>
</section>
