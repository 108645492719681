import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { map } from "rxjs/operators";
import { PageState } from "./page.model";

@Injectable({
  providedIn: "root",
})
export class PageStore extends ComponentStore<PageState> {
  constructor() {
    super({
      isMinimal: false,
      isRestricted: false,
    });
  }

  readonly page$ = this.select((state) => state.page);
  readonly isMinimal$ = this.select((state) => state.isMinimal);
  readonly isRestricted$ = this.select((state) => state.isRestricted);

  readonly updatePage = this.updater((state, page: PageState["page"]) => ({
    ...state,
    page,
  }));

  readonly updateIsMinimal = this.updater(
    (state, isMinimal: PageState["isMinimal"]) => ({ ...state, isMinimal })
  );

  readonly updateIsRestricted = this.updater(
    (state, isRestricted: PageState["isRestricted"]) => ({
      ...state,
      isRestricted,
    })
  );
}
