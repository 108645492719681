<div class="whg-card" [ngStyle]="{ 'background-image': 'url(' + card.image + ')' }" [ngClass]="card.style">
  <a *ngIf="card.link" [href]="card.link"></a>
  <div class="whg-card__info">
    <a [href]="card.link">
      <img [class]="" [src]="card.badge" />
    </a>
    <div>
      <h2 class="whg-card__title">{{ card.title | vegastranslate }}</h2>
      <h4 class="whg-card__sub-title">{{ card.subTitle | vegastranslate }}</h4>
    </div>
    <div *ngIf="card.jackpot$ | async" class="whg-card__jackpot {{ currencyCode }}">{{ card.jackpot$ | async }}</div>
    <a *ngIf="!(card.jackpot$ | async) && card.text" class="whg-card__text small" [href]="card.link">{{ card.text | vegastranslate }}</a>

    <button *ngIf="card?.button?.link" class="btn btn-primary btn-sm" (click)="openModal()">
      {{ card.button?.text | vegastranslate }}
    </button>
    <button *ngIf="card?.button && card.infoModal" class="btn btn-primary btn-sm" (click)="openModal()">
      {{ card.button?.text | vegastranslate }}
    </button>
  </div>
</div>
