import { Component, Input, OnInit } from '@angular/core';
import { LatestWinsService } from '../core-services/latest-wins/latest-wins.service';
import { map, takeUntil } from 'rxjs/operators';
import { TileBuilder } from '../tile/tile.builder';
import { Tile } from '../tile/tile.model';
import { GameHelper } from '../core-services/games/games.helper';
import { CoreService } from '../core-services/core.service';
import { GameModeEnum } from '../core-services/play/play.model';
import { PlayService } from '../core-services/play/play.service';
import { BigWin, ComponentSize } from '../core-services/latest-wins/latest-wins.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';
import { AmountAsLocalePipe } from '../pipes/amount-as-locale.pipe';
import { combineLatest } from 'rxjs';
import { GamesStore } from '../core-services/games/games.store';
import { GameProvider } from '../core-services/games/games.model';
import { VegasI18nPipe } from '../pipes/i18n.pipe';

export const LATEST_WINS_SELECTOR: string = 'whg-latest-wins';
export const DEFAULT_NUMBER_OF_ENTRIES: number = 6;
export const DEFAULT_BIG_WIN_SUBTITLE: string = 'Paid out in the last 48 hours';

@Component({
  selector: LATEST_WINS_SELECTOR,
  templateUrl: './latest-wins.component.html',
  styleUrls: ['latest-wins.component.scss'],
  providers: [LatestWinsService, AmountAsLocalePipe, VegasI18nPipe],
})
export class LatestWinsComponent extends BaseComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public subtitle: string = '';

  public _entries: number = DEFAULT_NUMBER_OF_ENTRIES;
  public _brandId!: number;
  public _componentSize: ComponentSize = ComponentSize.FullSize;
  public gameTileList: Tile[] = [];
  public isLoading: boolean = true;

  constructor(
    private latestWinsService: LatestWinsService,
    private playService: PlayService,
    private visitorStore: VisitorStore,
    private gameStore: GamesStore,
    private amountAsLocalePipe: AmountAsLocalePipe,
    private vegasI18nPipe: VegasI18nPipe,
    private coreService: CoreService
  ) {
    super();
  }

  @Input() public set brand(brand: string) {
    this._brandId = Number(brand);
  }

  @Input() public set entries(entries: string) {
    this._entries = Number(entries);
  }

  @Input() public set componentsize(componentSize: ComponentSize) {
    this._componentSize = componentSize;
  }

  public ngOnInit(): void {
    this.gameStore.getGameProviders();
    combineLatest([this.gameStore.allGameProviders$, this.visitorStore.currencySymbol$]).subscribe(
      ([gameProviderList, currency]: [GameProvider[], string | undefined]) => {
        if (gameProviderList?.length > 0) {
          this.latestWinsService
            .getLatestWinsGameList(this._entries, this._brandId, this.createProviderIdList(gameProviderList))
            .pipe(map((result: BigWin[]) => this.createGameTileList(result, currency ? currency : '')))
            .pipe(takeUntil(this.destroy$))
            .subscribe((result: Tile[]) => {
              this.isLoading = false;

              this.gameTileList = result;
            });
        }
      }
    );
  }

  private createProviderIdList(gameProviderList: GameProvider[]): number[] {
    return gameProviderList.map((gameProvider: GameProvider) => gameProvider.id);
  }

  private createGameTileList(gameList: BigWin[], currency: string): Tile[] {
    const placeholderTile: Tile = new TileBuilder('', '').isPlaceholder(true).build();
    const tileList: Tile[] = Array(this._entries).fill(placeholderTile);
    // eslint-disable-next-line no-magic-numbers
    let index: number = 0;

    gameList.forEach((bigWin: BigWin) => {
      const translatedSubTitle: string = this.vegasI18nPipe.transform(DEFAULT_BIG_WIN_SUBTITLE);
      const tile: Tile = new TileBuilder(bigWin.game?.name, bigWin.game?.provider)
        .withGame(bigWin.game)
        .withImage(GameHelper.createGameTileImageSource(this.coreService.scontentUrl, bigWin.game?.image))
        .withLink(this.playService.getGameUrl(bigWin.game?.launchcode, true, GameModeEnum.Real))
        .withTitle(`${currency} ${this.amountAsLocalePipe.transform(bigWin.biggestPayer.amount)}`)
        .withSubTitle(translatedSubTitle)
        .withBiggestPayer(bigWin.biggestPayer)
        .build();

      tileList[index] = tile;

      index++;
    });

    // eslint-disable-next-line no-magic-numbers
    return tileList.sort((a: Tile, b: Tile) => (a.bigWinAmount > b.bigWinAmount ? -1 : 1)).slice(0, this._entries);
  }
}
