import { Component, Input, OnInit } from '@angular/core';

import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { Moment } from 'moment';
import { chunk } from 'lodash-es';

import { Achievement } from '../core-services/achievements/achievements.model';
import { CalendarDay, CalendarViewEnum, CalendarWeekDay } from './calendar.model';
import { Promotion, PromotionEnum, PromotionLegend, PromotionType } from '../promotions-today/promotions-today.model';
import { PromotionsTodayService } from '../promotions-today/promotions-today.service';
import { AchievementsService } from '../core-services/achievements/achievements.service';
import { ContestData, TournamentsService } from '../core-services/tournaments/tournaments.service';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { getPromoColor } from '../promotions-today/promotions-today.config';
import { CoreService } from '../core-services/core.service';

export const CALENDAR_SELECTOR = 'whg-calendar';

@Component({
  selector: CALENDAR_SELECTOR,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() calendarView: CalendarViewEnum = CalendarViewEnum.Month;

  weekdays: CalendarWeekDay[] = [];
  month: CalendarDay[][] = [];
  calendarViewEnum = CalendarViewEnum;
  currentDaySelected!: CalendarDay;

  contests: ContestData[] = [];
  achievements: Achievement[] = [];
  promotions: Promotion[] = [];

  private today: Moment = moment();

  monthName: string;
  year: string;

  isLoading = true;

  constructor(
    private visitorStore: VisitorStore,
    private promotionsTodayService: PromotionsTodayService,
    private tournamentsService: TournamentsService,
    private achievementsService: AchievementsService,
    private coreService: CoreService,
    private spinner: NgxSpinnerService
  ) {
    this.monthName = this.today.format('MMMM');
    this.year = this.today.format('YYYY');
  }

  ngOnInit() {
    setTimeout(() => {
      this.spinner.show('calendar-spinner');
    }, 1500);

    const scheduleStart = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS');
    const scheduleEnd = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS');

    const requests = [
      this.tournamentsService.getContests(scheduleStart, scheduleEnd),
      this.achievementsService.getAchievementsBySchedules(scheduleStart, scheduleEnd),
    ];

    forkJoin(requests).subscribe(([contests, achievements]) => {
      this.contests = contests.data as ContestData[];
      this.achievements = achievements.data as Achievement[];
      this.promotions = [...this.promotionsMapper(this.contests), ...this.promotionsMapper(this.achievements)];
      this.currentDaySelected = this.getDay(this.today);

      this.setLocale();
      this.setCalendar();

      this.spinner.hide();
      this.isLoading = false;
    });
  }

  private promotionsMapper(dataList: ContestData[] | Achievement[]) {
    const promotionsMapped: Promotion[] = [];

    const sigTerms = `sigterms_${this.coreService.getCurrentLanguage()}`;

    dataList.forEach((data: ContestData | Achievement) => {
      const promoMetadata = data.metadata?.find(meta => meta.key === 'promo');
      const sigTermsMetadata = data.metadata?.find(meta => meta.key === sigTerms);
      const promoName = promoMetadata?.value as PromotionEnum;

      if (!Object.values(PromotionEnum).includes(promoName) || promoMetadata?.key !== 'promo') {
        return;
      }

      const isContestData = (data as ContestData).competitionId;

      const id = isContestData ? (data as ContestData).competitionId : (data as Achievement).id;
      const title = isContestData ? (data as ContestData).label : (data as Achievement).name;
      const type: PromotionType = isContestData ? 'tournament' : 'challenge';
      const scheduledStart = isContestData
        ? (data as ContestData).scheduledStart
        : (data as Achievement).scheduling.startDate;
      const scheduledEnd = isContestData
        ? (data as ContestData).scheduledEnd
        : (data as Achievement).scheduling.endDate;

      // extract first img src in description
      const image =
        data.description.match(/<img [^>]*src="[^"]*"[^>]*>/gm)?.map(x => x.replace(/.*src="([^"]*)".*/, '$1'))[0] ??
        '';

      const newPromotion: Promotion = {
        id,
        title,
        type,
        promoType: promoName,
        color: getPromoColor(promoName),
        scheduledStart,
        scheduledEnd,
        image,
        sigTerms: sigTermsMetadata?.value ?? '',
      };

      if (isContestData) {
        newPromotion.terms = (data as ContestData).termsConditions;
      }
      promotionsMapped.push(newPromotion);
    });

    return promotionsMapped;
  }

  private limitRushHourContests(promotions: Promotion[]) {
    const max = 2;
    let counter = 0;

    return promotions.filter(promo => (promo.promoType === PromotionEnum.RushHour ? counter++ < max : true));
  }

  onDaySelect(calendarDay: CalendarDay): void {
    if (!calendarDay.isThisMonth || calendarDay.isYesterdayOrBefore) {
      return;
    }
    this.setDayPromotions(calendarDay.promotions);
    this.currentDaySelected = calendarDay;
  }

  private setLocale(): void {
    this.visitorStore.countryCode$.pipe(first()).subscribe(countryCode => {
      moment.updateLocale(countryCode, {
        week: {
          dow: 1,
        },
      });
    });
  }

  private setCalendar(): void {
    const firstDay: Moment = this.today.clone().startOf('month').startOf('isoWeek');
    const lastDay: Moment = this.today.clone().endOf('month').endOf('isoWeek');
    const days: CalendarDay[] = [];
    let day: Moment = firstDay;

    while (day <= lastDay) {
      const calendarDay = this.getDay(day);
      if (calendarDay.isToday) {
        this.setDayPromotions(calendarDay.promotions);
      }
      days.push(calendarDay);
      day = day.clone().add(1, 'd');
    }

    this.month = chunk(days, 7);
  }

  private getDay(day: Moment): CalendarDay {
    let promotions = this.hasPromotions(day);
    promotions = this.limitRushHourContests(promotions);
    return {
      moment: day,
      day: day.format('D'),
      isToday: this.isToday(day),
      isYesterdayOrBefore: this.isYesterdayOrBefore(day),
      isThisMonth: this.isThisMonth(day),
      promotions: promotions,
      legends: this.getDayLegends(promotions),
    };
  }

  private isToday(day: Moment): boolean {
    return day.isSame(this.today, 'd');
  }

  private isYesterdayOrBefore(day: Moment): boolean {
    return this.today.diff(day, 'days') > 0;
  }

  private isThisMonth(day: Moment): boolean {
    return day.isSame(this.today, 'M');
  }

  private hasPromotions(day: Moment): Promotion[] {
    const currentDayPromotions = this.promotions.filter(promo => {
      const promoStartNoUTC = moment(moment.utc(promo.scheduledStart).format('YYYY-MM-DDTHH:mm:ss.SSS'));
      const promoEndNoUTC = moment(moment.utc(promo.scheduledEnd).format('YYYY-MM-DDTHH:mm:ss.SSS'));
      return (
        day.isBetween(promoStartNoUTC, promoEndNoUTC) ||
        day.isSame(promoStartNoUTC, 'day') ||
        day.isSame(promoEndNoUTC, 'day')
      );
    });
    return currentDayPromotions;
  }

  private getDayLegends(promotions: Promotion[]): PromotionLegend[] {
    const legends = promotions.map(promo => ({ text: promo.promoType, color: promo.color }));
    // Remove duplicate legends
    const leg = legends.filter((legend, index, array) => array.findIndex(lg => lg.text === legend.text) === index);
    return leg;
  }

  private setDayPromotions(promotions: Promotion[]): void {
    this.promotionsTodayService.next([...promotions]);
  }
}
