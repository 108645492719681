import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';

export const BANNER_SELECTOR = 'whg-banner';

@Component({
  selector: BANNER_SELECTOR,
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent extends BaseComponent implements OnInit {
  @Input() text1 = '';
  @Input() step1 = '';
  @Input() text2 = '';
  @Input() step2 = '';
  @Input() step5 = '';
  @Input() buttonText = '';
  link = '';

  constructor(private visitorStore: VisitorStore) {
    super();
  }

  ngOnInit() {
    this.subscribeUser();
  }

  subscribeUser(): void {
    this.visitorStore.loggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        isLoggedIn =>
          (this.link = isLoggedIn ? `${window.location.origin}/deposit` : `${window.location.origin}/register`)
      );
  }
}
