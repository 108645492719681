import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Promotion } from './promotions-today.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionsTodayService {
  private readonly promotionsTodaySource = new BehaviorSubject<Promotion[]>([]);
  public readonly promotionsToday$ = this.promotionsTodaySource.asObservable();

  constructor() { }

  next(promotions: Promotion[]) {
    this.promotionsTodaySource.next(promotions);
  }
}
