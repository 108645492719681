import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyConverter } from './currency-converter';
import { CountryCodesType } from './country-currency-codes';

@Pipe({ name: 'currencyConverter' })
export class CurrencyConverterPipe implements PipeTransform {
  transform(amount: number, countryCode: CountryCodesType) {
    const conversion = CurrencyConverter.to(amount, countryCode);
    return conversion;
  }
}
