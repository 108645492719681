export enum BottomNavItemType {
  Anchor,
  Button,
}

export type BottomNavConfig = {
  url: string;
  styles: string;
  liStyles?: string;
  icon: string;
} & (
  | {
      type: BottomNavItemType.Button;
    }
  | {
      type: BottomNavItemType.Anchor;
    }
);
