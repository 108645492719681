import { Game } from '../core-services/games/games.model';
import { Tile, TileImage } from './tile.model';
import { BiggestPayer } from '../core-services/latest-wins/latest-wins.model';

export class TileBuilder {
  private readonly tile: Tile;

  constructor(name: string, subTitle: string) {
    this.tile = new Tile();
    this.tile.title = name;
    this.tile.subTitle = subTitle;
    this.tile.image = new TileImage();
    this.tile.image.alt = name;
    this.tile.image.src = '';
    this.tile.link = '';
  }

  withImage(imageSource: string): TileBuilder {
    this.tile.image.src = imageSource;
    return this;
  }

  withGame(game: Game): TileBuilder {
    this.tile.game = game;
    return this;
  }

  withLink(gameLink: string): TileBuilder {
    this.tile.link = gameLink;
    return this;
  }

  isBigWin(isBigWin: boolean): TileBuilder {
    this.tile.isBigWin = isBigWin;
    return this;
  }

  withBiggestPayer(biggestPayer: BiggestPayer): TileBuilder {
    this.tile.isBigWin = true;
    this.tile.bigWinAmount = biggestPayer.amount;
    return this;
  }

  isPlaceholder(isPlaceholder: boolean): TileBuilder {
    this.tile.isPlaceholder = isPlaceholder;
    return this;
  }

  withTitle(title: string): TileBuilder {
    this.tile.title = title;
    return this;
  }

  withSubTitle(subTitle: string): TileBuilder {
    this.tile.subTitle = subTitle;
    return this;
  }

  build(): Tile {
    return this.tile;
  }
}
