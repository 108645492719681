/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/13566017/Achievement
 */
export interface Achievement extends AchievementContent {
  /** Name of the Achievement */
  name: string;
  /** Unique identifier of your account */
  accountId: string;
  /** The description of a Achievement */
  description: string;
  /** Informs the state of the achievement 1 = Hidden; 2 = Revealed */
  // eslint-disable-next-line no-magic-numbers
  initialState: 1 | 0;
  /** Icon to display for Achievement */
  icon: string;
  /** A list of Rule sets to be applied for achievements */
  ruleSets: RuleSet[];
  /** To archive the achievement or not */
  deprecated: boolean;
  dependantOn: AchievementLink[];
  /** The minimum value of the participants that will participate in the achievement */
  minimumShouldMatch: number;
  scheduling: Scheduling;
  achievementLiveStatus: AchievementStatus;
  /** Categorisation of the achievements. */
  category?: string[];
  /** A group of members that can receive the achievement. */
  memberGroups?: string[];
  /** A list of Metadata */
  metadata: Metadata[];
  /** ISO8601 timestamp for when the Award was created. All records are stored in UTC time zone. */
  created: string;
  /** Unique system identifier of an Achievement */
  id: string;
  /** It is a key-value pair of language code and a list of translations. The translatable fields are "name" and "description" */
  translations?: Translation;

  scheduledEnd?: Date;
  scheduledStart?: Date;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/14319649/Metadata
 */
export interface Metadata {
  key: string;
  value: string;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/481296652/Scheduling
 */
interface Scheduling {
  scheduleType: ScheduleType;
  /** A value indicating how many times it will be issued  */
  scheduleOccurrencesLimit: string;
  /** A value for the parameter of the scheduleType that indicates the Daily, Weekly, Monthly repetition */
  every: number[];
  /** The start date and time of a schedule. ISO8601 timestamp */
  startDate: Date;
  /** The end date and time of a schedule. ISO8601 timestamp*/
  endDate: Date;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/481263768/AchievementLink
 */
export interface AchievementLink {
  /** A unique identifier of an achievement   */
  achievementId: string;
  /** An achievement filter type */
  linkType: AchievementFilterType;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/14319653/RuleSet
 */
interface RuleSet {
  /** Indicates which rule is prioritised first so that the rules work in a sequence */
  priority: number;
  /** Can be achievement or contest  */
  scope: string;
  /** The main rule for the rule set  */
  action: string;
  conditions: MainConditionSet[];
  /** For contest creation with Custom points action selection pre-created variable  */
  onMatchThen?: string;
  /** For contest creation with Custom points action value  */
  onMatchConstant?: string;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/480739397/MainConditionSet
 */
interface MainConditionSet {
  /** Whether the rules must all evaluate as True or False or at least one of the rules must be True or False to satisfy the rule */
  matchCondition: MatchCondition;
  /** The value the rule(s) must evaluate to for the rule to be satisfied   */
  mustEvaluateTo: boolean;
  /** The value the rule(s) must evaluate to for the rule to be satisfied   */
  rules: MainRule;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/480804919/MainRule
 */
interface MainRule extends SubRule {
  /** Unique system identifier of a Main Rule */
  id: string;
  subConditions: SubConditionSet[];
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/480837719/SubConditionSet
 */
interface SubConditionSet {
  /** Whether the rules must all evaluate as True or False or at least one of the rules must be True or False to satisfy the rule */
  matchCondition: MatchCondition;
  /** The value the rule(s) must evaluate to for the rule to be satisfied   */
  mustEvaluateTo: boolean;
  subRules: SubRule[];
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/481427506/SubRule
 */
interface SubRule {
  /** The rules fact, ex. the action, the product, the sum of events etc. */
  fact: string;
  /** Part of the rule which operates the fact ex. is, more than, less than etc.*/
  operator: string;
  /** A rules value that defines the fact ex. if the fact is "the action" then the constant is "win" */
  constant: string;
}

/**
 * @see https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/482836565/Translation
 */
interface Translation {
  /** System generated field names, ex. label, description, termsAndConditions */
  fieldName: string;
  /** The translated field of the selected language	 */
  text: string;
}

export interface AchievementContent {
  image?: string;
}

export interface AchievementRequest {
  data: Achievement[];
}

enum AchievementStatus {
  Draft = 'Draft',
  Live = 'Live',
}

enum ScheduleType {
  Once = 'Once',
  Repeatedly = 'Repeatedly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum AchievementFilterType {
  Must = 'Must',
  Should = 'Should',
  MustNot = 'Must-not',
}

enum MatchCondition {
  All = 'All',
  Any = 'Any',
}
