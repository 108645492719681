import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserStore } from '../core-services/user/user.store';
import { BaseComponent } from '../base.component';
import { takeUntil, filter, tap, finalize, withLatestFrom } from 'rxjs/operators';
import { CoreService } from '../core-services/core.service';

export const LOGIN_SELECTOR = 'whg-login';

@Component({
  selector: LOGIN_SELECTOR,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  showPassword = false;
  errorMessage = '';
  disableLogin = false;

  constructor(private userStore: UserStore, private coreService: CoreService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {}

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  submitLogin($event: Event): void {
    $event.preventDefault();
    const formData = new FormData($event.target as HTMLFormElement);
    this.userStore
      .login(formData)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.disableLogin = true;
        }),
        finalize(() => {
          this.disableLogin = false;
        }),
        withLatestFrom(this.activatedRoute.queryParamMap)
      )
      .subscribe(([response, params]) => {
        if (response.statusCode === 200 || response.status === 'success') {
          let sender: string | null = params.get('sender');
          const legacySender: string | null = new URL(`${document.location}`).searchParams.get('sender');

          if (!sender && legacySender) {
            sender = `${legacySender}`
          }

          if (sender) {
            window.location.href = sender;
          } else {
            window.location.href = '/?loggedin';
          }
        } else {
          this.coreService
            .getErrorCodeTranslations(`ERROR_${JSON.parse(response.statusCode)['code']}`)
            .pipe(
              takeUntil(this.destroy$),
              filter<string | undefined>((x) => typeof x === 'string')
            )
            .subscribe((value: any) => {
              this.errorMessage = value;
            });
        }
      });
  }

  get isDisabled() {
    return this.disableLogin;
  }
}
