<whg-banner
  text1="No wager. No fuss."
  text2="50_cashback_up_to_400"
  text3="Claim within 7 days of making your initial deposit"
  [buttonText]="isLoggedIn ? 'Deposit' : 'Start Playing'"
  class="full-size-background"
  [hidden]="hideBanner"
></whg-banner>
<ng-container *ngIf="!hideBanner">
  <ng-content></ng-content>
</ng-container>
<whg-search class="d-lg-none"></whg-search>
<whg-game-nav-internal class="d-none d-lg-flex my-lg-3 sticky-games-nav"></whg-game-nav-internal>
<router-outlet></router-outlet>
<div class="seo-container" *ngIf="hideBanner">
  <div *ngFor="let html of jsonHTMLSEO$ | async" [innerHTML]="html"></div>
</div>
