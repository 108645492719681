<div *ngIf="showRecentSearch | async; else showGames" class="recent-search">
  <ng-container *ngIf="gamesNotFound"
    ><h3 class="text-muted">{{ 'No games found' | vegastranslate }}</h3></ng-container
  >
  <ng-container *ngIf="(recentSearches$ | async)?.length">
    <h3>{{ 'Recent Searches' | vegastranslate }}</h3>
    <h3>
      <a (click)="removeRecentSearch()">{{ 'Clear' | vegastranslate }}</a>
    </h3>
  </ng-container>
  <ul>
    <li *ngFor="let item of recentSearches$ | async">
      <button (mousedown)="onClickRecentSearch(item)" class="btn btn-link recent-list-btn" type="button">
        {{ item }}
      </button>
    </li>
  </ul>
</div>
<ng-template #showGames>
  <div class="whg-game-tiles__wrapper" resizeDir (resizeEvt)="resize()">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let tile of tiles">
        <whg-tile #tiles class="position-relative" [tile]="tile" [isLoggedIn]="isLoggedIn"></whg-tile>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
