export enum PaymentOption {
  ApplePay = 'ApplePay',
  BancoEstado = 'BancoEstado',
  BankTransfer = 'BankTransfer',
  BankTransferGB = 'BankTransferGB',
  BankTransferIBAN = 'BankTransferIBAN',
  BCP = 'BCP',
  CajaVecina = 'CajaVecina',
  EBankingFI = 'EBankingFI',
  EPS = 'EPS',
  InteracEtransfer = 'InteracEtransfer',
  Interac = 'Interac',
  Interbank = 'Interbank',
  Itau = 'Itau',
  Klarna = 'Klarna',
  Maestro = 'Maestro',
  MastercardDebit = 'Mastercard_debit',
  Mastercard = 'Mastercard',
  MuchBetter = 'MuchBetter',
  Neosurf = 'Neosurf',
  Neteller = 'Neteller',
  OnlineBanking = 'OnlineBanking',
  OnlineUberwesiung = 'OnlineUberwesiung',
  OpenBanking = 'OpenBanking',
  PagoEfectivo = 'PagoEfectivo',
  PaySafeCard = 'PaySafeCard',
  Paypal = 'Paypal',
  RapidTransfer = 'RapidTransfer',
  Revolut = 'Revolut',
  Rupay = 'Rupay',
  Santander = 'Santander',
  Sencillit = 'Sencillit',
  Skrill = 'Skrill',
  Sofort = 'Sofort',
  Trustly = 'Trustly',
  VisaDebit = 'VisaDebit',
  Visa = 'Visa',
  WebPlay = 'WebPlay',
  WesternUnion = 'WesternUnion'
}
