import { Component, Input, OnInit } from '@angular/core';
import { HighlightsTile } from './highlights-tile.model';

export const HIGHLIGHTS__TILE_SELECTOR = 'whg-highlights-tile';

@Component({
  selector: HIGHLIGHTS__TILE_SELECTOR,
  templateUrl: './highlights-tile.component.html',
  styleUrls: ['./highlights-tile.component.scss'],
})
export class HighlightsTileComponent implements OnInit {
  @Input() tile!: HighlightsTile;
  constructor() {}

  ngOnInit() {}

  tileClick() {
    // TODO open pop-up when modal is ready
    console.log('open modal');
  }
}
