<section class="whg-login">
  <div class="whg-login__login">
    <p class="text-uppercase small">{{ 'welcome back' | vegastranslate }}</p>
    <div class="h2">{{ 'My Account' | vegastranslate }}</div>
    <form (submit)="submitLogin($event)">
      <div *ngIf="errorMessage" [innerHTML]="errorMessage" role="alert" class="alert alert-dark"></div>

      <div class="form-group">
        <label for="username" class="username-label">{{ 'username' | vegastranslate }}</label>
        <div class="input-group">
          <input type="text" class="form-control" name="username" autocapitalize="none" />
        </div>
      </div>
      <div class="form-group">
        <label for="password" class="password-label">{{ 'password' | vegastranslate }}</label>
        <div class="input-group">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="password" />
          <div class="input-group-append">
            <button class="btn btn-outline-icon password-btn" type="button" (click)="toggleShowPassword()">
              <i [ngClass]="['fa-lg fa', showPassword ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <button
        class="btn btn-outline-light whg-login__login-button {{ disableLogin ? 'disabled' : '' }}"
        type="submit"
        [disabled]="disableLogin">
        {{ 'Log In' | vegastranslate }}
      </button>
    </form>
    <div class="whg-login__help">
      <p class="mt-2 mb-1 small">{{ 'Trouble logging in ?' | vegastranslate }}</p>
      <ul>
        <li>
          <a href="/resetpassword">{{ 'Reset Password' | vegastranslate }}</a>
        </li>
        <li>
          <a href="/support">{{ 'Contact Support' | vegastranslate }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="new-account">
    <div class="h2 mb-2">{{ "I'm new here" | vegastranslate }}</div>
    <a href="/create-account" class="btn btn-primary">{{ 'Register My Account' | vegastranslate }}</a>
  </div>
</section>
