import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { CoreService } from '../core.service';
import { AchievementRequest } from './achievements.model';

@Injectable({
  providedIn: 'root',
})
export class AchievementsService {
  private baseUrl = 'https://gateway.ziqni.com/api/v1/';
  public apiKey =
    this.coreService.isDev || this.coreService.isStg
      ? '157d2719a6644f50b2d70ceb0acc86be'
      : 'f1e71d63ced543a98c6a0b092ffa8c6a';
  public spaceName = this.coreService.isDev || this.coreService.isStg ? 'vegasloungecsno_stg' : 'vegasloungecasino';

  constructor(private coreService: CoreService, private httpClient: HttpClient) {}

  // https://complabs.atlassian.net/wiki/spaces/CLRAV/pages/268959859/Achievements%2Blist%2BGET%2Bapi%2Bv1%2Bspace%2Bachievements
  public getAchievements(): Observable<AchievementRequest> {
    const params: HttpParams = new HttpParams({
      fromObject: { _include: 'dependantOn,metadata', _lang: this.coreService.getCurrentLanguage(), _limit: '1000' },
    });

    const options = { headers: this.headersForGetRequest(), params };
    const url = `${this.baseUrl}${this.spaceName}/achievements`;

    return this.httpClient.get<AchievementRequest>(url, options);
  }

  public getAchievementsBySchedules(scheduleStart: string, scheduleEnd: string): Observable<AchievementRequest> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        _include: 'metadata',
        _limit: '1000',
        _lang: this.coreService.getCurrentLanguage(),
        'scheduling.startDate>=': scheduleStart,
        'scheduling.endDate<=': scheduleEnd,
      },
    });

    const options = { headers: this.headersForGetRequest(), params };
    const url = `${this.baseUrl}${this.spaceName}/achievements`;

    return this.httpClient.get<AchievementRequest>(url, options);
  }

  private headersForGetRequest(): HttpHeaders {
    return new HttpHeaders({ 'X-API-KEY': this.apiKey });
  }
}
