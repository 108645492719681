import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs/internal/Observable';
import { Game } from '../games/games.model';
import { PlatformService } from '../platform/platform.service';
import { switchMap, tap, map, first, filter, mapTo, takeUntil, mergeMap } from 'rxjs/operators';
import { PlatformRequestTypes } from '../platform/platform.model';
import { GamesStore } from '../games/games.store';
import { CoreService } from '../core.service';
import { VisitorStore } from '../visitor/visitor.store';

@Injectable({
  providedIn: 'root',
})
export class RecentPlayedStore extends ComponentStore<{ launchCodes: string[] }> {
  private platformParamKey = 'recentgames';

  constructor(
    private platformService: PlatformService,
    private coreService: CoreService,
    private visitorStore: VisitorStore
  ) {
    super({ launchCodes: [] });
  }

  readonly launchCodes$: Observable<string[]> = this.select(state => state.launchCodes);

  readonly updateLaunchCodes = this.updater((state, launchCodes: string[]) => ({
    ...state,
    launchCodes,
  }));

  /**
   * For generic tracking purposes,
   * use `sendLaunchCodeWithBeacon` if a user can get redirected at the same moment as this function is being run.
   */
  public addLaunchCode = this.effect((launchCode$: Observable<string>) => {
    return launchCode$.pipe(
      switchMap(launchCode =>
        this.launchCodes$.pipe(
          first(),
          map(launchCodes => Array.from([...launchCodes, launchCode]))
        )
      ),
      switchMap(launchCodes =>
        this.platformService
          .execute(PlatformRequestTypes.SET_PARAM, { name: this.platformParamKey, value: launchCodes.join(',') })
          .pipe(mapTo(launchCodes))
      ),
      tap((launchCodes: string[]) => this.updateLaunchCodes(launchCodes))
    );
  });

  /**
   * Only use this function when their is a chance a user get redirected to another page on the same time!
   * Use `addLaunchCode()` in all other cases.
   *
   * Sending a asynchronous request when a user navigates away could cause issues,
   * as the browser may choose not to send asynchronous requests.
   *
   * With this method, the data is transmitted asynchronously when the user agent has
   * an opportunity to do so, without delaying unload or the next navigation.
   *
   * As such this function allows us to track a click on a game without delaying the user.
   */
  public addLaunchCodeWithBeacon = this.effect((launchCode$: Observable<string>) => {
    return launchCode$.pipe(
      switchMap(launchCode =>
        this.launchCodes$.pipe(
          first(),
          map(launchCodes => Array.from(new Set([...launchCodes, launchCode])))
        )
      ),
      tap(launchCodes => {
        // Create xml body, as sendBeacon only suppports text/plain with current CORS setup.
        const xml = document.implementation.createDocument(null, 'request', null);

        const name = xml.createElement('name');
        name.innerHTML = 'recentgames';
        const value = xml.createElement('value');
        value.innerHTML = launchCodes.join(',');
        const sessionid = xml.createElement('sessionid');
        sessionid.innerHTML = this.coreService.getSessionIdFromCookie();

        xml.children[0].appendChild(name);
        xml.children[0].appendChild(value);
        xml.children[0].appendChild(sessionid);

        navigator.sendBeacon(
          this.coreService.platformBaseUrl + 'setparam',
          // TODO: Remove `as HTMLElement` after upgrading Typescript
          new XMLSerializer().serializeToString(xml.documentElement as HTMLElement)
        );
      }),
      tap((launchCodes: string[]) => this.updateLaunchCodes(launchCodes))
    );
  });

  public getRecentGames = this.effect(() => {
    return this.visitorStore.loggedIn$.pipe(
      takeUntil(this.destroy$),
      // Run effect only when a user is logged in
      filter(loggedIn => !!loggedIn),
      mergeMap(() =>
        this.platformService.execute(PlatformRequestTypes.GET_PARAM, {
          name: this.platformParamKey,
        })
      ),
      map(({ value }) => value.split(',')),
      tap((launchCodes: string[]) => this.updateLaunchCodes(launchCodes))
    );
  });
}
