import { Component, HostBinding, OnInit } from '@angular/core';
import { bottomNavConfig } from './bottom-nav.config';
import { BottomNavItemType } from './bottom-nav.models';
import { PageStore } from '../core-services/page/page.store';
import { takeUntil } from 'rxjs/operators';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';

export const BOTTOM_NAV_SELECTOR = 'whg-bottom-nav';

@Component({
  selector: BOTTOM_NAV_SELECTOR,
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent extends BaseComponent implements OnInit {
  bottomNavItemTypes = BottomNavItemType;
  config = bottomNavConfig;
  @HostBinding('hidden') isRestricted = false;
  isLoggedIn?: boolean;

  constructor(private pageStore: PageStore, private visitorStore: VisitorStore) {
    super();
  }

  ngOnInit() {
    this.subscribeUser();
    this.pageStore.isRestricted$.subscribe(res => {
      this.isRestricted = res;
    });
  }
  subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  selectCashier() {
    if (!this.isLoggedIn) {
      window.location.href = '/login';
    }
  }
}
