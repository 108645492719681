import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { debounceTime, filter, first, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { SearchState, SearchVisualState } from './search.model';
import { PlatformService } from '../platform/platform.service';
import { GetParamPlatformResponse, PlatformRequestTypes } from '../platform/platform.model';
import { Game } from '../games/games.model';
import { VisitorStore } from '../visitor/visitor.store';

@Injectable({
  providedIn: 'root',
})
export class SearchStore extends ComponentStore<SearchState> {
  private readonly platformParamName = 'recentSearches';

  constructor(private platformService: PlatformService, private visitorStore: VisitorStore) {
    super({
      recentSearches: [],
      recentSearch: '',
      visualState: SearchVisualState.HIDDEN,
    });
  }

  // selectors
  readonly recentSearches$ = this.select(state => state.recentSearches);
  readonly filteredGames$ = this.select(state => state.filteredGames);
  readonly recentSearch$ = this.select(state => state.recentSearch);
  readonly visualState$ = this.select(state => state.visualState);

  // updaters
  readonly setRecentSearches = this.updater((state, recentSearches: string[]) => ({ ...state, recentSearches }));
  readonly addFilteredGames = this.updater((state, games: Game[] | null) => ({ ...state, filteredGames: games }));
  readonly setVisualState = this.updater((state, visualState: SearchVisualState) => ({ ...state, visualState }));
  readonly setRecentSearch = this.updater((state, recentSearch: string) => ({
    ...state,
    recentSearch: recentSearch,
  }));

  public getRecentSearches = this.effect(() => {
    return this.visitorStore.loggedIn$.pipe(
      takeUntil(this.destroy$),
      // Run effect only when a user is logged in
      filter(loggedIn => !!loggedIn),
      mergeMap(() =>
        this.platformService.execute(PlatformRequestTypes.GET_PARAM, {
          name: this.platformParamName,
        })
      ),
      map<GetParamPlatformResponse, string[]>(({ value }) => {
        try {
          return JSON.parse(value);
        } catch {
          return [];
        }
      }),
      tap<string[]>(recentSearches => this.setRecentSearches(recentSearches))
    );
  });

  public addRecentSearches = this.effect<string>(recentSearch$ => {
    return recentSearch$.pipe(
      mergeMap(recentSearch => {
        return this.recentSearches$.pipe(
          first(),
          map(x => Array.from(new Set([...x, recentSearch])))
        );
      }),
      debounceTime(1500),
      map(recentSearch => recentSearch.slice(-10).reverse()),
      mergeMap(recentSearch => this.updatePlatformRecentSearches(recentSearch).pipe(mapTo(recentSearch))),
      tap<string[]>(recent => this.setRecentSearches(recent))
    );
  });

  private updatePlatformRecentSearches(recentSearches: string[]) {
    return this.platformService.execute(PlatformRequestTypes.SET_PARAM, {
      name: this.platformParamName,
      value: JSON.stringify(recentSearches),
    });
  }

  public clearRecentSearches() {
    this.updatePlatformRecentSearches([]).subscribe();
    this.setRecentSearches([]);
  }
}
