import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CoreService } from '../core-services/core.service';

@Pipe({ name: 'vegastranslate', pure: false })
export class VegasI18nPipe extends TranslatePipe implements PipeTransform {
  constructor(private coreService: CoreService, translate: TranslateService, _ref: ChangeDetectorRef) {
    super(translate, _ref);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public transform(value: any, args?: any): string {
    const legacyTranslation: string | undefined = this.coreService.getLegacyTranslation(value);

    return legacyTranslation ? legacyTranslation : super.transform(value, args);
  }
}
