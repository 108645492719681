// tslint:disable:member-ordering
// Disabled as grouping selector, updater & effects makes more sense.

import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CoreService } from '../core.service';
import { PlatformService } from '../platform/platform.service';
import { Category, Game, GameApiUrl, GameCategory, GameProvider, GamesState, TrendingSlot } from './games.model';

@Injectable({
  providedIn: 'root',
})
export class GamesStore extends ComponentStore<GamesState> {
  constructor(private http: HttpClient, private platformService: PlatformService, private coreService: CoreService) {
    super({ allGames: [], allProviders: [], categories: [], trendingSlots: [] });
  }

  /*
   * Selectors read state out of our component store
   */
  readonly allGames$: Observable<Game[]> = this.select(state => state.allGames);
  readonly allGameProviders$: Observable<GameProvider[]> = this.select(state => state.allProviders);
  readonly allGameCategories$: Observable<Category[]> = this.select(state => state.categories);
  readonly tredingSlot$: Observable<TrendingSlot[]> = this.select(state => state.trendingSlots);

  getGamesOfProviderBySeoName(provider: string): Observable<Game[]> {
    return this.select(({ allGames }) =>
      allGames.filter(game => game.provider.toLowerCase().split(' ').join('-') === provider)
    );
  }

  getGamesOfCategory(category: string): Observable<Game[]> {
    return this.select(({ allGames }) =>
      allGames
        .filter(game => game.categories.some(c => c.category === category))
        .sort((a, b) => {
          const aSeq = a.categories.find(c => c.category === category)?.seq;
          const bSeq = b.categories.find(c => c.category === category)?.seq;
          if (aSeq !== undefined && bSeq !== undefined) {
            return aSeq - bSeq;
          }
          return 0;
        })
    );
  }

  /*
   * Updaters act as reducers as such and add values to our component state
   */
  readonly addGames = this.updater((state, allGames: Game[]) => ({
    ...state,
    allGames,
  }));

  readonly addGameProviders = this.updater((state, allProviders: GameProvider[]) => ({
    ...state,
    allProviders,
  }));

  readonly addCategories = this.updater((state, categories: Category[]) => ({
    ...state,
    categories,
  }));
  readonly addTrendingSlots = this.updater((state, trendingSlots: TrendingSlot[]) => ({
    ...state,
    trendingSlots,
  }));

  /*
   * Effects trigger side effects which manipulate the components state (we can also do this by calling .setState() in our component)
   * Effects are automatically subscribed to in the component they are called in for the life of the component
   */

  /*
   *  New to VLC games are retrieved from the category property from the cms (gamesJSON.php),
   *  Content managers can change content from the CMS like this, we can also call the cms from a middleware
   */

  readonly getTrendingSlots = this.effect(() => {
    return this.http.get<TrendingSlot[]>('/assets/trending-slot/trending-slot-content.json').pipe(
      tap(res => {
        this.addTrendingSlots(res as TrendingSlot[]);
      })
    );
  });

  readonly getGames = this.effect(() => {
    return this.http
      .get<Game[]>(this.coreService.legacyUrl + GameApiUrl.getGameList)
      .pipe(tap((games: Game[]) => this.addGames(games)));
  });

  readonly getGameProviders = this.effect(() => {
    return this.http
      .get<GameProvider[]>(this.coreService.legacyUrl + `/ajax/getproviders.php`)
      .pipe(tap((gameProviders: GameProvider[]) => this.addGameProviders(gameProviders)));
  });

  readonly getCategories = this.effect(() => {
    return this.http
      .get<Category[]>(this.coreService.legacyUrl + `/ajax/getcategories.php`)
      .pipe(tap((categories: Category[]) => this.addCategories(categories)));
  });

  getGameByLaunchCode(launchCode: string): Observable<Game | undefined> {
    return this.select(state => state.allGames.find(game => game.launchcode === launchCode));
  }

  getGamesByLaunchCode(launchCodes: string[]): Observable<Game[]> {
    return this.select(state => state.allGames.filter(game => launchCodes.includes(game.launchcode)));
  }

  getGameBySeoName(seoName: string): Observable<Game | undefined> {
    return this.select(state => state.allGames.find(game => game.seo_name === seoName));
  }

  getGamesCategory(category: string, countryCode: string, isMobile: boolean): Observable<string> {
    if (isMobile && !category.startsWith('mobile')) {
      category = `mobile${category}`;
    }

    this.getCategories();
    return this.allGameCategories$.pipe(
      takeUntil(this.destroy$),
      filter(x => !!x && x.length > 0),
      map(categories => {
        const allCategories = categories.filter(cat => cat.category.includes(category));
        const countryCategory = allCategories.find(cat => cat.category === `${category}_${countryCode.toLowerCase()}`);
        return countryCategory && countryCategory.category ? countryCategory.category : category;
      })
    );
  }
}
