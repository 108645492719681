<div class="icon-items">
  <a
    *ngIf="loggedIn$ | async"
    (click)="coreService.navigateWithRouter($event, '/games/favourites')"
    [inlineSVG]="favouriteSVG"
    [ngClass]="['btn btn-outline-light icon-item', category === 'favourites' ? 'active' : '']"></a>
  <a
    *ngIf="loggedIn$ | async"
    (click)="coreService.navigateWithRouter($event, '/games/last-played')"
    [inlineSVG]="recentlyPlayedSVG"
    [ngClass]="['btn btn-outline-light icon-item', category === 'last-played' ? 'active' : '']"></a>
  <form>
    <input
      id="gameSearchInput"
      autocomplete="off"
      #searchInputField
      *ngIf="searchIsDisplayed"
      type="search"
      name="searchInput"
      [(ngModel)]="searchInput"
      (ngModelChange)="filterGames()" />
    <button [ngClass]="['btn btn-outline-light icon-item active']" type="submit" [inlineSVG]="searchSVG" (click)="toggleSearch()"></button>
    <button
      (click)="removeSearchInput()"
      *ngIf="searchIsDisplayed"
      [routerLink]="previousUrl"
      type="reset"
      class="whg-game-nav__search-close">
      X
    </button>
  </form>
</div>
<ul class="link-items game-category-list" #slider>
  <li *ngFor="let category of categories$ | async">
    <a
      [ngClass]="['link-item', isActive(category) ? 'active' : '', category.style]"
      [href]="category.url"
      (click)="coreService.navigateWithRouter($event, category.url)">
      {{ category.text | vegastranslate }}
    </a>
  </li>
</ul>
<ul class="navigation-arrows">
  <li>
    <button [inlineSVG]="leftArrow" (click)="scrollLeft()" [ngClass]="{ 'arrow-disabled': sliderScrollLeft === 0 }"></button>
  </li>
  <li>
    <button [inlineSVG]="rightArrow" (click)="scrollRight()" [ngClass]="{ 'arrow-disabled': sliderScrollRight === 0 }"></button>
  </li>
</ul>
