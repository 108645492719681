<form class="search-container">
  <button (click)="onClickSearch()" type="submit" class="search-icon" [inlineSVG]="searchSVG"></button>
  <input
    autocomplete="off"
    id="gameSearchInput"
    #searchInputField
    type="search"
    class="search-input"
    placeholder="Search for game or provider"
    name="searchInput"
    [(ngModel)]="searchInput"
    (ngModelChange)="filterGames()"
    (focus)="onFocusSearch()"
  />
  <button [routerLink]="previousUrl" type="reset" class="whg-search__close">X</button>
</form>
