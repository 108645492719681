import { Injectable } from '@angular/core';

import { RecentPlayedStore } from '../recent-played/recent-played.store';
import { GameModeEnum } from './play.model';

@Injectable({
  providedIn: 'root',
})
export class PlayService {
  constructor(private recentPlayedStore: RecentPlayedStore) {}

  getGameUrl(launchCode: string, isLoggedIn: boolean, mode: GameModeEnum): string {
    return isLoggedIn ? `/play/${launchCode.toLowerCase()}${mode === GameModeEnum.Fun ? '/fun' : ''}` : '/register';
  }

  setRecentGamesAndNavigate(launchcode: string, link: string): void {
    this.recentPlayedStore.addLaunchCodeWithBeacon(launchcode);
    window.location.assign(link);
  }
}
