import { FooterConfig } from './footer.models';

export const footerConfig: FooterConfig = {
  mgaLogo: {
    logo: '/assets/footer/mga.svg',
    url: 'https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=633683dc-daea-49e5-9a7e-da843d38e7de&details=1',
    target: '_blank',
  },
  gamblingCommission: {
    logo: '/assets/footer/gambling-commission.svg',
    url: 'https://registers.gamblingcommission.gov.uk/52894',
    target: '_blank',
  },
  gamblingWarning: [
    {
      logo: '/assets/footer/18+.svg',
      url: '/playerprotection',
      style: '',
      target: '_self',
    },
    {
      logo: '/assets/footer/gamblingtherapy.png',
      url: 'https://www.gamblingtherapy.org/',
      style: '',
      target: '_blank',
    },
    {
      logo: '/assets/footer/betblocker.png',
      url: 'https://betblocker.org/',
      style: '',
      target: '_blank',
    },
  ],
  companyLogo: {
    logo: '/assets/footer/logo.svg',
    url: '/',
  },
  links: [
    {
      text: 'Sign-up',
      url: '/create-account',
      target: '_self',
    },
    {
      text: 'Login',
      url: '/login',
      target: '_self',
    },
    {
      text: 'VIP',
      url: '/vip',
      target: '_self',
      class: 'footer-vip-link',
      hideOnUrls: ['playerprotection'],
    },
    {
      text: 'Terms & Conditions',
      url: '/terms',
      target: '_self',
    },
    {
      text: 'Promotional Terms and Conditions',
      url: '/promoterms',
      target: '_self',
    },
    {
      text: 'Privacy Statement',
      url: '/privacy',
      target: '_self',
    },
    {
      text: 'Complaints and Disputes',
      url: '/terms&scrollTo=complaintsanddisputes',
      target: '_self',
    },
    {
      text: 'Responsible Gambling',
      url: '/playerprotection',
      target: '_self',
    },
    {
      text: 'Game RTP',
      url: '/rtp',
      target: '_self',
    },
    {
      text: 'Affiliates',
      url: 'https://www.dreamteamaffiliates.com/',
      target: '_blank',
    },
  ],
  countryLogos: [
    {
      logo: '/assets/country/english.svg',
      text: 'English',
      url: '?language=en',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/deutch.svg',
      text: 'Deutsch',
      url: '?language=de',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/norsk.svg',
      text: 'Norsk',
      url: '?language=no',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/soumi.svg',
      text: 'Soumi',
      url: '?language=fi',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/espanol.svg',
      text: 'Espanol',
      url: '?language=es',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/french.svg',
      text: 'Francais',
      url: '?language=fr',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      logo: '/assets/country/portuguese.svg',
      text: 'Portuguese',
      url: '?language=pt',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
  ],
};
