import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, Params } from '@angular/router';

/**
 * Resolve legacy games urls to a seo friendly version
 *
 * Converts:
 * /index.php?games           => /games
 * /?games                    => /games
 * /?page=games&cat=free-play => /games/free-play (Possible used in some legacy code)
 * /games?category=freeplay   => /games/free-play (Used on /sitemap)
 */
@Injectable()
export class LegacyResolverService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let url = '';

    const params = route.queryParams;
    if (params.page) {
      url = params.page;
    }

    if (params.cat) {
      if (!url && route.url[0]) {
        url = route.url[0].path;
      }
      url += '/' + params.cat;
    } else if (params.category) {
      // Support links coming from /sitemap
      const childpath = this.resolveGameUrlWithFullCategoryParam(params);
      if (childpath && !url && route.url[0]) {
        url = route.url[0].path;
      }
      if (childpath) {
        url += childpath;
      }
    }

    if (url) {
      this.router.navigateByUrl(url, { replaceUrl: true });
    }
    return !url;
  }

  /**
   * Each brand has the /sitemap page, this page contains links to category pages.
   * These links are in the format /games?category=:category
   * For example:  https://www.vegasloungecasino.com/games?category=freeplay
   *
   * @returns Subroute of the seo category url, e.g. ?category=freeplay -> /free-play
   */
  private resolveGameUrlWithFullCategoryParam(params: Params) {
    // Routes config stored in legacy.
    const legacyRoutes = window.routes;

    if (legacyRoutes) {
      const gameRoute = legacyRoutes.find(route => route.path === 'games');
      if (gameRoute && gameRoute.children) {
        const categoryRoute = gameRoute.children.find(route => !!route.data && route.data.category === params.category);
        if (categoryRoute) {
          return '/' + categoryRoute.path;
        }
      }
    }
  }
}
