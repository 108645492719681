export const CASINO_VIDEOS = [
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Caribbean-Stud/2018_feb_poker_suite_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Caribbean-Stud/poker_studio_edit.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Dream-Catcher/2018_feb_dream_catcher_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Dream-Catcher/dream_catcher_video_20_sec_music_2021_02.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Holdem/04_2019_2_hand_casino_holdem_20_seconds_with_ui.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Holdem/04_2019_2_hand_casino_holdem_with_ui.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Lightning-Roulette/2018_mar_lightning_roulette_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Lightning-Roulette/2020_03_ruleta_relampago_35_sec.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Lightning-Roulette/lightning_roulette_video_10_sec_music_2021_02.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Lightning-Roulette/lightning_roulette_video_15_sec_music_2021_02.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Baccarat/2018_aug_malta_video_venezia_environment_overview.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Baccarat/2018_feb_dragon_tiger_promo_video_footage.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Baccarat/2019_oct_lightning_baccarat_brunette_female_dealer_4587_1994_with_evo_logo.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Baccarat/first_person_baccarat_video_2020_04.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Blackjack/2018_june_vip_environment_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Blackjack/2018_mar_vip_blackjack_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Crazy-Time/crazy_time_video_36_sec_with_ui_with_music_2020_05.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Roulette/2018_aug_malta_video_venezia_roulette.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Live-Roulette/2018_feb_vip_video.mp4',
    'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/Ultimate-Texas-Holdem/2018_mar_ultimate_texas_holdem_video.mp4',
];

export const TRENDING_VIDEOS = [
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_Aloha.mp4', launchCode: 'NEALOHACLUSTERPAYS' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_Bonanza.mp4', launchCode: 'NYXBONANZA' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_DangerHighVoltage.mp4', launchCode: 'NYXDANGERHIGHVOLTAGE' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_FireJoker.mp4', launchCode: 'PNGFIREJOKER' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_ImmortalRomance.mp4', launchCode: 'MGIMMORTALROMANCE' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_JaneBlondeReturns.mp4', launchCode: 'MGAGENTJANEBLONDE' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_LegacyOfDead.mp4', launchCode: 'PNGLEGACYOFDEAD' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_Pimped.mp4', launchCode: 'PNGPIMPED' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_Starburst.mp4', launchCode: 'NESTARBURST' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_TheGoonies.mp4', launchCode: 'BPTHEGOONIES' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_WackyPanda.mp4', launchCode: 'MGWACKYPANDA' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_WolfGold.mp4', launchCode: 'PMWOLFGOLD' },
    { url: 'https://wh-content.whitehatgaming.com/vegasloungecasino/images/live-at-lounge/trending/TNow_BookOfDead.mp4', launchCode: 'PNGBOOKOFDEAD' },
];
