import { AchievementLink } from '../core-services/achievements/achievements.model';

export interface WidgetConfig {
  type: WidgetTypeEnum;
  items: WidgetItem[];
  itemType?: WidgetItemTypeEnum;
}

export interface WidgetItem {
  id: string;
  title: string;
  description: string;
  shortDescription: string;
  image: string;
  remainingTime: string;
  terms: string;
  sigTerms: string;
  startDate?: Date;
  endDate?: Date;
  game?: string;
  dependantOn?: AchievementLink[];
  optinRequired?: boolean;
  hasOptIn?: boolean;
}

export enum WidgetTypeEnum {
  list = 'list',
  item = 'item',
  table = 'table',
}

export enum WidgetItemTypeEnum {
  challenges = 'challenges',
  tournaments = 'tournaments',
}
