import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FavoritesState } from './favorites.model';
import { Game } from '../games/games.model';
import { PlatformService } from '../platform/platform.service';
import { GetParamPlatformResponse, PlatformRequestTypes } from '../platform/platform.model';
import { GamesStore } from '../games/games.store';
import { VisitorStore } from '../visitor/visitor.store';

@Injectable({
  providedIn: 'root',
})
export class FavoritesStore extends ComponentStore<FavoritesState> {
  private readonly platformParamName = 'favorites';

  constructor(
    private platformService: PlatformService,
    private gamesStore: GamesStore,
    private visitorStore: VisitorStore
  ) {
    super({
      favorites: new Set(),
    });
  }

  readonly favorites$ = this.select(state => state.favorites);

  readonly setFavorites = this.updater((state, favorites: Set<Game>) => ({ ...state, favorites }));

  readonly getFavorites = this.effect(() => {
    return this.visitorStore.loggedIn$.pipe(
      takeUntil(this.destroy$),
      // Run effect only when a user is logged in
      filter(loggedIn => !!loggedIn),
      mergeMap(() =>
        this.platformService
          .execute(PlatformRequestTypes.GET_PARAM, {
            name: this.platformParamName,
          })
          .pipe(
            map<GetParamPlatformResponse, string[]>(({ value }) => {
              try {
                return JSON.parse(value);
              } catch {
                return [];
              }
            }),
            mergeMap(launchcodes =>
              this.gamesStore.allGames$.pipe(
                map(games => games.filter(game => launchcodes.includes(game.launchcode))),
                map(favorites => new Set(favorites))
              )
            ),
            tap<Set<Game>>(favorites => this.setFavorites(favorites))
          )
      )
    );
  });

  public updateFavorites = this.effect<Set<Game>>(favorites$ => {
    return favorites$.pipe(
      mergeMap(favorites =>
        this.platformService
          .execute(PlatformRequestTypes.SET_PARAM, {
            name: this.platformParamName,
            value: JSON.stringify(Array.from(favorites).map(game => game.launchcode)),
          })
          .pipe(tap(() => this.setFavorites(favorites)))
      )
    );
  });

  addFavorite(game: Game): Observable<Set<Game>> {
    return this.favorites$.pipe(
      map(existingFavorites => {
        return new Set([...Array.from(existingFavorites), game]);
      }),
      tap(games => this.updateFavorites(games))
    );
  }

  removeFavorite(game: Game): Observable<Set<Game>> {
    return this.favorites$.pipe(
      map(currentFavorites => {
        const newSet = new Set(currentFavorites);
        newSet.delete(game);
        return newSet;
      }),
      tap(games => this.updateFavorites(games))
    );
  }

  addFavourites(games: Game[]): Observable<Set<Game>> {
    return this.favorites$.pipe(
      map(existingFavorites => new Set([...Array.from(existingFavorites), ...games])),
      tap(g => this.updateFavorites(g))
    );
  }
}
