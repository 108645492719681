import { Component, HostBinding, Input } from '@angular/core';
import { VIPLoyaltyElemConfig } from './reward-card.model';

export const REWARD_CARD_SELECTOR = 'whg-reward-card';

@Component({
  selector: REWARD_CARD_SELECTOR,
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.scss'],
})
export class RewardCardComponent {
  @Input() set config(config: string) {
    this.parsedConfig = JSON.parse(config);
  }
  @Input() currentLevel?: boolean;

  @HostBinding('class.active-card') @Input() active?: boolean;
  vipStatus = false;
  parsedConfig?: VIPLoyaltyElemConfig;
}
