import { Component, Input } from '@angular/core';
const TAB_SELECTOR: string = 'whg-tab';

@Component({
  selector: TAB_SELECTOR,
  styleUrls: ['./tab.component.scss'],
  templateUrl: './tab.component.html',
})
export class TabComponent {
  @Input() public title?: string;
  @Input() public active: boolean = false;
}
