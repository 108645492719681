import { Component, Input } from '@angular/core';

const EMPTY_TILE_SELECTOR = 'whg-empty-tile';

@Component({
  selector: EMPTY_TILE_SELECTOR,
  templateUrl: './empty-tile.component.html',
  styleUrls: ['./empty-tile.component.scss'],
})
export class EmptyTileComponent {
  favoriteSvgPath = '/assets/icons/favourites.svg';
  @Input() iconPath = '/assets/icons/recently-played.svg';
  @Input() redirectLink?: string;
}
