import { Directive, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';

import { Subject, Observable, fromEvent } from 'rxjs';
import { throttleTime, debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[resizeDir]',
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Output() resizeEvt = new EventEmitter<void>();

  private readonly resize$: Observable<Event>;

  private readonly TIME_GAP = 500;
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.resize$ = fromEvent(window, 'resize').pipe(
      throttleTime(this.TIME_GAP),
      debounceTime(this.TIME_GAP),
      takeUntil(this.destroy$)
    );
  }

  ngOnInit() {
    this.resize$.subscribe(() => this.resizeEvt.emit());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
