<p *ngIf="!tiles || tiles.length < 1" class="whg-game-recent-played__text">
  Your recently played games are saved here. Jump right back into the fun.
</p>
<div class="whg-game-recent-played__tile-wrapper" resizeDir (resizeEvt)="resize()">
  <ng-container *ngIf="!isLoading; else empty">
    <ng-container *ngFor="let tile of tiles">
      <whg-tile #tiles class="position-relative" [tile]="tile" [isLoggedIn]="isLoggedIn"> </whg-tile>
    </ng-container>
  </ng-container>
  <ng-template #empty>
    <ng-container *ngFor="let tile of emptyTilesArray">
      <whg-empty-tile></whg-empty-tile>
    </ng-container>
  </ng-template>
</div>
<div class="my-5">
  <div *ngFor="let html of jsonHTMLSEO$ | async" [innerHTML]="html"></div>
</div>
