import { Game } from '../core-services/games/games.model';

export interface TileInterface {
  id?: number;
  image: TileImage;
  title: string;
  subTitle: string;
  game?: Game;
  link: string;
  hot?: number;
  new?: number;
  isBigWin?: boolean;
  category?: string;
  bigWinAmount?: number;
}

export class TileImage {
  src!: string;
  alt!: string;

  constructor() {
  }

}

export class Tile implements TileInterface {
  id?: number;
  image!: TileImage;
  title!: string;
  subTitle!: string;
  game?: Game;
  link!: string;
  hot?: number;
  new?: number;
  isBigWin?: boolean;
  bigWinAmount: number = 0;
  category?: string;
  isPlaceholder = false;

  constructor() {
  }
}
