import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import { Competition, Contest, Reward } from '../core-services/tournaments/tournaments.model';
import { TournamentsService } from '../core-services/tournaments/tournaments.service';
import { GamificationWidgetService } from '../gamification-widget/gamification-widget.service';
import { WidgetItemTypeEnum } from '../gamification-widget/gamification-widget.model';

const TOURNAMENT_SELECTOR = 'whg-tournament';

@Component({
  selector: TOURNAMENT_SELECTOR,
  templateUrl: './tournament.component.html',
  styleUrls: ['./tournament.component.scss'],
})
export class TournamentComponent implements OnInit, OnDestroy {
  @Input() contest!: Contest & { tournament: Competition };
  @Input() userId: number | undefined;
  @Input() isLoggedIn = false;
  public remainingTime!: string;
  public rewards: Reward[] = [];
  public isTodayPromotion!: boolean;

  private timer!: number;

  constructor(
    private tournamentsService: TournamentsService,
    private gamificationWidgetService: GamificationWidgetService
  ) {}

  ngOnInit() {
    this.setRemainingTime();
    this.timer = window.setInterval(() => this.setRemainingTime(), 1000);
    this.setRewards();
    this.isTodayPromotion = this.isATodayPromotion(
      this.contest.tournament.scheduledStart,
      this.contest.tournament.scheduledEnd
    );
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  openTournamentDetails(id: string) {
    this.gamificationWidgetService.openWidget(WidgetItemTypeEnum.tournaments, id);
  }

  takePart() {
    const tournamentId = this.contest.tournament.id;
    if (this.userId && tournamentId) {
      this.tournamentsService.optIn(this.userId, tournamentId).subscribe(() => {
        this.contest.tournament.optinRequired = false;
      });
    }
  }

  private setRewards(): void {
    this.tournamentsService
      .getTournamentDetail(this.contest.id)
      .pipe(first())
      .subscribe(({ data: { rewards } }) => (this.rewards = rewards));
  }

  private setRemainingTime(): void {
    const now = moment();
    const scheduledEnd = moment(this.contest.scheduledEnd);
    this.remainingTime =
      moment.utc(scheduledEnd.diff(now, 'days')) +
      'd ' +
      moment.utc(scheduledEnd.diff(now)).format('HH') +
      'h ' +
      moment.utc(scheduledEnd.diff(now)).format('mm') +
      'm ' +
      moment.utc(scheduledEnd.diff(now)).format('ss') +
      's ';
  }

  private isATodayPromotion(start: Date, end: Date) {
    const today = moment();
    const startD = moment(start).startOf('day');
    const endD = moment(end).endOf('day');
    return today.isBetween(startD, endD);
  }
}
