import { Component, Input, OnInit } from '@angular/core';
import { filter, first, map, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { TileInterface } from './tile.model';
import { Game, GameCategory } from '../core-services/games/games.model';
import { FavoritesStore } from '../core-services/favorites/favorites.store';
import { BaseComponent } from '../base.component';
import { PlayService } from '../core-services/play/play.service';
import { LoginComponent } from '../login/login.component';
import { FavouritesService } from '../core-services/favorites/favourites.service';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { CoreService } from '../core-services/core.service';
import { GameJackpotStore } from '../core-services/game-jackpots/game-jackpots.store';
import { CountryCodesType } from '../gamification-widget/country-currency-codes';
import { GameJackpot, GameJackpots } from '../core-services/game-jackpots/game-jackpots.model';

const TILE_SELECTOR: string = 'whg-tile';

@Component({
  selector: TILE_SELECTOR,
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent extends BaseComponent implements OnInit {
  @Input() public tile!: TileInterface;
  @Input() public isLoggedIn?: boolean = false;
  public modalRef?: NgbModalRef;
  public flag?: string;
  public titleMaxLength: number | null = null;
  public subMaxTitleLength: number | null = null;

  public countryCode!: CountryCodesType;
  public jackpot$!: Observable<number | undefined>;

  constructor(
    private favoriteStore: FavoritesStore,
    private favouritesService: FavouritesService,
    private ngbModal: NgbModal,
    private playService: PlayService,
    private visitorStore: VisitorStore,
    private gameJackpotStore: GameJackpotStore,
    public coreService: CoreService
  ) {
    super();
    const ONE: number = 1;

    this.visitorStore.countryCode$.pipe(take(ONE)).subscribe((v: string) => (this.countryCode = v as CountryCodesType));
  }

  public ngOnInit(): void {
    if (this.tile && this.tile.game) {
      if (this.tile.game.categories.some((c: GameCategory) => c.category === 'top')) {
        this.flag = 'Recommended';
      } else if (this.tile.game.categories.some((c: GameCategory) => c.category === 'new')) {
        this.flag = 'New';
      }
      this.getFakeJackpot();
    }
    if (this.tile?.isBigWin) {
      this.flag = 'Big Win';
    }
    this.truncateText();
  }

  public isFavourite(game: Game): Observable<boolean> {
    this.favoriteStore.getFavorites();

    return this.favoriteStore.favorites$.pipe(
      takeUntil(this.destroy$),
      filter((x: Set<Game>) => !!x),
      map((favoriteSet: Set<Game>) => favoriteSet.has(game))
    );
  }

  public toggleFavourite(game: Game): void {
    if (!this.isLoggedIn) {
      this.handleLocalFavourites(game);

      return;
    }

    this.isFavourite(game)
      .pipe(first())
      .subscribe((isFavourite: boolean) => (isFavourite ? this.removeFavourite(game) : this.addFavourite(game)));
  }

  public isLocalFavourite(game: Game): boolean {
    return this.favouritesService.isLocalFavourite(game.launchcode);
  }

  public tileClick(): void {
    const game: Game = this.tile.game as Game;

    this.playService.setRecentGamesAndNavigate(game.launchcode, this.tile.link);
  }

  public truncateText(): void {
    const width: number = window.innerWidth;

    // eslint-disable-next-line no-magic-numbers
    if (width <= 400) {
      this.titleMaxLength = 16;
      this.subMaxTitleLength = 14;
      // eslint-disable-next-line no-magic-numbers
    } else if (width > 400 && width < 500) {
      this.titleMaxLength = 24;
      this.subMaxTitleLength = 18;
      // eslint-disable-next-line no-magic-numbers
    } else if (width >= 500 && width < 600) {
      this.titleMaxLength = 40;
      this.subMaxTitleLength = 24;
    } else {
      this.titleMaxLength = null;
      this.subMaxTitleLength = null;
    }
  }

  private handleLocalFavourites(game: Game): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    const { launchcode } = game;
    const localFavourites: string[] = this.favouritesService.getLocalFavourites();

    // eslint-disable-next-line no-unused-expressions
    this.isLocalFavourite(game)
      ? this.removeLocalFavourite(localFavourites, launchcode)
      : this.addLocalFavourite(localFavourites, launchcode);
  }

  private addLocalFavourite(localFavourites: string[], launchCode: string): void {
    this.favouritesService.updateLocalFavourites(
      JSON.stringify({
        favourites: localFavourites.length > 0 ? `${localFavourites.join(',')},${launchCode}` : launchCode,
      })
    );

    if (this.favouritesService.getLocalFavourites().length > 1) {
      this.openLoginModal();
    }
  }

  private removeLocalFavourite(localFavourites: string[], launchCode: string): void {
    this.favouritesService.updateLocalFavourites(
      JSON.stringify({
        favourites: localFavourites.filter((f: string) => f !== launchCode).join(','),
      })
    );
  }

  private addFavourite(game: Game): void {
    this.favoriteStore.addFavorite(game).pipe(first()).subscribe();
  }

  private removeFavourite(game: Game): void {
    this.favoriteStore.removeFavorite(game).pipe(first()).subscribe();
  }

  private openLoginModal(): void {
    const resolver = (): void => {
      this.modalRef = undefined;
    };

    this.modalRef = this.ngbModal.open(LoginComponent);
    this.modalRef.result.then(resolver, resolver);
  }

  private getFakeJackpot(): unknown {
    const launchCode: string | undefined = this.tile.game?.launchcode;

    if (!launchCode) {
      return;
    }

    this.gameJackpotStore.getFakeJackpotsTotals(this.visitorStore.currencyCode$);
    this.jackpot$ = this.gameJackpotStore.fakeGameJackpots$.pipe(
      // eslint-disable-next-line @typescript-eslint/typedef
      filter((state) => !!state && !!this.tile.game && !!launchCode),
      // eslint-disable-next-line @typescript-eslint/typedef
      map((jackpot) => jackpot?.fakeJackpots[launchCode]?.value),
      filter((jackpot: number | undefined) => !!jackpot)
    );
  }
}
