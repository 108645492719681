import { Component, HostListener, Inject } from '@angular/core';

import { DOCUMENT } from '@angular/common';

export const SCROLL_TOP_SELECTOR = 'whg-scroll-top';

@Component({
  selector: SCROLL_TOP_SELECTOR,
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
})
export class ScrollTopComponent {
  public backToTopIcon = 'assets/icons/back-to-top.svg';
  public windowScrolled = false;
  private request?: number;

  constructor(@Inject(DOCUMENT) private document: Document) {
    /* scrollBehavior: 'smooth' conflicts with programmatic scrolling.
    We want to make sure it is not set (which for this particular site, is)
    see: https://whgaming.atlassian.net/browse/WPT-5783
    */
    document.documentElement.style.scrollBehavior = 'auto';
    // If the page is already scrolled
    this.onWindowScroll();
    // Using a passive event so it doesn't slow down the broser
    window.addEventListener('scroll', this.onWindowScroll.bind(this), { passive: true });
    window.addEventListener('mousewheel', this.stopScrollTop.bind(this), { passive: true });
  }

  onWindowScroll() {
    if (window.pageYOffset || this.document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if ((this.windowScrolled && window.pageYOffset) || this.document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  smoothScroll = () => {
    const currentScroll = this.document.body.scrollTop || window.pageYOffset;
    if (currentScroll > 0 && this.request) {
      this.request = requestAnimationFrame(this.smoothScroll);
      window.scrollTo(0, currentScroll - currentScroll / 8);
    } else {
      this.stopScrollTop();
    }
  };

  scrollToTop(): void {
    this.request = requestAnimationFrame(this.smoothScroll);
  }

  stopScrollTop() {
    if (this.request) {
      cancelAnimationFrame(this.request);
    }
  }
}
