export enum HeaderTypes {
  LoggedIn,
  LoggedOut,
  Restricted,
}
export enum HeaderItemType {
  Anchor,
  Button,
  Disabled,
}

export interface LogoConfig {
  url: string;
  img: string;
}

export interface IconConfig {
  url: string;
  icon: string;
}

export interface LoginConfig {
  url: string;
}

export type HeaderItem = {
  url: string;
  text: string;
  icon: string;
  styles: string;
} & (
  | {
      type: HeaderItemType.Button;
    }
  | {
      type: HeaderItemType.Disabled;
    }
  | {
      type: HeaderItemType.Anchor;
    }
);

export interface HeaderConfig {
  logout: IconConfig;
  message: IconConfig;
  logo: LogoConfig;
  account: IconConfig;
  menu: { [key in HeaderTypes]: HeaderItem[] };
  login: LoginConfig;
}
