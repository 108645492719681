import { BottomNavConfig, BottomNavItemType } from './bottom-nav.models';

export const bottomNavConfig: BottomNavConfig[] = [
  {
    url: '/home',
    icon: '/assets/icons/home.svg',
    type: BottomNavItemType.Anchor,
    styles: 'bottom-nav-icon',
  },
  {
    url: '/games',
    icon: '/assets/icons/games.svg',
    type: BottomNavItemType.Anchor,
    styles: 'bottom-nav-icon',
  },
  {
    url: '',
    icon: '/assets/icons/wallet.svg',
    type: BottomNavItemType.Button,
    styles: 'cashier-link bottom-nav-icon',
  },
  {
    url: '/promotions',
    icon: '/assets/icons/promotions.svg',
    type: BottomNavItemType.Anchor,
    styles: 'bottom-nav-icon promotions',
    liStyles: 'promotions-nav-item',
  },
  {
    url: '/support',
    icon: '/assets/icons/support.svg',
    type: BottomNavItemType.Anchor,
    styles: 'bottom-nav-icon',
  },
];
