import { Component, OnInit, Input } from '@angular/core';

import { takeUntil, tap, map } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

import { UserStore } from '../core-services/user/user.store';
import { VIPLoyaltyElemConfig } from '../reward-card/reward-card.model';

export const LOYALTY_PROGRESS_BAR_SELECTOR = 'whg-loyalty-progress-bar';

@Component({
  selector: LOYALTY_PROGRESS_BAR_SELECTOR,
  templateUrl: './loyalty-progress-bar.component.html',
  styleUrls: ['./loyalty-progress-bar.component.scss'],
})
export class LoyaltyProgressBarComponent extends BaseComponent implements OnInit {
  level = 0;
  points = 0;
  progress = 0;

  currentLevel!: VIPLoyaltyElemConfig;
  nextLevel!: VIPLoyaltyElemConfig;

  parsedConfig?: { [key: number]: VIPLoyaltyElemConfig };

  @Input() set config(config: string) {
    this.parsedConfig = JSON.parse(config);
  }

  constructor(private readonly userStore: UserStore) {
    super();
  }

  ngOnInit() {
    this.setLoyaltyLevel();
    this.setLoyaltyPoints();
    this.setLoyaltyProgress();
  }

  private setLoyaltyLevel() {
    this.userStore.level$
      .pipe(
        tap(level => (this.level = level ? level : 1)),
        tap(() => this.setCurrentAndNextLevel(this.level)),
        tap(() => this.setCurrentAndNextLevelColors()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setLoyaltyPoints(): void {
    this.userStore.points$
      .pipe(
        tap(points => (this.points = points ? points : 0)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setLoyaltyProgress(): void {
    this.userStore.experience$
      .pipe(
        map(userExp => {
          if (!userExp) {
            return 0;
          }
          if (!userExp.nextLevelAt) {
            return 100;
          }
          const range = userExp.nextLevelAt - userExp.startedAt;
          const levelPoints = userExp.points - userExp.startedAt;
          return (levelPoints / range) * 100;
        }),
        tap(percentage => (this.progress = percentage)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setCurrentAndNextLevel(level: number): void {
    if (!this.parsedConfig) {
      return;
    }
    const maxLevel = +Object.keys(this.parsedConfig).reduce((acc, curr) => (curr > acc ? curr : acc));
    this.currentLevel = this.parsedConfig[level === maxLevel ? level - 1 : level];
    this.nextLevel = this.parsedConfig[level === maxLevel ? level : level + 1];
  }

  private setCurrentAndNextLevelColors() {
    if (this.currentLevel && this.nextLevel) {
      document.documentElement.style.setProperty('--strike-color-current', `${this.currentLevel.header.styles}`);
      document.documentElement.style.setProperty('--strike-color-next', `${this.nextLevel.header.styles}`);
    }
  }
}
