import { OnDestroy, Injectable, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';

declare var componentCreated: Function;
declare var componentContentInit: Function;

@Injectable()
export abstract class BaseComponent implements OnDestroy, AfterContentInit {
  destroy$ = new Subject<void>();

  protected constructor() {
    if (typeof componentCreated === 'function') {
      componentCreated();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterContentInit(): void {
    if (typeof componentContentInit === 'function') {
      componentContentInit();
    }
  }
}
