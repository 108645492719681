import { Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LazyLoadImagesModule } from '@jfkz/ngx-lazy-load-images';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Todo: only import bootstrap components that we use.
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LegacyResolverService } from './core-services/legacy-resolver/legacy-resolver.service';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { LegacyGatewayComponent, LEGACY_GATEWAY_SELECTOR } from './legacy-gateway/legacy-gateway.component';
import { MenuComponent, MENU_SELECTOR } from './menu/menu.component';
import { FOOTER_SELECTOR, FooterComponent } from './footer/footer.component';
import { HEADER_SELECTOR, HeaderComponent } from './header/header.component';
import { BOTTOM_NAV_SELECTOR, BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { GAME_NAV_SELECTOR, GameNavComponent } from './game-nav/game-nav.component';
import { SearchComponent } from './search/search.component';
import { BannerComponent } from './banner/banner.component';
import { GameTilesComponent } from './game-tiles/game-tiles.component';
import { TileComponent } from './tile/tile.component';
import { EmptyTileComponent } from './empty-tile/empty-tile.component';
import { LOGIN_SELECTOR, LoginComponent } from './login/login.component';
import { LOYALTY_PROGRESS_BAR_SELECTOR, LoyaltyProgressBarComponent } from './loyalty-progress-bar/loyalty-progress-bar.component';
import { STATUS_BAR_SELECTOR, StatusBarComponent } from './status-bar/status-bar.component';
import { GAME_PAGE_SELECTOR, GamesPageComponent } from './games-page/games-page.component';
import { GameFavouriteComponent } from './game-favourite/game-favourite.component';
import { GameSearchResultComponent } from './game-search-result/game-search-result.component';
import { RewardCardComponent, REWARD_CARD_SELECTOR } from './reward-card/reward-card.component';
import { RewardDeckComponent, REWARD_DECK_SELECTOR } from './reward-deck/reward-deck.component';
import { INFO_CARDS_SELECTOR, InfoCardsComponent } from './info-cards/info-cards.component';
import { CardComponent } from './card/card.component';
import { MAIN_BANNER_SELECTOR, MainBannerComponent } from './main-banner/main-banner.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { ResizeDirective } from './directives/resize.directive';
import { FavouritesService } from './core-services/favorites/favourites.service';
import { PaymentMethodsListComponent, PAYMENT_METHODS_LIST_SELECTOR } from './payment-methods-list/payment-methods-list.component';
import {
  PaymentMethodsListHomeComponent,
  PAYMENT_METHODS_LIST_HOME_SELECTOR,
} from './payment-methods-list-home/payment-methods-list-home.component';
import { TILE_SLIDER_SELECTOR, TileSliderComponent } from './tile-slider/tile-slider.component';
import { GAME_RECENT_PLAYED_SELECTOR, GameRecentPlayedComponent } from './game-recent-played/game-recent-played.component';
import { CALENDAR_SELECTOR, CalendarComponent } from './calendar/calendar.component';
import { PROMOTION_NOTIFIER_SELECTOR, PromotionNotifierComponent } from './promotion-notifier/promotion-notifier.component';
import { SCROLL_TOP_SELECTOR, ScrollTopComponent } from './scroll-top/scroll-top.component';
import { PROMOTIONS_TODAY_SELECTOR, PromotionsTodayComponent } from './promotions-today/promotions-today.component';
import { PromotionsTodayCardComponent } from './promotions-today-card/promotions-today-card.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { AmountAsLocalePipe } from './pipes/amount-as-locale.pipe';
import { HIGHLIGHTS_SELECTOR, HighlightsComponent } from './highlights/highlights.component';
import { HighlightsTileComponent } from './highlights-tile/highlights-tile.component';
import { INFO_MODAL_SELECTOR, InfoModalComponent } from './info-modal/info-modal.component';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EVENTS_AT_LOUNGE_SELECTOR, EventsAtLoungeComponent } from './events-at-lounge/events-at-lounge.component';
import { LIVE_AT_LOUNGE_SELECTOR, LiveAtLoungeComponent } from './live-at-lounge/live-at-lounge.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TabsComponent } from './tabs/tabs/tabs.component';
import { GAMIFICATION_WIDGET_SELECTOR, GamificationWidgetComponent } from './gamification-widget/gamification-widget.component';
import { ChallengesWidgetComponent } from './challenges-widget/challenges-widget.component';
import { TournamentsWidgetComponent } from './tournaments-widget/tournaments-widget.component';
import { TOURNAMENTS_SLIDER_SELECTOR, TournamentsSliderComponent } from './tournaments-slider/tournaments-slider.component';
import { TournamentComponent } from './tournament/tournament.component';
import { ACCORDION_SELECTOR, AccordionComponent } from './accordion/accordion.component';
import { ACCORDION_ITEM_SELECTOR, AccordionItemComponent } from './accordion/accordion-item/accordion-item.component';
import { LATEST_WINS_SELECTOR, LatestWinsComponent } from './latest-wins/latest-wins.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedTranslateModule } from './shared-translate.module';
import { CurrencyConverterPipe } from './gamification-widget/currency-converter.pipe';

declare global {
  interface Window {
    routes?: Routes;
  }
}

// Default route to fallback on
const fallBackRoutes: Routes = [{ path: '**', children: [], canActivate: [LegacyResolverService] }];

// Maps a legacy view string to a Angular Component
const RouteViews: Record<string, any> = {
  list: GameTilesComponent,
  lastPlayed: GameRecentPlayedComponent,
  search: GameSearchResultComponent,
  favourites: GameFavouriteComponent,
};

const COMPONENTS = [
  AccordionComponent,
  AccordionItemComponent,
  AppComponent,
  BannerComponent,
  BottomNavComponent,
  CalendarComponent,
  FooterComponent,
  GameNavComponent,
  GameTilesComponent,
  HeaderComponent,
  InfoCardsComponent,
  LegacyGatewayComponent,
  LoginComponent,
  LoyaltyProgressBarComponent,
  LatestWinsComponent,
  MenuComponent,
  InfoModalComponent,
  RewardCardComponent,
  RewardDeckComponent,
  StatusBarComponent,
  GamesPageComponent,
  TileSliderComponent,
  GameFavouriteComponent,
  GameSearchResultComponent,
  MainBannerComponent,
  ScrollTopComponent,
  PromotionsTodayComponent,
  PromotionNotifierComponent,
  GameRecentPlayedComponent,
  HighlightsComponent,
  EventsAtLoungeComponent,
  LiveAtLoungeComponent,
  GamificationWidgetComponent,
  TournamentsSliderComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    CardComponent,
    ChallengesWidgetComponent,
    EmptyTileComponent,
    EllipsisPipe,
    AmountAsLocalePipe,
    CurrencyConverterPipe,
    ImageFallbackDirective,
    ResizeDirective,
    TileComponent,
    PaymentMethodsListComponent,
    PaymentMethodsListHomeComponent,
    PromotionsTodayCardComponent,
    SearchComponent,
    HighlightsTileComponent,
    SearchComponent,
    TabComponent,
    TabsComponent,
    TournamentsWidgetComponent,
    TournamentComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    InlineSVGModule,
    LazyLoadImagesModule,
    NgbModule,
    NgScrollbarModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    RouterModule.forRoot(fallBackRoutes),
    SharedTranslateModule,
    TranslateModule,
  ],
  exports: [NgxSpinnerModule],
  entryComponents: [...COMPONENTS],
  providers: [CookieService, NgbActiveModal, { provide: APP_BASE_HREF, useValue: '/' }, LegacyResolverService],
})
export class AppModule {
  constructor(private injector: Injector, public router: Router, private favouriteService: FavouritesService) {
    const addComponents = (routes: Routes) => {
      routes.forEach((route) => {
        // Add resolver to resolve the query params if any.
        if (route.path === 'games') {
          route.canActivate = [LegacyResolverService];
        }

        // Use the RouteViews dictionary to map the component using the given view.
        if (route.data && route.data.view) {
          route.component = RouteViews[route.data.view];
        }

        // Add components to child routes if any.
        if (route.children) {
          addComponents(route.children);
        }
      });
    };

    // Get routes from the legacy system if any
    const legacyRoutes = window.routes || [];
    addComponents(legacyRoutes);

    this.router.resetConfig([...legacyRoutes, ...fallBackRoutes]); // Manually loading the routes from legacy.
  }

  ngDoBootstrap(app: any) {
    // Start start the full application in dev environment.
    if (location.hostname === 'localhost') {
      app.bootstrap(AppComponent);
    } else {
      // Convert Angular component to a custom element.
      const AccordionElement = createCustomElement(AccordionComponent, { injector: this.injector });
      const AccordionItemElement = createCustomElement(AccordionItemComponent, { injector: this.injector });
      const LegacyGatewayElement = createCustomElement(LegacyGatewayComponent, { injector: this.injector });
      const HeaderElement = createCustomElement(HeaderComponent, { injector: this.injector });
      const MenuElement = createCustomElement(MenuComponent, { injector: this.injector });
      const BottomNavElement = createCustomElement(BottomNavComponent, { injector: this.injector });
      const FooterElement = createCustomElement(FooterComponent, { injector: this.injector });
      const GameNavElement = createCustomElement(GameNavComponent, { injector: this.injector });
      const LoginElement = createCustomElement(LoginComponent, { injector: this.injector });
      const LoyaltyProgressBarElement = createCustomElement(LoyaltyProgressBarComponent, { injector: this.injector });
      const LatestWinsElement = createCustomElement(LatestWinsComponent, { injector: this.injector });
      const StatusBarElement = createCustomElement(StatusBarComponent, { injector: this.injector });
      const GamesPageElement = createCustomElement(GamesPageComponent, { injector: this.injector });
      const RewardCardElement = createCustomElement(RewardCardComponent, { injector: this.injector });
      const RewardDeckElement = createCustomElement(RewardDeckComponent, { injector: this.injector });
      const InfoCardsElement = createCustomElement(InfoCardsComponent, { injector: this.injector });
      const MainBannerElement = createCustomElement(MainBannerComponent, { injector: this.injector });
      const TileSliderElement = createCustomElement(TileSliderComponent, { injector: this.injector });
      const GameRecentlyPlayedElement = createCustomElement(GameRecentPlayedComponent, { injector: this.injector });
      const CalendarElement = createCustomElement(CalendarComponent, { injector: this.injector });
      const PromotionNotifierElement = createCustomElement(PromotionNotifierComponent, { injector: this.injector });
      const ScrollTopElement = createCustomElement(ScrollTopComponent, { injector: this.injector });
      const PromotionsTodayElement = createCustomElement(PromotionsTodayComponent, { injector: this.injector });
      const HighLightsElement = createCustomElement(HighlightsComponent, { injector: this.injector });
      const InfoModalElement = createCustomElement(InfoModalComponent, { injector: this.injector });
      const EventsAtLoungeElement = createCustomElement(EventsAtLoungeComponent, { injector: this.injector });
      const LiveAtLoungeElement = createCustomElement(LiveAtLoungeComponent, { injector: this.injector });
      const GamificationWidgetElement = createCustomElement(GamificationWidgetComponent, { injector: this.injector });
      const TournamentsSliderElement = createCustomElement(TournamentsSliderComponent, { injector: this.injector });
      const PaymentMethodsListElement = createCustomElement(PaymentMethodsListComponent, { injector: this.injector });
      const PaymentMethodsListHomeElement = createCustomElement(PaymentMethodsListHomeComponent, { injector: this.injector });

      // Register the custom element with the browser.
      customElements.define(ACCORDION_SELECTOR, AccordionElement);
      customElements.define(ACCORDION_ITEM_SELECTOR, AccordionItemElement);
      customElements.define(LEGACY_GATEWAY_SELECTOR, LegacyGatewayElement);
      customElements.define(HEADER_SELECTOR, HeaderElement);
      customElements.define(MENU_SELECTOR, MenuElement);
      customElements.define(BOTTOM_NAV_SELECTOR, BottomNavElement);
      customElements.define(FOOTER_SELECTOR, FooterElement);
      customElements.define(GAME_NAV_SELECTOR, GameNavElement);
      customElements.define(LOGIN_SELECTOR, LoginElement);
      customElements.define(LOYALTY_PROGRESS_BAR_SELECTOR, LoyaltyProgressBarElement);
      customElements.define(STATUS_BAR_SELECTOR, StatusBarElement);
      customElements.define(GAME_PAGE_SELECTOR, GamesPageElement);
      customElements.define(REWARD_CARD_SELECTOR, RewardCardElement);
      customElements.define(REWARD_DECK_SELECTOR, RewardDeckElement);
      customElements.define(INFO_CARDS_SELECTOR, InfoCardsElement);
      customElements.define(MAIN_BANNER_SELECTOR, MainBannerElement);
      customElements.define(TILE_SLIDER_SELECTOR, TileSliderElement);
      customElements.define(GAME_RECENT_PLAYED_SELECTOR, GameRecentlyPlayedElement);
      customElements.define(CALENDAR_SELECTOR, CalendarElement);
      customElements.define(PROMOTION_NOTIFIER_SELECTOR, PromotionNotifierElement);
      customElements.define(SCROLL_TOP_SELECTOR, ScrollTopElement);
      customElements.define(PROMOTIONS_TODAY_SELECTOR, PromotionsTodayElement);
      customElements.define(HIGHLIGHTS_SELECTOR, HighLightsElement);
      customElements.define(INFO_MODAL_SELECTOR, InfoModalElement);
      customElements.define(EVENTS_AT_LOUNGE_SELECTOR, EventsAtLoungeElement);
      customElements.define(LIVE_AT_LOUNGE_SELECTOR, LiveAtLoungeElement);
      customElements.define(GAMIFICATION_WIDGET_SELECTOR, GamificationWidgetElement);
      customElements.define(TOURNAMENTS_SLIDER_SELECTOR, TournamentsSliderElement);
      customElements.define(LATEST_WINS_SELECTOR, LatestWinsElement);
      customElements.define(PAYMENT_METHODS_LIST_SELECTOR, PaymentMethodsListElement);
      customElements.define(PAYMENT_METHODS_LIST_HOME_SELECTOR, PaymentMethodsListHomeElement);
    }
    this.favouriteService.mergeFavouriteGames();
  }
}
