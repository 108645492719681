import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, fromEvent, merge, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DeviceType, Jurisdiction, PaymentMethods, VisitorState } from './visitor.model';
import { CoreService } from '../core.service';
import { paymentMethods } from './visitor.config';

@Injectable({
  providedIn: 'root',
})
export class VisitorStore extends ComponentStore<VisitorState> {
  constructor(@Inject(DOCUMENT) private document: Document, private coreService: CoreService) {
    super({
      deviceType: undefined,
      jurisdiction: Jurisdiction.UKGC,
      currencyCode: 'EUR',
      countryCode: 'UK',
      loggedIn: false,
    });
  }

  readonly jurisdiction$ = this.select(state => state.jurisdiction);

  readonly currencyCode$ = this.select(state => state.currencyCode);

  readonly currencySymbol$ = this.select(state => state.currencySymbol);

  readonly countryCode$ = this.select(state => state.countryCode);

  readonly deviceType$ = this.select(state => state.deviceType);

  readonly paymentMethods$ = this.select(state => state.paymentMethods);

  readonly loggedIn$ = this.select(state => state.loggedIn);

  readonly updateJurisdiction = this.updater((state, jurisdiction: VisitorState['jurisdiction']) => ({
    ...state,
    jurisdiction,
  }));

  readonly updateCurrencyCode = this.updater((state, currencyCode: VisitorState['currencyCode']) => ({
    ...state,
    currencyCode,
  }));

  readonly updateCurrencySymbol = this.updater((state, currencySymbol: VisitorState['currencySymbol']) => ({
    ...state,
    currencySymbol,
  }));

  readonly updateCountryCode = this.updater((state, countryCode: VisitorState['countryCode']) => ({
    ...state,
    countryCode,
  }));

  readonly updateLoggedIn = this.updater((state, loggedIn: VisitorState['loggedIn']) => ({
    ...state,
    loggedIn,
  }));

  readonly updateDeviceType = this.updater((state, deviceType: DeviceType) => ({ ...state, deviceType }));

  readonly updatePaymentMethods = this.updater((state, newPaymentMethods: Map<string, PaymentMethods>) => ({
    ...state,
    paymentMethods: newPaymentMethods,
  }));

  readonly getDeviceType = this.effect(() => {
    const breakpoint = window.getComputedStyle(this.document.body).getPropertyValue('--breakpoint-md');
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoint})`);
    return merge(of(mediaQuery), fromEvent<MediaQueryListEvent>(mediaQuery, 'change')).pipe(
      map(query => {
        return query.matches ? DeviceType.Mobile : DeviceType.Desktop;
      }),
      tap<DeviceType>(deviceType => this.updateDeviceType(deviceType))
    );
  });

  readonly filterAllowedPaymentMethods = this.effect(() => {
    return combineLatest([this.countryCode$, this.jurisdiction$]).pipe(
      switchMap(([countryCode, jurisdiction]) => {
        const allowedMethods = this.coreService.filterArrayByLocation(paymentMethods, jurisdiction, countryCode);
        const methodsNameAndIconMap: Map<string, PaymentMethods> = new Map<string, PaymentMethods>();
        allowedMethods.forEach((paymentMethod: PaymentMethods) => {
          methodsNameAndIconMap.set(paymentMethod.name, {name: paymentMethod.name, icon: paymentMethod.icon});
        });
        const methodsNameAndIcon = allowedMethods.map(({ name, icon }) => ({
          name,
          icon,
        }));

        return of(methodsNameAndIconMap);
      }),
      tap<Map<string, PaymentMethods>>(allowedMethods =>  {
        this.updatePaymentMethods(allowedMethods)
      })
    );
  });

  isUserLoggedIn(): boolean {
    let isLoggedIn = false;
    this.select(state => state.loggedIn).pipe(take(1)).subscribe((y) => isLoggedIn = y); return isLoggedIn;
    return isLoggedIn;
  }
}
