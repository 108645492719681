import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { PromotionsTodayService } from './promotions-today.service';
import { BaseComponent } from '../base.component';
import { Promotion, PromotionLegend } from './promotions-today.model';

export const PROMOTIONS_TODAY_SELECTOR = 'whg-promotions-today';

@Component({
  selector: PROMOTIONS_TODAY_SELECTOR,
  templateUrl: './promotions-today.component.html',
  styleUrls: ['./promotions-today.component.scss'],
})
export class PromotionsTodayComponent extends BaseComponent implements OnInit {
  promotions: Promotion[] = [];
  legends: PromotionLegend[] = [];

  isLoading: boolean = true;

  constructor(private promotionsTodayService: PromotionsTodayService, private spinner: NgxSpinnerService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
     this.spinner.show('promo-spinner');
    },1500);

    this.promotionsTodayService.promotionsToday$.pipe(takeUntil(this.destroy$)).subscribe(promotions => {
      this.promotions = promotions;
      const legends = this.promotions.map(promo => ({ text: promo.promoType, color: promo.color }));
      this.legends = legends.filter((legend, index, array) => array.findIndex(leg => (leg.text === legend.text)) === index); // Remove duplicate legends 

      this.spinner.hide();
      this.isLoading = false;
    });
  }
}
