import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { MarketingprefsSchema } from '../user/user.model';
import { CoreService } from '../core.service';
import { PromotionApiUrl } from './promotion-notifier.model';

@Injectable()
export class PromotionNotifierService {
  constructor(private httpClient: HttpClient, private coreService: CoreService) {
  }

  updateMarketingPreferences(marketingPreferences: MarketingprefsSchema): Observable<any> {
    const updateMarketingPreferencesUrl = `${this.coreService.platformBaseUrl}${PromotionApiUrl.updateMarketingPreferences}`;
    const body = {
      sessionid: this.coreService.getSessionIdFromCookie(),
      marketingPrefs: marketingPreferences
    };
    return this.httpClient.post(updateMarketingPreferencesUrl, body);
  }
}
