export enum GamesTilesContentType {
  CATEGORY = 'category',
  ALL = 'all',
  PROVIDER = 'provider',
  PROVIDER_LIST = 'provider-list',
}
export interface GameTilesConfig {
  imageSrc: string;
  contentType: GamesTilesContentType;
  category?: string;
  provider?: string;
}
