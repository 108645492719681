<div class="todays-promotions">
  <h1>{{ 'Promotion Guide' | vegastranslate }}</h1>
  <p>{{ 'A guide to your daily dose of decadence!' | vegastranslate }}</p>

  <ng-container *ngIf="!isLoading; else spinner">
    <ul class="promotions-list">
      <li *ngFor="let legend of legends">
        <span class="promo-legend" [ngStyle]="{ background: legend.color }"></span>
        <span class="text-capitalize">{{ legend.text | vegastranslate }}</span>
      </li>
    </ul>
    <ng-container *ngFor="let promo of promotions">
      <whg-promotions-today-card [promo]="promo"></whg-promotions-today-card>
    </ng-container>
  </ng-container>

  <ng-template #spinner>
    <div class="spinner-container">
      <ngx-spinner
        name="promo-spinner"
        bdColor="rgba(0,0,0,0)"
        [fullScreen]="false"
        size="large"
        type="ball-scale-pulse"
      ></ngx-spinner>
    </div>
  </ng-template>
</div>
