import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SeoJson } from './seo-json.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  currentLanguage: string = this.cookieService.get('language');
  defaultLanguage = 'en';
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  getHTMLJSON(fileName: string) {
    if (!!this.currentLanguage) {
      return this.http.get<SeoJson>(`/assets/seoJSON/${this.currentLanguage}/${fileName}`).pipe(map(res => res.html));
    } else {
      return this.http.get<SeoJson>(`/assets/seoJSON/${this.defaultLanguage}/${fileName}`).pipe(map(res => res.html));
    }
  }
}
