import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { WithProperties } from '@angular/elements';

import { Subject } from 'rxjs';
import { takeUntil, tap, distinctUntilChanged, map, skipWhile, filter } from 'rxjs/operators';

import { RewardCardComponent } from '../reward-card/reward-card.component';
import { UserStore } from '../core-services/user/user.store';

type CardIntersectionEntry = IntersectionObserverEntry & {
  target: WithProperties<typeof RewardCardComponent>['prototype'];
};

export const REWARD_DECK_SELECTOR = 'whg-reward-deck';

@Component({
  selector: REWARD_DECK_SELECTOR,
  templateUrl: './reward-deck.component.html',
  styleUrls: ['./reward-deck.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class RewardDeckComponent implements OnInit, OnDestroy {
  private assignedElements: Set<Element> = new Set();
  private observer!: IntersectionObserver;
  private activeCard: (Element & RewardCardComponent) | undefined;
  private scrollTimeout!: NodeJS.Timeout;

  private cards: RewardCardComponent[] = [];

  private destroy$ = new Subject<void>();

  @HostListener('scroll', ['$event'])
  onDeckScroll() {
    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      if (this.activeCard) {
        this.setActiveCard();
      }
    }, 50);
  }

  constructor(private elRef: ElementRef, private readonly userStore: UserStore) {}

  ngOnInit() {
    this.setCards();
    this.setIntersectionObserver();
    this.setActiveCard();
    this.setLevel();
  }

  ngOnDestroy() {
    clearTimeout(this.scrollTimeout);
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setCards(): void {
    this.cards = this.elRef.nativeElement.shadowRoot.querySelector('slot').assignedElements();
  }

  private setIntersectionObserver(): void {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this) as any, {
      root: this.elRef.nativeElement,
      rootMargin: '0px',
      threshold: [0, 0.9],
    });
  }

  private setActiveCard(): void {
    this.cards.forEach(card => (card.active = false));

    if (this.activeCard) {
      this.activeCard.active = true;
    } else if (this.cards.length > 0) {
      this.cards[0].active = true;
    }
  }

  private setLevel() {
    this.userStore.userBalance$
      .pipe(
        filter(balance => !!balance && !!balance.experience),
        distinctUntilChanged((acc, curr) => !!acc && !!curr && acc.experience.level === curr.experience.level),
        map(balance => (balance ? balance.experience.level : 0)),
        skipWhile(level => level <= 0),
        tap(level => {
          this.cards.forEach(card => (card.currentLevel = false));
          this.cards[--level].currentLevel = true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  slotChange($event: Event): void {
    if (!($event.target instanceof HTMLSlotElement)) {
      return;
    }

    const rewardCards = $event.target.assignedElements();

    // Add new cards to the observer
    rewardCards.forEach(card => {
      if (this.assignedElements.has(card)) {
        return;
      }
      this.assignedElements.add(card);
      this.observer.observe(card);
    });

    // Remove old cards
    Array.from(this.assignedElements)
      .filter(element => rewardCards.indexOf(element) < 0)
      .forEach(removedCard => this.observer.unobserve(removedCard));
  }

  private handleIntersection(cards: CardIntersectionEntry[]): void {
    cards.forEach(card => {
      if (card.isIntersecting && card.intersectionRatio >= 0.9 && !card.target.active) {
        this.activeCard = card.target;
      }
    });
  }
}
