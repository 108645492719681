import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { first, switchMap, takeUntil, filter, pairwise } from 'rxjs/operators';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';

import { gameNavConfig } from './game-nav.config';
import { BaseComponent } from '../base.component';
import { LinksConfig } from './game-nav.models';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { CoreService } from '../core-services/core.service';
import { Game } from '../core-services/games/games.model';
import { GamesStore } from '../core-services/games/games.store';
import { SearchStore } from '../core-services/search/search.store';
import { SearchVisualState } from '../core-services/search/search.model';
import { Router, NavigationEnd, ActivationEnd, RoutesRecognized } from '@angular/router';

export const GAME_NAV_SELECTOR = 'whg-game-nav';
const ITEM_WIDTH = 200;

@Component({
  selector: GAME_NAV_SELECTOR + '-internal',
  templateUrl: './game-nav.component.html',
  styleUrls: ['./game-nav.component.scss'],
})
export class GameNavComponent extends BaseComponent implements OnInit, AfterViewInit {
  @HostBinding('class.search-view-open') searchIsDisplayed = false;
  @Input() category = '';
  favouriteSVG = './assets/icons/favourites.svg';
  recentlyPlayedSVG = '/assets/icons/recently-played.svg';
  searchSVG = './assets/icons/search.svg';
  categories$!: Observable<LinksConfig[]>;
  /* Support null on the model as when HTML Form is being reset, the value would become null */
  searchInput: string | null = null;
  @ViewChild('searchInputField') searchInputField?: ElementRef;
  @ViewChild('slider') slider?: ElementRef;
  public sliderScrollLeft = 0;
  public sliderScrollRight = 1;
  public previousUrl = '/games/';
  private allGames$: Observable<Game[]> = this.gamesStore.allGames$;
  public loggedIn$: Observable<boolean> = this.visitorStore.loggedIn$;
  private filteredResult?: Game[];
  leftArrow = './assets/icons/arrow-left.svg';
  rightArrow = './assets/icons/arrow-right.svg';

  constructor(
    public coreService: CoreService,
    private visitorStore: VisitorStore,
    private gamesStore: GamesStore,
    private searchStore: SearchStore,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.setNavCategories();
    this.getPage();

    // TODO: Remove when routing is in place
    if (location.pathname === '/games/search') {
      this.searchStore.setVisualState(SearchVisualState.RECENT_SEARCHES);
    }

    this.searchStore.visualState$.subscribe(visualState => {
      this.searchIsDisplayed = visualState !== SearchVisualState.HIDDEN;
    });

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  ngAfterViewInit(): void {
    this.subscribeSlider();
  }

  toggleSearch(): void {
    if (location.pathname.startsWith('/games')) {
      this.router.navigateByUrl('/games/search');
    } else {
      location.href = '/games/search';
    }

    setTimeout(() => {
      if (this.searchInputField) {
        this.searchInputField.nativeElement.focus();
      }
    }, 0);
  }

  getPage() {
    const path = location.pathname.split('/');
    this.category = path[path.length - 1];
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        const url = event.urlAfterRedirects.split('/');
        this.category = url[url.length - 1];

        if (event.urlAfterRedirects === '/games/search') {
          this.searchStore.setVisualState(SearchVisualState.RECENT_SEARCHES);
        } else {
          this.searchStore.setVisualState(SearchVisualState.HIDDEN);
        }
      });
  }

  setNavCategories(): void {
    this.categories$ = combineLatest([this.visitorStore.countryCode$, this.visitorStore.jurisdiction$]).pipe(
      takeUntil(this.destroy$),
      switchMap(([countryCode, jurisdiction]) => {
        return of(this.coreService.filterArrayByLocation(gameNavConfig.categories, jurisdiction, countryCode));
      })
    );
  }

  filterGames() {
    const searchInput = this.searchInput;
    if (searchInput && (searchInput.length > 2 || /^[0-9].*$/.test(searchInput))) {
      this.allGames$.pipe(takeUntil(this.destroy$), first()).subscribe(games => {
        this.filteredResult = games.filter(game => {
          return (
            game.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            game.provider.toLowerCase().includes(searchInput.toLowerCase())
          );
        });
        this.searchStore.addFilteredGames(this.filteredResult?.length > 0 ? this.filteredResult : null);
        this.searchStore.addRecentSearches(searchInput);
      });
    } else {
      this.searchStore.addFilteredGames([]);
    }
  }

  isActive(category: LinksConfig) {
    return (category.text === 'All' && this.category === 'games') || category.url.split('/')[2] === this.category;
  }

  public scrollLeft(): void {
    this.slider?.nativeElement.scrollTo({
      left: this.slider?.nativeElement.scrollLeft - ITEM_WIDTH,
      behavior: 'smooth',
    });
  }

  public scrollRight(): void {
    this.slider?.nativeElement.scrollTo({
      left: this.slider.nativeElement.scrollLeft + ITEM_WIDTH,
      behavior: 'smooth',
    });
  }

  private subscribeSlider(): void {
    if (this.slider?.nativeElement) {
      fromEvent(this.slider?.nativeElement, 'scroll', { passive: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.getSliderScrollPosition());
    }
  }

  private getSliderScrollPosition(): void {
    this.sliderScrollLeft = this.slider?.nativeElement.scrollLeft;
    this.sliderScrollRight =
      this.slider?.nativeElement.scrollWidth -
      this.slider?.nativeElement.scrollLeft -
      this.slider?.nativeElement.getBoundingClientRect().width;
  }
  removeSearchInput() {
    this.searchInput = '';
    this.searchStore.setRecentSearch(this.searchInput);
    this.filterGames();
  }
}
