export const GameApiUrl = {
  getGameList: '/ajax/gamesJSON.php',
};
export interface GameCategory {
  amount?: number;
  category?: string; // should be enum
  country?: string; // should be enum
  id?: number;
  name?: string;
  type?: string; // should be enum
  seq?: number;
}

export interface Game {
  categories: GameCategory[];
  sub_category: string;
  date_added: string;
  description: string; // this actually gives HTML (some times)
  desktop: number;
  fun_supported: number;
  game_seq: number;
  gamelimit: number;
  hot: number;
  id: number;
  image: string;
  jackpot: number;
  launchcode: string;
  max: number;
  min: number;
  mobile: number;
  name: string;
  new: number;
  provider: string;
  rtp: number;
  seo_name: string;
  type: string;
  typeid: number;
  volatility?: string;
  value?: number;
}

interface SingleJackpotsConfig {
  name: string;
  value: number;
  isMobile: boolean;
  isRestricted: boolean;
}

interface AscendingDescendingGames {
  popular: Game[];
  ascending: Game[];
  descending: Game[];
}

export interface GameTypeGames {
  all: AscendingDescendingGames;
  new: AscendingDescendingGames;
  live: AscendingDescendingGames;
  slots: AscendingDescendingGames;
  jackpots: AscendingDescendingGames;
  blackjack: AscendingDescendingGames;
  roulette: AscendingDescendingGames;
  classic: AscendingDescendingGames;
  fun: AscendingDescendingGames;
  mobileall: AscendingDescendingGames;
  mobilenew: AscendingDescendingGames;
  mobilelive: AscendingDescendingGames;
  mobileslots: AscendingDescendingGames;
  mobilejackpots: AscendingDescendingGames;
  mobileblackjack: AscendingDescendingGames;
  mobileroulette: AscendingDescendingGames;
  mobileclassic: AscendingDescendingGames;
  mobilefun: AscendingDescendingGames;
  megaways: AscendingDescendingGames;
}

export interface GamesJackpotsConfig {
  _fetchTime: string;
  total: number;
  jackpots: {
    [x: string]: SingleJackpotsConfig;
  };
}

export interface GameWithScore {
  game: Game;
  totalScore: number;
}
export interface GamesState {
  allGames: Game[];
  allProviders: GameProvider[];
  categories: Category[];
  exclusionList?: Game[];
  filteredGames?: Game[];
  searchedGames?: string;
  recentGames?: string;
  recommendedForYouGames?: Game[];
  becauseYouPlayedGames?: Game[];
  gameTypeGames?: Game[];
  providerGames?: Game[];
  gameDetails?: Game[];
  groupedGameTypeGames?: GameTypeGames;
  becauseYouPlayedFromCurrentGame?: Game[];
  providerGamesFromCurrentGame?: Game[];
  gamesByProvider?: Game[];
  popularGames?: Game[];
  trendingSlots: TrendingSlot[];
}

export interface GameProvider {
  title: string;
  amount: string;
  id: number;
  image?: {
    src: string;
    alt: string;
  };
}

export interface Category {
  id: number;
  name: string;
  category: string;
  amount: number;
  type: 'mobile' | 'desktop';
  country: string;
}
export interface TrendingSlot {
  gameName: string;
  launchCode: string;
  fileName: string;
}
