<ng-container *ngIf="isOpen">
  <ul class="whg-widget">
    <li *ngFor="let item of widgetConfig">
      <button
        [inlineSVG]="item.icon"
        [ngClass]="{ 'whg-widget--active': activeItemType === item.type }"
        (click)="activeItemType !== item.type ? setActiveType(item.type) : null"
      ></button>
    </li>
  </ul>
  <div *ngIf="activeItemType && !isLoading" class="whg-widget__content">
    <whg-challenges-widget 
      *ngIf="activeItemType === 'challenges'"
      [config]="challenges"
      [isLoggedIn]="isLoggedIn"
      [userId]="userId"
      [challengeId]="challengeId"
      (close)="closeWidget()"
    >
    </whg-challenges-widget>

    <whg-tournaments-widget
      *ngIf="activeItemType === 'tournaments'"
      [config]="tournaments"
      [isLoggedIn]="isLoggedIn"
      [userId]="userId"
      [tournamentId]="tournamentId"
      (openModal)="openModal($event)"
      (close)="closeWidget()"
    >
    </whg-tournaments-widget>
  </div>
  <div class="background" (click)="closeWidget()"></div>
</ng-container>
