import { Component, OnInit } from '@angular/core';
import { headerConfig } from './header.config';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';
import { HeaderItem, HeaderItemType, HeaderTypes } from './header.models';
import { filter, takeUntil } from 'rxjs/operators';
import { PageStore } from '../core-services/page/page.store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from '../menu/menu.component';
import { combineLatest } from 'rxjs';
import { UserStore } from '../core-services/user/user.store';
import { UserBalances } from '../core-services/user/user.model';

declare var xtremepush: any;
export const HEADER_SELECTOR = 'whg-header';

@Component({
  selector: HEADER_SELECTOR,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  headerItemTypes = HeaderItemType;
  config = headerConfig;
  page?: string;
  menu?: HeaderItem[];
  loggedIn$ = this.visitorStore.loggedIn$;
  hasNewMessages = false;
  menuIcon = 'assets/icons/menu.svg';
  modalRef?: NgbModalRef;
  currencyCode?: string;
  balance?: UserBalances;
  isLoggedIn$ = this.visitorStore.loggedIn$;
  isShowHeader = true;

  constructor(
    private visitorStore: VisitorStore,
    private pageStore: PageStore,
    private ngbModal: NgbModal,
    private userStore: UserStore
  ) {
    super();
  }

  ngOnInit() {
    this.getNewMessages();
    this.getBalance();
    this.getCurrency();
    this.getHeaderStates();
    this.getPage();
    this.showHeader();
  }
  showHeader(): void {
    const url = window.location.href;
    if (url.includes('create-account')) {
      this.isShowHeader = false;
    }
  }

  getNewMessages(): void {
    // TODO: Create a xtremepush service that we can call
    if (typeof xtremepush !== 'undefined') {
      xtremepush('inbox', 'badge', {}, (result: { [badge: string]: number }) => {
        if (result.badge >= 1) {
          this.hasNewMessages = true;
        }
      });
    }
  }

  openMessages() {
    this.hasNewMessages = false;
  }

  openMenu() {
    if (this.modalRef === undefined) {
      this.menuIcon = '/assets/icons/close.svg';
      this.modalRef = this.ngbModal.open(MenuComponent, {
        windowClass: 'whg-menu__modal-window',
        container: '.sticky-top',
        backdropClass: 'whg-menu__modal-backdrop',
      });
      this.modalRef.result.then(
        resolve => {
          this.menuIcon = '/assets/icons/menu.svg';
          this.modalRef = undefined;
        },
        resolve => {
          this.menuIcon = '/assets/icons/menu.svg';
          this.modalRef = undefined;
        }
      );
    } else {
      this.modalRef.close();
    }
  }

  getHeaderStates() {
    combineLatest([this.visitorStore.loggedIn$, this.pageStore.isRestricted$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([loggedIn, restricted]) => {
        if (restricted) {
          this.menu = headerConfig.menu[HeaderTypes.Restricted];
        } else if (loggedIn) {
          this.menu = headerConfig.menu[HeaderTypes.LoggedIn];
        } else {
          this.menu = headerConfig.menu[HeaderTypes.LoggedOut];
        }
      });
  }

  getPage() {
    this.pageStore.page$.pipe(takeUntil(this.destroy$)).subscribe(page => {
      this.page = page;
    });
  }

  getBalance() {
    this.userStore.userBalance$
      .pipe(
        takeUntil(this.destroy$),
        filter(x => !!x)
      )
      .subscribe(res => {
        if (res) {
          this.balance = res.balances;
        }
      });
  }

  getCurrency() {
    this.visitorStore.currencySymbol$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.currencyCode = res;
    });
  }
}
