<a *ngIf="redirectLink; else noLink" [href]="redirectLink">
  <ng-container *ngTemplateOutlet="ref"></ng-container>
</a>
<ng-template #noLink>
  <ng-container *ngTemplateOutlet="ref"></ng-container>
</ng-template>
<div class="whg-empty-tile__info d-flex align-items-center">
  <div class="d-inline-flex flex-column">
    <span></span>
    <span></span>
  </div>
  <span class="p-0 mx-1" [inlineSVG]="favoriteSvgPath"> </span>
</div>
<ng-template #ref>
  <div class="whg-empty-tile__image d-flex align-items-center justify-content-center mb-1 w-100 position-relative">
    <span class="position-absolute text-center" [inlineSVG]="iconPath"></span>
  </div>
</ng-template>
