<nav class="modal-body">
  <ng-template *ngTemplateOutlet="MenuRef; context: { $implicit: config }"></ng-template>
</nav>

<button
  class="btn whg-menu__close-button"
  type="button"
  (click)="closeModal()"
  [inlineSVG]="'/assets/icons/close.svg'"
></button>

<ng-template #MenuRef let-config>
  <ul class="whg-menu__menu">
    <li
      *ngFor="let menuItem of config"
      class="nav-item"
      [ngClass]="{ 'nav-item__new-message': hasNewMessages && menuItem.name === 'Messages' }"
    >
      <a
        *ngIf="menuItem.type === menuItemTypes.Anchor"
        class="whg-menu__menu-item"
        [ngClass]="[menuItem.styles]"
        [href]="menuItem.link"
      >
        {{ menuItem.name | vegastranslate }}
      </a>
      <button
        *ngIf="menuItem.type === menuItemTypes.Button"
        class="whg-menu__menu-item"
        [ngClass]="[menuItem.styles]"
        type="button"
      >
        {{ menuItem.name | vegastranslate }}
      </button>
      <button
        *ngIf="menuItem.type === menuItemTypes.ButtonClick"
        class="whg-menu__menu-item"
        [ngClass]="[menuItem.styles]"
        (click)="onClick(menuItem)"
        type="button"
      >
        {{ menuItem.name | vegastranslate }}
      </button>
      <button
        *ngIf="menuItem.type === menuItemTypes.Disabled"
        class="whg-menu__menu-item"
        [ngClass]="[menuItem.styles]"
        type="button"
        disabled
      >
        {{ menuItem.name | vegastranslate }}
      </button>

      <ng-container *ngIf="menuItem.subMenu">
        <ng-template *ngTemplateOutlet="MenuRef; context: { $implicit: menuItem.subMenu }"></ng-template>
      </ng-container>
    </li>
  </ul>
</ng-template>
