import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest } from 'rxjs';

import { WidgetConfig, WidgetItem, WidgetTypeEnum } from '../gamification-widget/gamification-widget.model';
import { TournamentsService } from '../core-services/tournaments/tournaments.service';
import { LeaderBoardRow, Reward } from '../core-services/tournaments/tournaments.model';
import { InfoModal } from '../info-modal/info-modal.model';
import { Ics } from '../core-services/ics/ics.model';

const TOURNAMENTS_WIDGET_SELECTOR = 'whg-tournaments-widget';

@Component({
  selector: TOURNAMENTS_WIDGET_SELECTOR,
  templateUrl: './tournaments-widget.component.html',
  styleUrls: ['./tournaments-widget.component.scss'],
})
export class TournamentsWidgetComponent implements OnInit {
  @Input() config!: WidgetConfig;
  @Input() isLoggedIn = false;
  @Input() userId: number | undefined;
  @Input() tournamentId!: string;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() openModal: EventEmitter<InfoModal> = new EventEmitter<InfoModal>();
  public widgetTypeEnumList: WidgetTypeEnum = WidgetTypeEnum.list;
  public widgetTypeEnumItem: WidgetTypeEnum = WidgetTypeEnum.item;
  public widgetTypeEnumTable: WidgetTypeEnum = WidgetTypeEnum.table;
  public tournaments!: WidgetItem[];
  public activeTournament!: WidgetItem | null;
  public leaderboard: LeaderBoardRow[] = [];
  public rewards: Reward[] = [];

  constructor(private tournamentsService: TournamentsService) { }

  ngOnInit() {
    this.initWidget();
  }

  public initWidget(): void {
    this.setInitialItems();
    if (this.tournamentId) {
      const tournament = this.tournaments.find(t => t.id === this.tournamentId);
      if (tournament) {
        this.setTournamentItem(tournament);
      }
    }
  }

  public setTournamentItem(tournament: WidgetItem): void {
    this.activeTournament = tournament;
    this.config.type = WidgetTypeEnum.item;
  }

  /**
   * @description Since competition Labs don't have a Tournament Leaderboard, but only individual contest leaderboard as aggreed all the
   * tournaments will only have one contest and that contest will provide the "tournament leaderboard"
   * @param tournament
   */
  public setTournamentLeaderBoard(tournament: WidgetItem): void {
    this.activeTournament = tournament;
    this.config.type = WidgetTypeEnum.table;

    this.tournamentsService
      .getCompetition(tournament.id).subscribe(({ data: { contests } }) => {
        if (contests.length > 0) {
          const contestId: string = contests[0].id;

          combineLatest([
            this.tournamentsService.getTournamentLeaderboard(contestId),
            this.tournamentsService.getTournamentDetail(contestId),
          ]).subscribe(([leaderboard, contestDetail]) => {
            this.leaderboard = leaderboard.data;
            this.rewards = contestDetail.data.rewards;
          });
        }
      });
  }

  public showList(): void {
    this.config.type = WidgetTypeEnum.list;
    this.rewards = [];
    this.leaderboard = [];
    this.activeTournament = null;
  }

  public takePart(tournament: WidgetItem): void {
    this.optIn(tournament);
  }

  public closeWidget(): void {
    this.close.emit();
  }

  private setInitialItems(): void {
    this.tournaments = this.config.items;
  }

  private optIn(tournament: WidgetItem): void {
    const tournamentId = tournament.id;
    if (this.userId && tournamentId) {
      this.tournamentsService.optIn(this.userId, tournamentId).subscribe(() => {
        this.tournamentId = tournamentId;
        tournament.optinRequired = false;
        this.initWidget()
      });
    }
  }
}
