import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, takeUntil } from 'rxjs/operators';

import { HighlightsStore } from '../core-services/highlights/highlights.store';
import { BaseComponent } from '../base.component';
import { Language } from '../core-services/highlights/highlights.model';
import { GamificationWidgetService } from '../gamification-widget/gamification-widget.service';
import { WidgetItemTypeEnum } from '../gamification-widget/gamification-widget.model';

export const EVENTS_AT_LOUNGE_SELECTOR = 'whg-events-at-lounge';

@Component({
  selector: EVENTS_AT_LOUNGE_SELECTOR,
  templateUrl: './events-at-lounge.component.html',
  styleUrls: ['./events-at-lounge.component.scss'],
})
export class EventsAtLoungeComponent extends BaseComponent implements OnInit {
  CAROUSEL_INTERVAL = 5000;
  loungeEvents?: Language[];
  @Input() path!: string;
  @ViewChild('carousel', { static: true }) carousel?: NgbCarousel;
  modalRef?: NgbModalRef;

  constructor(
    private highlightsStore: HighlightsStore,
    private readonly gamificationWidgetService: GamificationWidgetService
  ) {
    super();
  }

  ngOnInit() {
    this.highlightsStore.getHighlights(this.path);
    this.getEvents();
  }

  getEvents() {
    this.highlightsStore.highlights$
      .pipe(
        map(res => {
          if (res) {
            return this.highlightsStore.getHighLightsInLanguage(res);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.loungeEvents = res as Language[];
      });
  }

  swipeLeft(): void {
    this.carousel?.prev();
  }

  swipeRight(): void {
    this.carousel?.next();
  }

  public openWidget(): void {
    this.gamificationWidgetService.openWidget(WidgetItemTypeEnum.tournaments);
  }
}
