import { TileInterface } from '../tile/tile.model';
import { baseUrl } from '../game-nav/game-nav.config';

export const tiles: TileInterface[] = [
  {
    image: {
      src: '/assets/lounge-rooms/%s/VLCasinoFloor.jpg',
      alt: 'VL Casino Floor',
    },
    title: 'VL Casino Floor',
    subTitle: 'Indulge in 2000+ games',
    link: `${baseUrl}`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/SlotsHall.jpg',
      alt: 'Slots Hall',
    },
    title: 'Slots Hall',
    subTitle: 'Thousands of premium slots',
    link: `${baseUrl}/slots`,
    category: 'slots',
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/LiveCasinoParlour.jpg',
      alt: 'Live Casino Parlour',
    },
    title: 'Live Casino Parlour',
    subTitle: 'Real dealers, at real tables, in real time',
    link: `${baseUrl}/live-casino`,
    category: 'live',
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/RecommendationRoom.jpg',
      alt: 'Recommendation Room',
    },
    title: 'Recommendation Room',
    subTitle: 'These games are popular right now',
    link: `${baseUrl}/recommended`,
    category: 'top',
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/NewGamesCorner.jpg',
      alt: 'New Games Corner',
    },
    title: 'New Games Corner',
    subTitle: 'Explore our latest additions',
    link: `${baseUrl}/new-games`,
    category: 'new',
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/TablesRoom.jpg',
      alt: 'Tables Room',
    },
    title: 'Tables Room',
    subTitle: 'World-class card and table games',
    link: `${baseUrl}/table-games`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/HouseOfJackpots.jpg',
      alt: 'House Of Jackpots',
    },
    title: 'House Of Jackpots',
    subTitle: 'Hit that big progressive jackpot win',
    link: `${baseUrl}/jackpots`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/DailyJackpotLounge.jpg',
      alt: 'Daily Jackpots Lounge',
    },
    title: 'Daily Jackpots Lounge',
    subTitle: 'Your shot at a daily jackpot',
    link: `${baseUrl}/daily-jackpots`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/ScratchCardSaloon.jpg',
      alt: 'ScratchCard Saloon',
    },
    title: 'ScratchCard Saloon',
    subTitle: 'Lottery Scratchcards with a modern twist',
    link: `${baseUrl}/scratch-cards`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/FreePlayParlour.jpg',
      alt: 'Free Play Parlour',
    },
    title: 'Free Play Parlour',
    subTitle: 'Try our games for free',
    link: `${baseUrl}/free-play`,
  },
  {
    image: {
      src: '/assets/lounge-rooms/%s/ThePowerhouse.jpg',
      alt: 'The Powerhouse',
    },
    title: 'The Powerhouse',
    subTitle: 'Exceptional Software Providers to enhance your gameplay',
    link: `${baseUrl}/providers`,
  },
];
