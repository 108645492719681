import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { Game } from '../core-services/games/games.model';
import { CoreService } from '../core-services/core.service';
import { TileInterface } from '../tile/tile.model';
import { SearchStore } from '../core-services/search/search.store';
import { PlayService } from '../core-services/play/play.service';
import { GameModeEnum } from '../core-services/play/play.model';
import { SearchVisualState } from '../core-services/search/search.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { TileComponent } from '../tile/tile.component';

export const GAME_SEARCH_RESULT_SELECTOR = 'whg-game-search-result';

@Component({
  selector: GAME_SEARCH_RESULT_SELECTOR,
  templateUrl: './game-search-result.component.html',
  styleUrls: ['./game-search-result.component.scss'],
})
export class GameSearchResultComponent extends BaseComponent implements OnInit {
  public gamesNotFound = false;
  games? = [];
  isLoggedIn?: boolean;
  isLoading = true;
  tiles: TileInterface[] = [];
  recentSearches$ = this.searchStore.recentSearches$;
  showRecentSearch = this.searchStore.visualState$.pipe(map((visualState) => visualState === SearchVisualState.RECENT_SEARCHES));
  @ViewChildren('tiles') tilesList!: QueryList<TileComponent>;

  constructor(
    private coreService: CoreService,
    public searchStore: SearchStore,
    private playService: PlayService,
    private visitorStore: VisitorStore
  ) {
    super();
  }

  ngOnInit() {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.searchStore.filteredGames$.pipe(takeUntil(this.destroy$)).subscribe((games) => {
      this.gamesNotFound = !!!games;
      if (games && games.length > 0) {
        this.searchStore.setVisualState(SearchVisualState.GAMES);
      } else {
        this.searchStore.setVisualState(SearchVisualState.RECENT_SEARCHES);
      }

      if (games) {
        this.setGameTiles(games);
      }
    });
  }
  resize() {
    this.tilesList.forEach((tile) => tile.truncateText());
  }

  setGameTiles(games: Game[]): void {
    if (!games) {
      return;
    }
    this.tiles = games.map((game) => {
      const { id, launchcode, name, provider, hot, new: n, image, sub_category } = game;
      const extension = sub_category === 'animated' ? 'svg' : 'jpg';
      return {
        id,
        image: {
          src: `${this.coreService.scontentUrl}images/portraitdt/${image}.${extension}`,
          alt: name,
        },
        title: name,
        subTitle: provider,
        game,
        hot,
        new: n,
        link: this.playService.getGameUrl(launchcode, true, GameModeEnum.Real),
      };
    });
    this.isLoading = false;
  }

  removeRecentSearch() {
    this.searchStore.clearRecentSearches();
  }

  onClickRecentSearch(recentSearch: string) {
    this.searchStore.setRecentSearch(recentSearch);
  }
}
