<img
  class="whg-game-tiles__image d-block mx-auto game-block"
  width="240"
  height="120"
  [src]="gameTilesConfig.imageSrc"
  [alt]="gameTilesConfig.category"
/>
<div class="whg-game-tiles__wrapper" resizeDir (resizeEvt)="resize()">
  <ng-container *ngIf="!isLoading; else empty">
    <div *ngFor="let tile of tiles">
      <whg-tile #tiles class="position-relative" [tile]="tile" [isLoggedIn]="isLoggedIn"></whg-tile>
    </div>
  </ng-container>
  <ng-template #empty>
    <ng-container *ngFor="let tile of emptyTilesArray">
      <whg-empty-tile></whg-empty-tile>
    </ng-container>
  </ng-template>
  <div #bottom></div>
</div>
<div id="lounge-rooms" class="my-5">
  <div *ngFor="let html of jsonHTMLSEO$ | async" [innerHTML]="html"></div>
</div>
