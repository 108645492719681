<ng-container *ngIf="!isLoading">
  <div class="whg-tile-slider__header">
    <div>
      <span class="whg-tile-slider__title">
        <span class="whg-tile-slider__title--live" *ngIf="tileSlider.category === 'live'">•</span>
        {{ tileSlider.title | vegastranslate }}
      </span>
      <span class="whg-tile-slider__sub-title text-muted">{{ tileSlider.subTitle | vegastranslate }}</span>
    </div>
    <div>
      <span class="whg-tile-slider__actions">
        <ng-container *ngIf="isMobile; else desktop">
          <a class="whg-tile-slider__see-all" [href]="tileSlider.link">
            {{ 'See All' | translate }} <span [inlineSVG]="arrowRight"></span
          ></a>
        </ng-container>
        <ng-template #desktop>
          <button
            [ngClass]="{ 'whg-tile-slider__actions--disable': sliderScrollLeft === 0 }"
            [inlineSVG]="arrowLeft"
            (click)="scrollLeft()"
          ></button>
          <button
            [ngClass]="{ 'whg-tile-slider__actions--disable': sliderScrollRight === 0 }"
            [inlineSVG]="arrowRight"
            (click)="scrollRight()"
          ></button>
          <a class="whg-tile-slider__anchor" [href]="tileSlider.link"
            >{{ 'See All' | translate }} {{ tileSlider.category | vegastranslate }} {{ 'Games' | vegastranslate }}</a
          >
        </ng-template>
      </span>
    </div>
  </div>
  <div #slider class="whg-tile-slider__tiles" resizeDir (resizeEvt)="resize()">
    <ng-container *ngIf="tileSlider.tiles && tileSlider.tiles.length > 0; else empty">
      <ng-container *ngFor="let tile of tileSlider.tiles">
        <whg-tile #tiles class="position-relative" [tile]="tile" [isLoggedIn]="isLoggedIn"> </whg-tile>
      </ng-container>
    </ng-container>
    <ng-template #empty>
      <ng-container *ngFor="let tile of emptyTilesArray">
        <whg-empty-tile
          [iconPath]="isFav ? '/assets/icons/plus.svg' : '/assets/icons/recently-played.svg'"
        ></whg-empty-tile>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
