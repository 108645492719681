import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';

import { takeUntil } from 'rxjs/operators';

import { PlayService } from '../core-services/play/play.service';
import { GameModeEnum } from '../core-services/play/play.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';

import { CASINO_VIDEOS, TRENDING_VIDEOS } from './videos.const';

export const LIVE_AT_LOUNGE_SELECTOR = 'whg-live-at-lounge';

@Component({
  selector: LIVE_AT_LOUNGE_SELECTOR,
  templateUrl: './live-at-lounge.component.html',
  styleUrls: ['./live-at-lounge.component.scss'],
})
export class LiveAtLoungeComponent extends BaseComponent implements OnInit {

  liveCasinoSrc = '';
  liveCasinoVideos =  LiveAtLoungeComponent.createRandomArray(CASINO_VIDEOS,3);
  trendingSlotSrc = '';
  trendingSlotVideos = LiveAtLoungeComponent.createRandomArray(TRENDING_VIDEOS, 3);

  link = '';
  isLoggedIn = false;

  constructor(private playService: PlayService, private visitorStore: VisitorStore) {
    super();
  }

  ngOnInit() {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.nextLiveCasinoVideo();
      this.nextTrendingSlotVideo();
    });
  }

  nextLiveCasinoVideo() {
    this.liveCasinoSrc = this.pickRandomVideo<string>(this.liveCasinoVideos);
  }

  nextTrendingSlotVideo() {
    const selectedTrendingSlot = this.pickRandomVideo<{ url: string, launchCode: string }>(this.trendingSlotVideos);
    this.trendingSlotSrc = selectedTrendingSlot.url;
    this.link = this.playService.getGameUrl(selectedTrendingSlot.launchCode, this.isLoggedIn, GameModeEnum.Real);
  }

  private pickRandomVideo<T>(videos: T[]) {
    const randomVideoIndex = Math.floor(Math.random() * videos.length);
    return videos[randomVideoIndex] as T;
  }

  private static createRandomArray(originalArray: any[], newArrayLength: number): any[] {
    return originalArray.sort((a, b) => 0.5 - Math.random()).slice(originalArray.length - newArrayLength);
  }
}
