import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PaymentMethods } from '../core-services/visitor/visitor.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { PaymentMethodsGeotargettingOrder } from './payment-methods-geotargetting-order';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const PAYMENT_METHODS_LIST_HOME_SELECTOR = 'whg-payment-methods-list-home';

@Component({
  selector: PAYMENT_METHODS_LIST_HOME_SELECTOR,
  templateUrl: './payment-methods-list-home.component.html',
  styleUrls: ['./payment-methods-list-home.component.scss'],
})
export class PaymentMethodsListHomeComponent extends BaseComponent implements OnInit {
  private static assetsPath = '/assets/payment-providers/';
  public paymentMethodListHome: PaymentMethods[] = [];
  constructor(private visitorStore: VisitorStore) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.visitorStore.countryCode$, this.visitorStore.paymentMethods$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([countryCode, paymentMethods]) => {
        if (countryCode && paymentMethods) {
          const geotargettingOrder = PaymentMethodsGeotargettingOrder[countryCode.toLowerCase()];
          if (geotargettingOrder) {
            geotargettingOrder.forEach((paymentOption) => {
              const paymentMethod = paymentMethods.get(paymentOption);
              if (paymentMethod) {
                this.paymentMethodListHome.push(paymentMethod);
              }
            });
          }
        }
      });
  }

  public getIconURL(icon: string): string {
    return PaymentMethodsListHomeComponent.assetsPath + icon;
  }
}
