import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from '../core.service';
import { PlatformRequestTypes } from '../platform/platform.model';
import { Observable } from 'rxjs/internal/Observable';
import { Game, GameApiUrl, GameProvider } from '../games/games.model';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { BiggestPayer, BigWin, LatestWinsResponse } from './latest-wins.model';

@Injectable()
export class LatestWinsService {

  private static DEFAULT_TIME_WINDOW_IN_HOURS = 48;
  private static DEV_TIME_WINDOW_IN_HOURS = 720; // 1 month
  private static DEV_COUNTRY = 'GB' // bypass logged out users during test from everywhere

  constructor(private httpClient: HttpClient, private coreService: CoreService) {
  }

  public getLatestWinsGameList(numberOfEntries: number, brandId: number, gameProviderList: number[]): Observable<BigWin[]> {
    return this.getGameList().pipe(map(gameList => {
      return this.createGameListMapByLaunchCode(gameList);
    })).pipe(switchMap(gameMapById => forkJoin({
      latestWins: this.getLatestWins(numberOfEntries, brandId, gameProviderList),
      gameMapById: of(gameMapById)
    }).pipe(map(result => {
      return this.sortGames(result.latestWins, result.gameMapById);
    }))));
  }

  private getLatestWins(numberOfEntries: number, brandId: number, gameProviderList: number[]): Observable<BiggestPayer[]> {
    const latestWinsApiUrl = `${this.coreService.platformBaseUrl}${PlatformRequestTypes.GET_LATEST_WINS}`;
    const offset = new Date().getTimezoneOffset();
    const currentDateWithTimeZone = new Date().getTime() - (offset * 60 * 1000);
    const toDate = new Date(currentDateWithTimeZone);
    const fromDate = new Date(currentDateWithTimeZone);
    const body = {
      type: PlatformRequestTypes.GET_LATEST_WINS,
      fromDate: new Date(fromDate.setHours(toDate.getHours() - this.getTimeWindow())).toISOString().split('T')[0],
      providerIds: gameProviderList,
      toDate: toDate.toISOString().split('T')[0],
      brandId: brandId,
      numberOfEntries: numberOfEntries * 3,
      ...!this.coreService.getSessionIdFromCookie() && this.isDevEnv() ? {country: LatestWinsService.DEV_COUNTRY} : {},
      ...this.coreService.getSessionIdFromCookie() ? {sessionid: this.coreService.getSessionIdFromCookie()} : {}
    };
    return this.httpClient.post<LatestWinsResponse>(latestWinsApiUrl, body).pipe(map(result => {
      return result.biggestPayers;
    }));
  }

  private getGameList(): Observable<Game[]> {
    const gameListUrl = `${this.coreService.legacyUrl}${GameApiUrl.getGameList}`;
    return this.httpClient.get<Game[]>(gameListUrl);
  }

  private createGameListMapByLaunchCode(gameList: Game[]): Map<string, Game> {
    const gameMapById = new Map<string, Game>();
    gameList.forEach((game: Game) => {
      gameMapById.set(game.launchcode, game);
    });
    return gameMapById;
  }

  private sortGames(biggestPayers: BiggestPayer[], gameMapById: Map<string, Game>): BigWin[] {
    const latestWins: BigWin[] = [];
    biggestPayers?.forEach((biggestPayer: BiggestPayer) => {
      const gameFound = gameMapById.get(biggestPayer.gameLaunchCode);
      if (gameFound) {
        latestWins.push(new BigWin(gameFound, biggestPayer));
      }
    });
    return latestWins;
  }

  private getTimeWindow(): number {
    return this.isDevEnv() ?
      LatestWinsService.DEV_TIME_WINDOW_IN_HOURS : LatestWinsService.DEFAULT_TIME_WINDOW_IN_HOURS;
  }

  private isDevEnv(): boolean {
    return (this.coreService.isDev || this.coreService.isStg)
  }
}
