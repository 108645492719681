import { MenuConfig, MenuItem, MenuItemType, MenuTypes } from './menu.models';
const SupportSubMenu: MenuItem[] = [
  {
    name: 'Live chat now',
    type: MenuItemType.Button,
    styles: '',
  },
  {
    name: 'Email your concierge',
    link: 'mailto:support@vegasloungecasino.com',
    type: MenuItemType.Anchor,
    styles: '',
  },
];

export const menuConfig: MenuConfig = {
  [MenuTypes.LoggedOut]: [
    {
      name: 'Home',
      link: '/',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'My Account',
      link: '/account',
      type: MenuItemType.Anchor,
      styles: '',
      subMenu: [
        {
          name: 'Register',
          link: '/create-account',
          type: MenuItemType.Anchor,
          styles: '',
        },
        {
          name: 'Login',
          link: '/login',
          type: MenuItemType.Anchor,
          styles: '',
        },
        {
          name: 'Logout',
          type: MenuItemType.Disabled,
          styles: '',
        },
      ],
    },
    {
      name: 'Games',
      link: '/games',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'Promotions',
      link: '/promotions',
      type: MenuItemType.Anchor,
      styles: 'promotions-nav-item',
    },
    {
      name: 'Loyalty',
      link: '/loyalty',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'VIP',
      link: '/vip',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'Support',
      link: '/support',
      type: MenuItemType.Anchor,
      subMenu: SupportSubMenu,
      styles: '',
    },
  ],
  [MenuTypes.LoggedIn]: [
    {
      name: 'Lobby',
      link: '/',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'My Account',
      link: '/account',
      type: MenuItemType.Anchor,
      styles: '',
      subMenu: [
        {
          name: 'Deposit',
          link: '/deposit',
          type: MenuItemType.Anchor,
          styles: 'cashier-link',
        },
        {
          name: 'Logout',
          link: '/logout',
          type: MenuItemType.Anchor,
          styles: '',
        },
      ],
    },
    {
      name: 'Games',
      link: '/games',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'Promotions',
      link: '/promotions',
      type: MenuItemType.Anchor,
      styles: 'promotions-nav-item',
    },
    {
      name: 'Messages',
      type: MenuItemType.ButtonClick,
      styles: '',
      class: 'messages',
    },
    {
      name: 'Loyalty',
      link: '/loyalty',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'VIP',
      link: '/vip',
      type: MenuItemType.Anchor,
      styles: '',
    },
    {
      name: 'Support',
      link: '/support',
      type: MenuItemType.Anchor,
      subMenu: SupportSubMenu,
      styles: '',
    },
  ],
  [MenuTypes.Restricted]: [
    {
      name: 'Lobby',
      type: MenuItemType.Disabled,
      styles: '',
    },
    {
      name: 'My Account',
      type: MenuItemType.Disabled,
      styles: '',
      subMenu: [
        {
          name: 'Register',
          type: MenuItemType.Disabled,
          styles: '',
        },
        {
          name: 'Logout',
          link: '/logout',
          type: MenuItemType.Anchor,
          styles: '',
        },
      ],
    },
    {
      name: 'Games',
      type: MenuItemType.Disabled,
      styles: '',
    },
    {
      name: 'Promotions',
      type: MenuItemType.Disabled,
      styles: 'promotions-nav-item',
    },
    {
      name: 'Messages',
      type: MenuItemType.Button,
      styles: '',
    },
    {
      name: 'Loyalty',
      type: MenuItemType.Disabled,
      styles: '',
    },
    {
      name: 'VIP',
      type: MenuItemType.Disabled,
      styles: '',
    },
    {
      name: 'Support',
      type: MenuItemType.Disabled,
      styles: '',
      subMenu: [
        {
          name: 'Live chat now',
          type: MenuItemType.Button,
          styles: '',
        },
        {
          name: 'Email your concierge',
          type: MenuItemType.Disabled,
          styles: '',
        },
      ],
    },
  ],
};
