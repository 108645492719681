<div class="whg-challenges-widget">
  <div class="whg-challenges-widget__header">
    <button
      [ngClass]="{ 'whg-challenges-widget__active-item-arrow--hidden': config && config.type !== widgetTypeEnumItem }"
      class="whg-challenges-widget__active-item-arrow"
      [inlineSVG]="'/assets/icons/arrow-left.svg'"
      (click)="showList()"></button>
    <h2 *ngIf="config.type === widgetTypeEnumList">{{ "Today's Challenges" | vegastranslate }}</h2>
    <h2 *ngIf="config.type === widgetTypeEnumItem">{{ 'Challenge Info' | vegastranslate }}</h2>

    <span [inlineSVG]="'/assets/icons/close.svg'" (click)="closeWidget()"></span>
  </div>
  <div class="whg-challenges-widget__content-wrapper">
    <ng-container *ngIf="config.type === widgetTypeEnumList">
      <ng-container *ngFor="let challenge of challenges">
        <div class="whg-challenges-widget__content-item">
          <div class="whg-challenges-widget__content-item-image" [ngStyle]="{ 'background-image': 'url(' + challenge.image + ')' }"></div>
          <div class="whg-challenges-widget__content-item-info">
            <h4>{{ challenge.title }}</h4>
            <div [innerHTML]="challenge.shortDescription"></div>
            <span>{{ challenge.remainingTime }} {{ 'remaining' | vegastranslate }}</span>
          </div>
          <button class="btn btn-primary btn-sm whg-challenges-widget__content-item-action-2" (click)="setChallengeItem(challenge)">
            {{ 'More Info' | vegastranslate }}
          </button>
        </div>
        <div *ngIf="challenge.sigTerms" class="whg-challenges-widget__terms">
          <span [innerHTML]="challenge.sigTerms"></span>
          <span>&nbsp;</span>
          <a class="full-terms" (click)="setChallengeItem(challenge)">{{ 'Full Terms Apply' | vegastranslate }}</a>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="whg-challenges-widget__active-item" *ngIf="config.type === widgetTypeEnumItem">
    <div class="whg-challenges-widget__active-item-image" [ngStyle]="{ 'background-image': 'url(' + activeChallenge?.image + ')' }"></div>
    <div class="whg-challenges-widget__active-item-info">
      <h2>{{ activeChallenge?.title }}</h2>
      <span>{{ activeChallenge?.remainingTime }} {{ 'remaining' | vegastranslate }}</span>
      <div class="whg-challenges-widget__active-item-progress">
        <div [ngStyle]="{ width: 0 }"></div>
      </div>
      <div class="whg-challenges-widget__dependency-tree" *ngIf="dependencyTree.length > 1">
        <div>
          <span class="whg-challenges-widget__dependency-tree-item" *ngFor="let item of dependencyTree; let i = index">
            <span
              *ngIf="i !== 0"
              class="whg-challenges-widget__dependency-tree-separator"
              [ngStyle]="{ width: handleDependencyTreeWidth() }"></span>
          </span>
        </div>
      </div>
      <ng-scrollbar class="whg-challenges-widget__active-item-description">
        <div class="description" [innerHTML]="activeChallenge?.description"></div>
      </ng-scrollbar>
    </div>
  </div>
</div>
