import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserStore } from '../core-services/user/user.store';
import { BaseComponent } from '../base.component';
import { takeUntil, filter, finalize, withLatestFrom, take } from 'rxjs/operators';
import { CoreService } from '../core-services/core.service';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { Jurisdiction } from '../core-services/visitor/visitor.model';
import { LoginResponse } from '../core-services/user/user.model';

declare global {
  interface Window {
    popup: (
      width: number,
      title: string,
      message: string,
      showActionBox: 'true' | 'false',
      closeButton: 'true' | 'false',
      extraModalContainerClass?: string,
      buttonText?: string,
      buttonLink?: string
    ) => void;
  }
}

export const LOGIN_SELECTOR: string = 'whg-login';

const SUCCESS_CODE: number = 200;
const SELF_EXCLUSION_CODE: number = 228;
const POPUP_WIDTH: number = 300;

@Component({
  selector: LOGIN_SELECTOR,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent {
  public showPassword: boolean = false;
  public errorMessage: string = '';
  public disableLogin: boolean = false;

  private userJurisdiction!: Jurisdiction;
  private triggerPopup: boolean = false;

  constructor(
    private visitorStore: VisitorStore,
    private userStore: UserStore,
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute
  ) {
    super();

    this.visitorStore.jurisdiction$
      .pipe(take(1))
      .subscribe((jurisdiction: string) => (this.userJurisdiction = jurisdiction as Jurisdiction));
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public submitLogin($event: Event): void {
    $event.preventDefault();

    const formData: FormData = new FormData($event.target as HTMLFormElement);

    this.disableLogin = true;

    this.userStore
      .login(formData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.disableLogin = false)),
        withLatestFrom(this.activatedRoute.queryParamMap)
      )
      // eslint-disable-next-line complexity
      .subscribe(([response, params]: [LoginResponse, ParamMap]) => {
        if (response.statusCode === SUCCESS_CODE || response.status === 'success') {
          let sender: string | null = params.get('sender');
          const legacySender: string | null = new URL(`${document.location}`).searchParams.get('sender');

          if (!sender && legacySender) {
            sender = `${legacySender}`;
          }

          if (sender) {
            window.location.href = sender;
          } else {
            window.location.href = '/?loggedin';
          }

          return;
        }

        const statusObject: {
          code: number | string;
          significantLockType: string;
        } = JSON.parse(response.statusCode);

        if (+statusObject.code === SELF_EXCLUSION_CODE) {
          if (this.userJurisdiction === Jurisdiction.UKGC) {
            statusObject.code = '228_UK';
          }

          if (this.userJurisdiction === Jurisdiction.MGA) {
            statusObject.code = '228_MGA';
          }

          this.triggerPopup = true;
        }

        this.coreService
          .getErrorCodeTranslations(`ERROR_${statusObject.code}`)
          .pipe(
            takeUntil(this.destroy$),
            filter<string | undefined>((x: string | undefined) => typeof x === 'string')
          )
          .subscribe((value: string | undefined) => {
            if (this.triggerPopup) {
              this.triggerPopupError(value as string);

              return;
            }

            this.errorMessage = value as string;
          });
      });
  }

  private triggerPopupError(message: string): void {
    window.popup(POPUP_WIDTH, this.coreService.getLegacyTranslation('general_error') as string, `<p>${message}</p>`, 'true', 'false');
  }
}
