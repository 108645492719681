import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Game } from '../core-services/games/games.model';
import { GamesStore } from '../core-services/games/games.store';
import { Observable } from 'rxjs';
import { filter, first, pairwise, takeUntil } from 'rxjs/operators';
import { SearchStore } from '../core-services/search/search.store';
import { Router, RoutesRecognized } from '@angular/router';

export const SEARCH_SELECTOR = 'whg-search';

@Component({
  selector: SEARCH_SELECTOR,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  /* Support null on the model as when HTML Form is being reset, the value would become null */
  searchInput: string | null = null;
  searchSVG = './assets/icons/search.svg';
  @ViewChild('searchInputField') searchInputField?: ElementRef;
  public previousUrl = '/games/';
  private filteredResult?: Game[];
  private allGames$: Observable<Game[]> = this.gamesStore.allGames$;

  constructor(
    private gamesStore: GamesStore,
    private router: Router,
    private searchStore: SearchStore) {
    super();
  }
  ngOnInit() {
    this.getRecentSearch();
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  filterGames() {
    const searchInput = this.searchInput;
    if (searchInput && (searchInput.length > 2 || /^[0-9].*$/.test(searchInput))) {
      this.allGames$.pipe(takeUntil(this.destroy$), first()).subscribe(games => {
        this.filteredResult = games.filter(game => {
          return (
            game.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            game.provider.toLowerCase().includes(searchInput.toLowerCase())
          );
        });
        this.searchStore.addFilteredGames(this.filteredResult);
        this.searchStore.addRecentSearches(searchInput);
      });
    } else {
      this.searchStore.addFilteredGames([]);
    }
  }

  onFocusSearch() {
    if (location.pathname !== '/games/search') {
      this.router.navigate(['/games/search']);
    }
  }

  onClickSearch() {
    if (location.pathname !== '/games/search') {
      location.href = '/games/search';
    }

    if (this.searchInputField) {
      this.searchInputField.nativeElement.focus();
    }
  }
  getRecentSearch() {
    this.searchStore.recentSearch$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.searchInput = res;
      this.filterGames();
    });
  }

  ngAfterViewInit(): void {
    if (this.searchInputField && location.pathname === '/games/search') {
      setTimeout(() => window.scroll(0, 0), 100);
      this.searchInputField.nativeElement.focus();
    }
  }
}
