import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { VegasI18nPipe } from './pipes/i18n.pipe';

export function AEHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',

      loader: {
        provide: TranslateLoader,
        useFactory: AEHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [VegasI18nPipe],
  exports: [VegasI18nPipe],
})
export class SharedTranslateModule {}
