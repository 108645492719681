import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'img[imageFallback]',
})
export class ImageFallbackDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError(): void {
    const element: HTMLImageElement = this.elementRef.nativeElement as HTMLImageElement;
    element.src = '/assets/general/VL-Overlay.png';
  }
}
