import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { interval, Observable, of, timer } from 'rxjs';
import { exhaustMap, map, retry, switchMap, take, tap } from 'rxjs/operators';

import { CoreService } from '../core.service';
import { GameJackpots, GameJackpotState } from './game-jackpots.model';

@Injectable({
  providedIn: 'root',
})
export class GameJackpotStore extends ComponentStore<GameJackpotState> {
  private readonly fetchGamesJackpots = this.coreService.jackpotsUrl;

  constructor(private http: HttpClient, private coreService: CoreService) {
    super({ jackpots: undefined, fakeJackpots: undefined });
  }

  readonly gameJackpots$: Observable<GameJackpots | undefined> = this.select(state => state.jackpots);
  readonly fakeGameJackpots$: Observable<GameJackpots | undefined> = this.select(state => state.fakeJackpots);
  readonly gameJackpotsTotals$: Observable<number | undefined> = this.select(state => {
    // totalJackpot.toLocaleString as any is needed due to typescript version that we are currently using
    return (
      state.totalJackpots &&
      (state.totalJackpots.toLocaleString as any)(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  });

  readonly addGameJackpots = this.updater((state, jackpots: GameJackpots) => ({
    ...state,
    jackpots: jackpots,
  }));
  readonly addTotalJackpot = this.updater((state, totalJackpots: number) => ({
    ...state,
    totalJackpots: totalJackpots,
  }));
  readonly updateFakeGameJackpots = this.updater((state, fakeJackpots: GameJackpots) => ({
    ...state,
    fakeJackpots: fakeJackpots
  }));

  public getJackpotTotal = this.effect((currency$: Observable<string>) => {
    return currency$.pipe(
      switchMap(currency =>
        this.getJackpots(currency).pipe(
          map(jackpots => jackpots.total),
          switchMap(total => {
            // tslint:disable-next-line:max-line-length
            // Should be 5000-10000, should be value higher than the amount of seconds we expect a jackpot total increases on the backend;
            const totalFrames = 7891,
              amountToAnimate = total * this.getJackpotsPercentageToAnimate(total),
              step = amountToAnimate / totalFrames;

            let currentAmount = total - amountToAnimate;

            return interval(1000).pipe(
              exhaustMap(() => {
                currentAmount += step;
                return of(currentAmount);
              }),
              take(totalFrames)
            );
          }),
          tap((gameJackpotTotals: number) => this.addTotalJackpot(gameJackpotTotals))
        )
      )
    );
  });

  public getFakeJackpotsTotals = this.effect((currency$: Observable<string>) => {
    return currency$.pipe(
      switchMap(currency =>
        this.getFakeJackpots(currency).pipe(
          switchMap(gameJackpotsRes => {
            return interval(1000).pipe(
              switchMap(() => {
                Object.keys(gameJackpotsRes.fakeJackpots).forEach((key: string) => {
                  const fakeJackpotClone = { ...gameJackpotsRes.fakeJackpots[key] };
                  const value = fakeJackpotClone.value;
                  const currentAmount = value + Math.random() * (2 - 0.02) + 0.02;
                  fakeJackpotClone.value = currentAmount;
                  gameJackpotsRes.fakeJackpots[key] = fakeJackpotClone;
                })
                return of({ ...gameJackpotsRes })
              }),
              tap(gameJackpotsRes => this.updateFakeGameJackpots(gameJackpotsRes))
            )
          })
        )));
  });

  private getJackpots(currency: string): Observable<GameJackpots> {
    return timer(0, this.coreService.randomInterval(60000, 120000)).pipe(
      switchMap(() => this.http.get<GameJackpots>(this.fetchGamesJackpots + currency + '.json')),
      retry(3),
      tap(gameJackpots => this.addGameJackpots(gameJackpots))
    );
  }

  private getFakeJackpots(currency: string): Observable<GameJackpots> {
    return timer(0, this.coreService.randomInterval(60000, 120000)).pipe(
      switchMap(() => this.http.get<GameJackpots>(this.fetchGamesJackpots + currency + '.json')),
      retry(3),
      map(gameJackpots => ({ ...gameJackpots, fakeJackpots: gameJackpots.jackpots })),
      tap(gameJackpots => this.updateFakeGameJackpots(gameJackpots))
    );
  }

  private getJackpotsPercentageToAnimate(total: number) {
    if (total < 10000) {
      // Thousand
      return 0.1;
    } else if (total < 100000) {
      // Hundred thousand
      return 0.005;
    } else if (total < 1000000) {
      // Million
      return 0.0025;
    } else {
      // More then a million
      return 0.001;
    }
  }
}
