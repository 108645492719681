<div class="whg-live-lounge">
  <h2>{{ 'LIVE at The Lounge' | vegastranslate }}</h2>
  <whg-tabs>
    <whg-tab [title]="'Trending Slot' | vegastranslate">
      <a [href]="link">
        <video
          #trendingVideo
          (canplay)="trendingVideo.play()"
          preload="none"
          (loadedmetadata)="trendingVideo.muted = true"
          (ended)="nextTrendingSlotVideo(); trendingVideo.load()"
          muted
          playsinline>
          <source [src]="trendingSlotSrc" type="video/mp4" />
        </video>
      </a>
    </whg-tab>
    <whg-tab [title]="'Live Casino' | vegastranslate">
      <a href="/games/live-casino">
        <video
          #video
          (canplay)="video.play()"
          preload="none"
          (loadedmetadata)="video.muted = true"
          (ended)="nextLiveCasinoVideo(); video.load()"
          muted
          playsinline>
          <source [src]="liveCasinoSrc" type="video/mp4" />
        </video>
      </a>
    </whg-tab>
  </whg-tabs>
</div>
