import { Component, Input, OnInit } from '@angular/core';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

import { Card } from '../card/card.model';
import { GameJackpotStore } from '../core-services/game-jackpots/game-jackpots.store';
import { BaseComponent } from '../base.component';
import { VisitorStore } from '../core-services/visitor/visitor.store';

export const INFO_CARDS_SELECTOR = 'whg-info-cards';

@Component({
  selector: INFO_CARDS_SELECTOR,
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.scss'],
})
export class InfoCardsComponent extends BaseComponent implements OnInit {
  @Input() set config(config: string) {
    this.infoCardsConfig = JSON.parse(config);
  }

  public infoCardsConfig!: Card[];
  public isLoading = true;
  public currencyCode!: string;
  private jackpotTotals$: Observable<number | undefined>;

  constructor(private gameJackpotsStore: GameJackpotStore, private visitorStore: VisitorStore) {
    super();
    this.jackpotTotals$ = this.gameJackpotsStore.gameJackpotsTotals$;
  }

  ngOnInit() {
    this.infoCardsConfig.some(card => !!card.jackpot) ? this.setJackpot() : (this.isLoading = false);
  }

  setJackpot(): void {
    forkJoin([this.getCurrencyCodeRequest(), this.getCurrencySymbolRequest()]).subscribe(
      ([currencyCode, currencySymbol]) => {
        this.currencyCode = currencyCode;
        this.gameJackpotsStore.getJackpotTotal(currencyCode);

        const jackpot$ = this.jackpotTotals$.pipe(
          takeUntil(this.destroy$),
          map(totalJackpot => (totalJackpot ? `${currencySymbol}${totalJackpot}` : 'Loading...'))
        );

        this.infoCardsConfig = this.infoCardsConfig.map(card => {
          return !card.jackpot ? card : Object.assign(card, { jackpot$ });
        });

        this.isLoading = false;
      }
    );
  }

  private getCurrencyCodeRequest(): Observable<string> {
    return this.visitorStore.currencyCode$.pipe(first());
  }

  private getCurrencySymbolRequest(): Observable<string | undefined> {
    return this.visitorStore.currencySymbol$.pipe(first());
  }
}
