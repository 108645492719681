import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, timer } from 'rxjs';
import { switchMap, tap, distinctUntilChanged, takeUntil, take } from 'rxjs/operators';

import { Balance, LoginResponse, UserDetails, UserExperience, UserState } from './user.model';
import { CoreService } from '../core.service';
import { PlatformService } from '../platform/platform.service';
import { PlatformRequestTypes } from '../platform/platform.model';
import { VisitorStore } from '../visitor/visitor.store';

@Injectable({
  providedIn: 'root',
})
export class UserStore extends ComponentStore<UserState> {
  private readonly platformBaseURL = this.coreService.platformBaseUrl;

  constructor(
    private http: HttpClient,
    private coreService: CoreService,
    private platformService: PlatformService,
    private visitorStore: VisitorStore ) {
    super({});
  }

  readonly userDetails$: Observable<UserDetails | undefined> = this.select(state => state.userDetails);
  readonly userBalance$: Observable<Balance | undefined> = this.select(state => state.userBalance);

  readonly addUserDetails = this.updater((state, userDetails: UserDetails) => ({
    ...state,
    userDetails: { ...userDetails },
  }));

  readonly addUserBalance = this.updater((state, userBalance: Balance) => ({
    ...state,
    userBalance: { ...userBalance },
  }));

  readonly getUserDetails = this.effect(() => {
    const isLoggedIn = this.visitorStore.isUserLoggedIn();
    return isLoggedIn ? this.platformService
      .execute(PlatformRequestTypes.GET_USER_DETAILS, null)
      .pipe(tap((userDetails: UserDetails) => this.addUserDetails(userDetails))) : of(null);

  });

  readonly startBalancePolling = this.effect((sessionId$: Observable<string>) => {
    return sessionId$.pipe(
      switchMap(id =>
        timer(0, 5000) // a bit concerned about performance here as the header is available on every page
          .pipe(
            switchMap(() =>
              this.http.post<Balance>(`${this.platformBaseURL}getbalance`, {
                sessionid: id,
                touchSession: false,
              })
            )
          )
          .pipe(
            tap((balance: Balance) => {
              if (balance?.code === 254) {
                location.reload();
              }
              this.addUserBalance(balance);
            })
          )
      )
    );
  });

  readonly experience$: Observable<UserExperience | undefined> = this.select(
    this.userBalance$,
    balance => balance && balance.experience
  ).pipe(distinctUntilChanged((curr, acc) => !!curr && !!acc && curr.points === acc.points));

  readonly level$: Observable<number | undefined> = this.select(
    this.experience$,
    experience => experience && experience.level
  ).pipe(distinctUntilChanged((curr, acc) => !!curr && !!acc && curr === acc));

  readonly points$: Observable<number | undefined> = this.select(
    this.experience$,
    experience => experience && experience.points
  ).pipe(distinctUntilChanged((curr, acc) => !!curr && !!acc && curr === acc));

  public login(formData: FormData): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.coreService.legacyUrl + `/ajax/authenticate_user.php`, formData)
      .pipe(tap(x => this.addUserDetails(x.userDetails)));
  }


}
