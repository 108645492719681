import { Component, OnInit } from '@angular/core';
import { UserStore } from '../core-services/user/user.store';
import { PromotionChannelType, PromotionMarketing } from '../core-services/promotion-notifier/promotion-notifier.model';
import { MarketingprefsSchema } from '../core-services/user/user.model';
import { PromotionNotifierService } from '../core-services/promotion-notifier/promotion-notifier.service';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../base.component';

export const PROMOTION_NOTIFIER_SELECTOR = 'whg-promotion-notifier';

@Component({
  selector: PROMOTION_NOTIFIER_SELECTOR,
  templateUrl: 'promotion-notifier.component.html',
  styleUrls: ['promotion-notifier.component.scss'],
  providers: [PromotionNotifierService]
})
export class PromotionNotifierComponent  extends BaseComponent  implements OnInit {
  public promoMarketing: PromotionMarketing = new PromotionMarketing();
  public channelType = PromotionChannelType;
  private marketingPreferences!: MarketingprefsSchema;
  showEmail: boolean = true;
  showSms: boolean = true;

  constructor(private userStore: UserStore, private promotionNotifierService: PromotionNotifierService) {
    super();
  }

  ngOnInit() {
    this.getMarketingPrefs();
  }

  getMarketingPrefs(): void {
    this.userStore.userDetails$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res && res.marketingPrefs && res.marketingPrefs.brandMarketing) {
        this.marketingPreferences = res.marketingPrefs;
        this.promoMarketing = PromotionMarketing.fromBrandMarketingSchema(res.marketingPrefs.brandMarketing);
        this.showEmail = res.marketingPrefs.brandMarketing.email;
        this.showSms = res.marketingPrefs.brandMarketing.sms;
      }
    });
  }

  toggleChannel(channel: PromotionChannelType): void {
    this.promoMarketing[channel] = !this.promoMarketing[channel];
    this.updateMarketingPreferences();
  }

  private updateMarketingPreferences(): void {
    this.marketingPreferences!.brandMarketing!.sms = this.promoMarketing.sms;
    this.marketingPreferences!.brandMarketing!.email = this.promoMarketing.email;
    this.promotionNotifierService.updateMarketingPreferences(this.marketingPreferences).subscribe();
  }
}
