export interface RestrictionCriteriasConfig {
  text?: string;
  icon?: string;
  excludedJurisdictions?: string[];
  includedOnlyLanguages?: string[];
  excludedOnlyLanguages?: string[];
}

export enum ImageTypes {
  Portrait = '/images/portraitdt/',
  Landscape = '/images/gamesdt/',
  Mobile = '/images/mobile/icons/new-iconsdt/',
}
