<div class="whg-live-lounge">
  <h1>{{ 'Events at The Lounge' | vegastranslate }}</h1>

  <ngb-carousel
    #carousel
    [interval]="CAROUSEL_INTERVAL"
    [showNavigationArrows]="false"
    [pauseOnHover]="true"
    [pauseOnFocus]="true"
  >
    <ng-template ngbSlide *ngFor="let event of loungeEvents">
      <a class="image-link" (click)="openWidget()">
        <div class="carousel-caption">
          <h1>{{ event.name | vegastranslate }}</h1>
        </div>
        <div (swipeleft)="swipeLeft()" (swiperight)="swipeRight()">
          <img [src]="event.sliderImage" alt="image" />
        </div>
      </a>
    </ng-template>
  </ngb-carousel>
  <div class="sig-terms text-center">
    <p class="text-muted">
      <a href="/playerprotection" target="_blank">18+</a>. <a href="/promoterms">{{ 'Terms Apply' | vegastranslate }}</a>.
      <a href="/playerprotection" target="_blank">{{ 'Play Responsibly' | vegastranslate }}</a>.
      <a href="https://www.gamblingtherapy.org" target="_blank">www.GamblingTherapy.org</a>
    </p>
  </div>
</div>
