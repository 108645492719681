import { Component, Input, OnInit } from '@angular/core';
import { footerConfig } from './footer.config';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { combineLatest, Observable, of } from 'rxjs';
import { CountryLogoConfig, LinksConfig } from './footer.models';
import { CoreService } from '../core-services/core.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

export const FOOTER_SELECTOR = 'whg-footer';

@Component({
  selector: FOOTER_SELECTOR,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  @Input() copyright?: string;
  @Input() licenseText?: string;
  @Input() managedText?: string;
  @Input() responsibleGambling?: string;

  mgaLogo = footerConfig.mgaLogo;
  gamblingCommission = footerConfig.gamblingCommission;
  gamblingWarning = footerConfig.gamblingWarning;
  companyLogo = footerConfig.companyLogo;
  links = footerConfig.links;
  country$!: Observable<CountryLogoConfig[]>;

  constructor(private visitorStore: VisitorStore, private coreService: CoreService) {
    super();
  }

  ngOnInit() {
    this.country$ = combineLatest([this.visitorStore.countryCode$, this.visitorStore.jurisdiction$]).pipe(
      takeUntil(this.destroy$),
      switchMap(([countryCode, jurisdiction]) => {
        return of(this.coreService.filterArrayByLocation(footerConfig.countryLogos, jurisdiction, countryCode));
      })
    );
    this.subscribeUser();
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      this.links = isLoggedIn
        ? //  if LoggedIn, removes login & create accounts links
          footerConfig.links.filter(link => !link.url.includes('login') && !link.url.includes('create-account'))
        : footerConfig.links;
    });
  }

  public isVisible(link: LinksConfig): boolean {
    const currentUrl = window.location.href;
    let linkIsVisible = true;
    if (link?.hideOnUrls) {
      link.hideOnUrls.forEach((url: string) => {
        if (currentUrl.includes(url)) {
          linkIsVisible = false;
        }
      });
    }
    return linkIsVisible;
  }
}
