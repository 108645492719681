import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntil, filter, mergeMap, map } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { Game } from '../core-services/games/games.model';
import { TileInterface } from '../tile/tile.model';
import { CoreService } from '../core-services/core.service';
import { PlayService } from '../core-services/play/play.service';
import { GameModeEnum } from '../core-services/play/play.model';
import { RecentPlayedStore } from '../core-services/recent-played/recent-played.store';
import { combineLatest } from 'rxjs';
import { GamesStore } from '../core-services/games/games.store';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { TileComponent } from '../tile/tile.component';
import { SeoService } from '../core-services/seo-service/seo.service';

export const GAME_RECENT_PLAYED_SELECTOR: string = 'whg-recent-played';

@Component({
  selector: GAME_RECENT_PLAYED_SELECTOR,
  templateUrl: './game-recent-played.component.html',
  styleUrls: ['./game-recent-played.component.scss'],
})
export class GameRecentPlayedComponent extends BaseComponent implements OnInit {
  @ViewChildren('tiles') public tilesList!: QueryList<TileComponent>;
  public games: Game[] = [];
  public tiles: TileInterface[] = [];
  public emptyTilesArray: number[] = Array(6).fill(0);
  public isLoading: boolean = true;
  public isLoggedIn: boolean = false;
  public seoJSONfileName: string = 'favourite_recent.json';
  public jsonHTMLSEO$: any;

  constructor(
    private recentPlayedStore: RecentPlayedStore,
    private coreService: CoreService,
    private playService: PlayService,
    private gameStore: GamesStore,
    private visitorStore: VisitorStore,
    private seoService: SeoService
  ) {
    super();
    this.jsonHTMLSEO$ = this.seoService.getHTMLJSON(this.seoJSONfileName);
  }

  public ngOnInit(): void {
    this.subscribeUser();
    this.recentPlayedStore.launchCodes$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: string[]) => !!x),
        mergeMap((launchCodes: string[]) =>
          combineLatest(launchCodes.map((launchCode: string) => this.gameStore.getGameByLaunchCode(launchCode)))
        ),
        map((games: any) => games.filter((game: Game): game is Game => !!game))
      )
      .subscribe((games: Game[]) => {
        this.games = games;
        this.setGameTiles();
      });
  }

  public resize(): void {
    this.tilesList.forEach((tile: TileComponent) => tile.truncateText());
  }

  public setGameTiles(): void {
    if (this.games.length < 1) {
      return;
    }
    this.tiles = this.games.map((game: Game) => {
      // eslint-disable-next-line @typescript-eslint/typedef
      const { id, launchcode, name, provider, hot, new: n, image } = game;

      return {
        id,
        image: {
          src: `${this.coreService.scontentUrl}images/portraitdt/${image}.jpg`,
          alt: name,
        },
        title: name,
        subTitle: provider,
        game,
        hot,
        new: n,
        link: this.playService.getGameUrl(launchcode, true, GameModeEnum.Real),
      };
    });
    this.isLoading = false;
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
    });
  }
}
