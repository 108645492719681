<div class="bottom-nav">
  <ul>
    <li *ngFor="let item of config" [ngClass]="[item.liStyles || '']">
      <a *ngIf="item.type === bottomNavItemTypes.Anchor" [inlineSVG]="item.icon" [href]="item.url" [ngClass]="[item.styles]"></a>
      <button
        *ngIf="item.type === bottomNavItemTypes.Button"
        [inlineSVG]="item.icon"
        [ngClass]="[item.styles]"
        (click)="selectCashier()"></button>
    </li>
  </ul>
</div>
