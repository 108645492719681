import { Component, OnInit } from '@angular/core';
import { menuConfig } from './menu.config';
import { MenuTypes, MenuItemType, MenuItem } from './menu.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PageStore } from '../core-services/page/page.store';

declare var xtremepush: any;
export const MENU_SELECTOR = 'whg-menu';

@Component({
  selector: MENU_SELECTOR,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends BaseComponent implements OnInit {
  menuItemTypes = MenuItemType;
  config?: MenuItem[];
  hasNewMessages = false;

  constructor(private visitorStore: VisitorStore, private pageStore: PageStore, public ngbModal: NgbModal) {
    super();
  }

  ngOnInit() {
    this.getNewMessages();
    combineLatest([this.visitorStore.loggedIn$, this.pageStore.isRestricted$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([loggedIn, restricted]) => {
        if (restricted) {
          this.config = menuConfig[MenuTypes.Restricted];
        } else if (loggedIn) {
          this.config = menuConfig[MenuTypes.LoggedIn];
        } else {
          this.config = menuConfig[MenuTypes.LoggedOut];
        }
      });
  }

  onClick(menuItem: MenuItem) {
    if (menuItem.name === 'Messages') {
      xtremepush('inbox', 'open');
      this.hasNewMessages = false;
    }
  }

  getNewMessages(): void {
    // TODO: Create a xtremepush service that we can call
    if (xtremepush) {
      xtremepush('inbox', 'badge', {}, (result: { [badge: string]: number }) => {
        if (result.badge >= 1) {
          this.hasNewMessages = true;
        }
      });
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
