<div class="fav-text mb-3">Save the games you’re loving right here.</div>
<div class="whg-game-tiles__wrapper" resizeDir (resizeEvt)="resize()">
  <ng-container *ngIf="!isLoading">
    <ng-container *ngFor="let tile of tiles">
      <whg-tile #tiles class="position-relative" [tile]="tile" [isLoggedIn]="isLoggedIn"></whg-tile>
    </ng-container>
    <whg-empty-tile [redirectLink]="redirectLink" [iconPath]="iconPath"></whg-empty-tile>
  </ng-container>
  <ng-container *ngIf="games.length < 1">
    <ng-container *ngFor="let tile of emptyTilesArray">
      <whg-empty-tile class="empty-tile-list" [redirectLink]="redirectLink" [iconPath]="iconPath"></whg-empty-tile>
    </ng-container>
  </ng-container>
</div>
<div class="my-5">
  <div *ngFor="let html of jsonHTMLSEO$ | async" [innerHTML]="html"></div>
</div>
