import { Injectable } from '@angular/core';

import { InfoModal } from './info-modal.model';

@Injectable({ providedIn: 'root' })
export class InfoModalService {
  infoModalConfig?: InfoModal;

  setConfig(infoModal?: InfoModal) {
    this.infoModalConfig = infoModal;
  }

  get getConfig(): InfoModal {
    return <InfoModal>this.infoModalConfig;
  }
}
