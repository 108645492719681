<div class="whg-tournaments-widget">
  <div class="whg-tournaments-widget__header">
    <button
      [ngClass]="{ 'whg-tournaments-widget__active-item-arrow--hidden': config.type === widgetTypeEnumList }"
      class="whg-tournaments-widget__active-item-arrow"
      [inlineSVG]="'/assets/icons/arrow-left.svg'"
      (click)="showList()"></button>
    <h2 *ngIf="config.type === widgetTypeEnumList">{{ "Today's Tournaments" | vegastranslate }}</h2>
    <h2 *ngIf="config.type === widgetTypeEnumTable">{{ 'Leaderboard' | vegastranslate }}</h2>
    <h2 *ngIf="config.type === widgetTypeEnumItem">{{ 'Tournament Info' | vegastranslate }}</h2>
    <span [inlineSVG]="'/assets/icons/close.svg'" (click)="closeWidget()"></span>
  </div>
  <div class="whg-tournaments-widget__content-wrapper">
    <ng-container *ngIf="config.type === widgetTypeEnumList">
      <div *ngFor="let tournament of tournaments">
        <div class="whg-tournaments-widget__content-item">
          <div class="whg-tournaments-widget__content-item-image" [ngStyle]="{ 'background-image': 'url(' + tournament.image + ')' }"></div>
          <div class="whg-tournaments-widget__content-item-info">
            <div>
              <h4>{{ tournament.title }}</h4>
              <div [innerHTML]="tournament.shortDescription"></div>
            </div>
            <div>
              <p class="entered" *ngIf="!tournament.optinRequired && isLoggedIn">• {{ 'Entered' | vegastranslate }}</p>
              <span>{{ tournament.remainingTime }} {{ 'remaining' | vegastranslate }}</span>
            </div>
          </div>
          <button class="btn btn-primary btn-sm whg-tournaments-widget__content-item-action-2" (click)="setTournamentItem(tournament)">
            {{ 'More Info' | vegastranslate }}
          </button>
        </div>
        <div *ngIf="tournament.sigTerms" class="whg-tournaments-widget__terms">
          <span [innerHTML]="tournament.sigTerms"></span>
          <span>&nbsp;</span>
          <a class="full-terms" (click)="setTournamentItem(tournament)">{{ 'Full Terms Apply' | vegastranslate }}</a>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="whg-tournaments-widget__table" *ngIf="config.type === widgetTypeEnumTable">
    <div class="whg-tournaments-widget__active-item-image" [ngStyle]="{ 'background-image': 'url(' + activeTournament?.image + ')' }"></div>
    <div class="whg-tournaments-widget__info">
      <div>
        <h2>{{ activeTournament?.title }}</h2>
      </div>
      <div>
        <label class="remaining-time">{{ activeTournament?.remainingTime }} {{ 'remaining' | vegastranslate }}</label>
        <button class="btn btn-sm btn-white" (click)="setTournamentItem(activeTournament!)">
          {{ 'Details' | vegastranslate }}
        </button>
        <span class="ml-1" *ngIf="!activeTournament?.optinRequired && isLoggedIn">• {{ 'Entered' | vegastranslate }}</span>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{ 'Rank' | vegastranslate }}</th>
          <th>{{ 'Name' | vegastranslate }}</th>
          <th></th>
          <th>{{ 'Points' | vegastranslate }}</th>
          <th>{{ 'Prize' | vegastranslate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of leaderboard; let i = index"
          [ngClass]="{ 'whg-tournaments-widget--user': isLoggedIn && userId?.toString() === row.memberRefId }">
          <td>
            <span
              [ngClass]="{
                'whg-tournaments-widget--first': i === 0,
                'whg-tournaments-widget--second': i === 1,
                'whg-tournaments-widget--third': i === 2
              }">
              {{ row.rank }}
            </span>
          </td>
          <td>{{ row.name }}</td>
          <td>
            <span *ngIf="row.change > 0" class="whg-tournaments-widget__arrow whg-tournaments-widget__arrow--up"></span>
            <span *ngIf="row.change === 0">•</span>
            <span *ngIf="row.change < 0" class="whg-tournaments-widget__arrow whg-tournaments-widget__arrow--down"></span>
          </td>
          <td>{{ row.points }}</td>
          <td>{{ rewards[i] && rewards[i].value }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="whg-tournaments-widget__active-item" *ngIf="config.type === widgetTypeEnumItem">
    <div class="whg-tournaments-widget__active-item-image" [ngStyle]="{ 'background-image': 'url(' + activeTournament?.image + ')' }"></div>
    <div class="whg-tournaments-widget__info">
      <div>
        <h2>{{ activeTournament?.title }}</h2>
      </div>
      <div>
        <label class="remaining-time">{{ activeTournament?.remainingTime }} {{ 'remaining' | vegastranslate }}</label>
        <button class="btn btn-sm mr-1 btn-white" (click)="setTournamentLeaderBoard(activeTournament!)">
          {{ 'Leaderboard' | vegastranslate }}
        </button>

        <button
          *ngIf="activeTournament?.optinRequired && isLoggedIn && !activeTournament?.hasOwnProperty('hasOptin')"
          class="btn btn-primary btn-sm"
          (click)="takePart(activeTournament!)">
          {{ 'Take Part' | vegastranslate }}
        </button>
        <span *ngIf="!activeTournament?.optinRequired && isLoggedIn">• {{ 'Entered' | vegastranslate }}</span>
      </div>
    </div>
    <div class="whg-tournaments-widget__active-item-info">
      <ng-scrollbar class="whg-tournaments-widget__active-item-description">
        <div class="description" [innerHTML]="activeTournament?.description"></div>
        <div class="description" [innerHTML]="activeTournament?.terms"></div>
      </ng-scrollbar>
    </div>
  </div>
</div>
