export enum MenuTypes {
  LoggedIn,
  LoggedOut,
  Restricted,
}

export enum MenuItemType {
  Anchor,
  Button,
  Disabled,
  ButtonClick,
}

export type MenuItem = {
  name: string;
  styles: string;
  class?: string;
  subMenu?: MenuItem[];
} & (
  | {
      type: MenuItemType.Button;
    }
  | {
      type: MenuItemType.ButtonClick;
    }
  | {
      type: MenuItemType.Disabled;
    }
  | {
      link: string;
      type: MenuItemType.Anchor;
    }
);

export type MenuConfig = {
  [key in MenuTypes]: MenuItem[];
};
