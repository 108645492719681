import { BrandmarketingSchema } from '../user/user.model';

export const PromotionApiUrl = {
  updateMarketingPreferences: 'user-marketing-update'
}

export enum PromotionChannelType {
  email = 'email',
  sms = 'sms'
}

export class PromotionMarketing implements BrandmarketingSchema {
  email: boolean = false;
  phone: boolean = false;
  post: boolean = false;
  sms: boolean = false;

  constructor() {
  }

  public static fromBrandMarketingSchema(brandMarketingPreferences: BrandmarketingSchema): PromotionMarketing {
    const promotionMarketing = new PromotionMarketing();
    promotionMarketing.email = brandMarketingPreferences.email;
    promotionMarketing.sms = brandMarketingPreferences.sms;
    promotionMarketing.phone = brandMarketingPreferences.phone;
    promotionMarketing.post = brandMarketingPreferences.post;
    return promotionMarketing;
  }
}
