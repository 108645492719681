import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ImageTypes, RestrictionCriteriasConfig } from './core.models';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private legacyTranslations$ = new BehaviorSubject<Record<string, string> | undefined>(undefined);
  private defaultLanguage = 'en';
  private currentLanguage: string = this.cookieService.get('language');
  private legacyTranslations: Record<string, string> = {};
  isDev = location.hostname.includes('localhost') || location.hostname.startsWith('dev-');
  isStg = location.hostname.includes('whgstage') && !location.hostname.startsWith('dev-');

  jackpotsUrl =
    this.isDev || this.isStg ? 'https://feeds-jackpots.s3.amazonaws.com/' : 'https://jackpots.whitehatgaming.com/';
  platformBaseUrl =
    this.isDev || this.isStg
      ? 'https://platform.whgstage.com/platform/usergateway/'
      : 'https://platform.whitehatgaming.com/platform/usergateway/';
  scontentUrl =
    this.isDev || this.isStg ? 'https://stage.whgstage.com/scontent/' : 'https://scontent-wh.whitehatgaming.com/';
  legacyUrl = this.isDev ? 'https://dev-vegasloungecasino.whgstage.com/' : '';

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private translateService: TranslateService
  ) {
    if (this.currentLanguage) {
      this.translateService.use(this.currentLanguage);
    } else {
      this.translateService.use(this.defaultLanguage);
    }
  }
  // TODO: rename as it not a random interval, its a random number
  randomInterval(min: number, max: number): number {
    const rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
  }

  filterArrayByLocation<T extends RestrictionCriteriasConfig>(
    element: T[],
    jurisdiction: string,
    countryCode: string
  ): T[] {
    return element.filter(el => this.allowedByLocation(el, jurisdiction, countryCode));
  }

  allowedByLocation<T extends RestrictionCriteriasConfig>(el: T, jurisdiction: string, countryCode: string): boolean {
    if (!this.ifRestrictionsPresent(el)) {
      return true;
    }

    let showed = false;

    if (!el.excludedJurisdictions || !el.excludedJurisdictions.length) {
      // * element applies for all jurisdictions
      showed = true;
    } else if (el.excludedJurisdictions && el.excludedJurisdictions.includes(jurisdiction)) {
      // * el found in excluded jurisdictions array
      showed = false;
    } else {
      // * there are excluded jurisdictions for this element but the current jurisdiction is different
      showed = true;
    }

    if (showed) {
      // tslint:disable-next-line:max-line-length
      if (
        (!el.includedOnlyLanguages || !el.includedOnlyLanguages.length) &&
        (!el.excludedOnlyLanguages || !el.excludedOnlyLanguages.length)
      ) {
        // * if there are no restrictions for the element then show it everywhere
        showed = true;
      } else if (el.includedOnlyLanguages && el.includedOnlyLanguages.includes(countryCode)) {
        // * show only for specific countries
        showed = true;
      } else if (el.excludedOnlyLanguages && el.excludedOnlyLanguages.includes(countryCode)) {
        // * hide for this countries
        showed = false;
        // tslint:disable-next-line:max-line-length
      } else {
        // * if there are restrictions for both included and excluded but geoLocationCountryCode is not there,
        // in this case includedOnlyLanguages takes priority
        showed = false;
      }
    }

    return showed;
  }

  /*
   * The cookie is set in legacy, the page is also refreshed therefore we can not use state
   */
  getSessionIdFromCookie(): string {
    return this.cookieService.get('sid');
  }
  getLanguageFromCookie(): string {
    return this.cookieService.get('language');
  }

  removeHtmlTags(description: string): string {
    return description.replace(/(<([^>]+)>)/gi, '');
  }

  getImagePath(imgCode: string, type: ImageTypes = ImageTypes.Portrait): string {
    return this.scontentUrl + type + imgCode + '.jpg';
  }

  /*
   * Because we are using Angular Elements, we can not get the current url query param by using Angular's Router,
   * Therefore using HttpParams instead
   */
  getParamValueQueryString(paramName: string) {
    const url: string = window.location.href;
    /*
     *   We get the first value after '?'
     */
    const httpParams: HttpParams = new HttpParams({ fromString: url.split('?')[1] });
    return httpParams.get(paramName) || undefined;
  }

  hideContentIfGameCategoryOpened(): boolean {
    return !!this.getParamValueQueryString('cat');
  }

  private ifRestrictionsPresent<T extends RestrictionCriteriasConfig>(el: T): el is Required<T> {
    return (
      el.hasOwnProperty('excludedJurisdictions') &&
      el.hasOwnProperty('includedOnlyLanguages') &&
      el.hasOwnProperty('excludedOnlyLanguages')
    );
  }

  setLegacyTranslations(translatedObject: Record<string, string>): void {
    this.legacyTranslations = translatedObject;
  }

  getLegacyTranslation(key: string): string | undefined {
    return this.legacyTranslations[key];
  }

  setErrorCodeTranslations(translatedObject: Record<string, string>) {
    this.legacyTranslations$.next(translatedObject);
  }

  getErrorCodeTranslations(key: string) {
    return this.legacyTranslations$.pipe(
      filter(x => !!x),
      map<any, string | undefined>(values => values[key])
    );
  }

  /**
   * @todo Migrate to routerLink after full Angular Migration
   */
  navigateWithRouter(event: MouseEvent, link: string): boolean {
    if (
      this.router.routerState.snapshot.url === '' ||
      event.button !== 0 ||
      event.ctrlKey ||
      event.shiftKey ||
      event.altKey ||
      event.metaKey
    ) {
      return true;
    }

    this.router.navigateByUrl(link, {
      // TODO: enable after angular upgrade
      // scrollPositionRestoration: 'top'
    });
    return false;
  }

  public getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }
}
