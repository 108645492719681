import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { CoreService } from '../core.service';
import {
  CompetitionRequest,
  CompetitionsRequest,
  ContestRequest,
  LeaderBoardRequest,
  OptInRequest,
} from './tournaments.model';

@Injectable({
  providedIn: 'root',
})
export class TournamentsService {
  private baseUrl = 'https://gateway.ziqni.com/api/v1/';
  public apiKey =
    this.coreService.isDev || this.coreService.isStg
      ? '157d2719a6644f50b2d70ceb0acc86be'
      : 'f1e71d63ced543a98c6a0b092ffa8c6a';
  public spaceName = this.coreService.isDev || this.coreService.isStg ? 'vegasloungecsno_stg' : 'vegasloungecasino';

  constructor(private coreService: CoreService, private httpClient: HttpClient) {}

  // Get CompetitionLabs competitions
  getCompetitions(): Observable<CompetitionsRequest> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        statusCode: '5',
        _include: 'metadata',
        _limit: '1000',
        _lang: this.coreService.getCurrentLanguage(),
      },
    });
    const options = { headers: this.headersForGetRequest(), params };
    const url = `${this.baseUrl}${this.spaceName}/competitions`;

    return this.httpClient.get<CompetitionsRequest>(url, options);
  }

  getContests(scheduleStart: string, scheduleEnd: string) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        _include: 'metadata',
        _limit: '1000',
        _lang: this.coreService.getCurrentLanguage(),
        'scheduledStart>=': scheduleStart,
        'scheduledEnd<=': scheduleEnd,
      },
    });
    const options = { headers: this.headersForGetRequest(), params };
    const url = `${this.baseUrl}${this.spaceName}/contests`;

    return this.httpClient.get<{ data: ContestData[] }>(url, options);
  }

  // Get CompetitionLabs competition
  getCompetition(competitionId: string): Observable<CompetitionRequest> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        _include: 'metadata',
        _limit: '1000',
        _lang: this.coreService.getCurrentLanguage(),
      },
    });
    const options = { headers: this.headersForGetRequest(), params };
    const url = `${this.baseUrl}${this.spaceName}/competitions/${competitionId}`;
    return this.httpClient.get<CompetitionRequest>(url, options);
  }

  // Get CompetitionLabs competition leader-board
  getTournamentLeaderboard(contestId: string): Observable<LeaderBoardRequest> {
    const options = { headers: this.headersForGetRequest(), _lang: this.coreService.getCurrentLanguage() };
    const url = `${this.baseUrl}${this.spaceName}/contests/${contestId}/leaderboard`;

    return this.httpClient.get<LeaderBoardRequest>(url, options);
  }

  // Opt in to a CompetitionLabs competition
  optIn(userId: number, competitionId: string): Observable<any> {
    const options = { headers: this.headersForGetRequest() };
    const url = `${this.baseUrl}${this.spaceName}/members/reference/${userId}/competition/${competitionId}/optin`;

    return this.httpClient.get<any>(url, options);
  }

  // Get Competition Labs competition Opted in Users
  getOptedInUsers(userId: number, competitionId: string): Observable<OptInRequest> {
    const options = { headers: this.headersForGetRequest() };
    const url = `${this.baseUrl}${this.spaceName}/members/reference/${userId}/competition/${competitionId}/entrants`;

    return this.httpClient.get<OptInRequest>(url, options);
  }

  // Get CompetitionLabs tournament details
  getTournamentDetail(contestId: string): Observable<ContestRequest> {
    const options = { headers: this.headersForGetRequest(), _lang: this.coreService.getCurrentLanguage() };
    const url = `${this.baseUrl}${this.spaceName}/contests/${contestId}`;

    return this.httpClient.get<ContestRequest>(url, options);
  }

  private headersForGetRequest(): HttpHeaders {
    return new HttpHeaders({ 'X-API-KEY': this.apiKey });
  }
}

export interface ContestData {
  statusCode: number;
  number: number;
  actualStart: Date;
  actualEnd: Date;
  entrantsFromContest: any[];
  description: string;
  label: string;
  jsonClass: string;
  rewards: any[];
  scheduledStart: Date;
  id: string;
  termsConditions: string;
  status: string;
  scheduledEnd: Date;
  round: number;
  competitionId: string;
  roundType: string;
  metadata?: { jsonClass: string; key: string; value: string }[];
}
