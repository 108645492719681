import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PageStore } from '../core-services/page/page.store';
import { VisitorState } from '../core-services/visitor/visitor.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { CoreService } from '../core-services/core.service';
import { TranslateService } from '@ngx-translate/core';

export const LEGACY_GATEWAY_SELECTOR = 'whg-legacy-gateway';

// TODO: Remove below line after typescript is upgraded to 3.6+
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

@Component({
  selector: LEGACY_GATEWAY_SELECTOR,
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Gateway between legacy site & angular services,
 * this should be a temporary component that should be removed after full angular migration.
 */
export class LegacyGatewayComponent implements OnChanges {
  @Input() visitor?: string;
  @Input() translations?: string;
  @Input() errors?: string;
  @Input() page?: string;

  constructor(private visitorStore: VisitorStore, private coreService: CoreService, private pageStore: PageStore,
              private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.visitor) {
      const legacy: Partial<Omit<VisitorState, 'paymentMethods' | 'deviceType'>> = JSON.parse(
        changes.visitor.currentValue
      );

      if (legacy.jurisdiction) {
        this.visitorStore.updateJurisdiction(legacy.jurisdiction);
      }
      if (legacy.countryCode) {
        this.visitorStore.updateCountryCode(legacy.countryCode);
      }
      if (legacy.currencyCode) {
        this.visitorStore.updateCurrencyCode(legacy.currencyCode);
      }
      if (legacy.currencySymbol) {
        this.visitorStore.updateCurrencySymbol(legacy.currencySymbol);
      }
      if (legacy.loggedIn !== undefined) {
        this.visitorStore.updateLoggedIn(legacy.loggedIn);
      }
    }

    if (changes.errors) {
      const raw = changes.errors.currentValue;
      this.coreService.setErrorCodeTranslations(JSON.parse(raw));
    }
    if (changes.translations) {
      const raw = changes.translations.currentValue;
      this.coreService.setLegacyTranslations(JSON.parse(raw));
    }

    if (changes.page) {
      const raw = changes.page.currentValue;
      this.pageStore.setState(JSON.parse(raw));
    }
  }
}
