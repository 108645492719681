<div *ngIf="tile.game" class="whg-tile__img-wrapper">
  <button (click)="tileClick()" class="whg-tile__img-button">
    <img class="mb-1 w-100" [src]="tile.image.src" [alt]="tile.image.alt" imageFallback loading="lazy" />
  </button>
  <small class="whg-tile__flag" *ngIf="!!flag" [ngClass]="{ recommended: flag === 'Recommended' }">{{ flag | vegastranslate }}</small>
  <span class="whg-tile__jackpot" *ngIf="jackpot$ | async as jackpot">
    {{ jackpot | currencyConverter: countryCode }}
  </span>
</div>
<ng-container *ngIf="!tile.game">
  <a [href]="tile.link" (click)="coreService.navigateWithRouter($event, tile.link)">
    <div class="whg-tile__provider d-flex align-items-center justify-content-center mb-1 w-100 position-relative">
      <img loading="lazy" class="position-absolute text-center" [src]="tile.image.src" [alt]="tile.image.alt" />
    </div>
  </a>
</ng-container>
<div class="whg-tile__info d-flex justify-content-between align-items-center">
  <div class="d-flex flex-column">
    <span>{{ tile.title | vegastranslate | vlcEllipsis: titleMaxLength }}</span>
    <span class="small text-muted" [class.whg-tile__bigwin-sub-title]="tile.isBigWin"
      >{{ tile.subTitle | vegastranslate | vlcEllipsis: subMaxTitleLength }}
    </span>
  </div>
  <ng-container *ngIf="tile.game">
    <button
      *ngIf="(isFavourite(tile.game) | async) || isLocalFavourite(tile.game); else noFav"
      class="p-0 pl-0 mx-1 whg-tile__info--favorite"
      inlineSVG="/assets/icons/favourites.svg"
      (click)="toggleFavourite(tile.game)"></button>
    <ng-template #noFav>
      <button class="p-0 pl-0 mx-1" inlineSVG="/assets/icons/favourites.svg" (click)="toggleFavourite(tile.game)"></button>
    </ng-template>
  </ng-container>
</div>
