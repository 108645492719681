import { WidgetItemTypeEnum } from './gamification-widget.model';

export const widgetConfig: WidgetConfigInterface[] = [
  {
    type: WidgetItemTypeEnum.tournaments,
    icon: '/assets/icons/trophy.svg',
  },
  {
    type: WidgetItemTypeEnum.challenges,
    icon: '/assets/icons/bolt.svg',
  },
];

export interface WidgetConfigInterface {
  type: string;
  icon: string;
}
