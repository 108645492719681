import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { of, throwError, Observable } from 'rxjs';

import { PlatformResponse, PlatformRequest } from './platform.model';
import { CoreService } from '../core.service';

/*
 *   Platform accepts all requests as a POST request,
 *   I like this approach as platform acts as a conductor and reacts depending
 *   on what command it receives, ApolloServer also works the same way
 */

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private http: HttpClient, private coreService: CoreService) {}
  execute<T extends keyof PlatformRequest>(type: T, body: PlatformRequest[T]): Observable<PlatformResponse[T]> {
    return this.http
      .post<PlatformResponse[keyof PlatformResponse]>(
        `${this.coreService.platformBaseUrl}${type}`,
        // @todo find a replacement for 'as any' here
        { ...(body as any), sessionid: this.coreService.getSessionIdFromCookie() }
      )
      .pipe(
        switchMap(response => {
          // Make sure you always get a response back of the expected type
          if (this.isExpectedType(response, type)) {
            return of(response);
          } else {
            return throwError(response);
          }
        })
      );
  }

  isExpectedType<T extends keyof PlatformResponse>(
    response: PlatformResponse[keyof PlatformResponse],
    type: T
  ): response is PlatformResponse[T] {
    return response.type === type;
  }
}
