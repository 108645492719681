<div class="progress-title d-flex justify-content-between">
  <div *ngIf="currentLevel?.header as current">
    <span class="progress-title__icon current" [inlineSVG]="current.iconUrl"></span>
    <span class="progress-title__text">{{ current.title | vegastranslate }}</span>
  </div>
  <div *ngIf="nextLevel && nextLevel.header as next">
    <span class="progress-title__text">{{ next.title | vegastranslate }}</span>
    <span class="progress-title__icon next" [inlineSVG]="next.iconUrl"></span>
  </div>
</div>
<div class="progress">
  <div
    class="progress-bar progress-bg"
    role="progressbar"
    [style.width]="progress + '%'"
    [attr.aria-valuenow]="progress"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
