<h1>{{ 'Vegas Lounge Planner' | vegastranslate }}</h1>
<h2 class="text-muted">{{ monthName | vegastranslate }} {{ year }}</h2>
<ng-container *ngIf="!isLoading; else spinner">
  <table>
    <thead>
      <tr [ngClass]="{ 'whg-calendar--week': calendarView === calendarViewEnum.Week }">
        <th *ngFor="let day of weekdays">
          {{ day.day | vegastranslate }}
          <div
            class="whg-calendar__promotions"
            *ngIf="calendarView === calendarViewEnum.Week && day.promotions.length > 0"
          >
            <span *ngFor="let promotion of day.promotions" [ngStyle]="{ background: promotion.color }"></span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="calendarView === calendarViewEnum.Month">
      <tr *ngFor="let week of month">
        <td *ngFor="let day of week">
          <div
            (click)="onDaySelect(day)"
            [ngClass]="{
              'whg-calendar__day--empty': !day.isThisMonth,
              'whg-calendar__day--expired': day.isThisMonth && day.isYesterdayOrBefore,
              'whg-calendar__day--today': day.isToday,
              'whg-calendar__day--selected': this.currentDaySelected.day === day.day && day.isThisMonth
            }"
          >
            <ng-container *ngIf="day.isThisMonth">{{ day.day | vegastranslate }}</ng-container>
            <div class="whg-calendar__promotions" *ngIf="day.legends.length && day.isThisMonth">
              <span *ngFor="let legend of day.legends" [ngStyle]="{ background: legend.color }"></span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #spinner>
  <div class="spinner-container">
    <ngx-spinner
      name="calendar-spinner"
      bdColor="rgba(0,0,0,0)"
      [fullScreen]="false"
      size="large"
      type="ball-scale-pulse"
    ></ngx-spinner>
  </div>
</ng-template>
