import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { InfoModalService } from './info-modal.service';
import { InfoModal } from './info-modal.model';
import { IcsService } from '../core-services/ics/ics.service';

export const INFO_MODAL_SELECTOR = 'whg-info-modal';

@Component({
  selector: INFO_MODAL_SELECTOR,
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  public infoModal!: InfoModal;

  constructor(private infoModalService: InfoModalService, private icsService: IcsService, private ngbModal: NgbModal) {
    this.infoModal = this.infoModalService.getConfig;
  }

  public closeModal(): void {
    this.ngbModal.dismissAll();
  }

  public downloadReminder(): void {
    if (this.infoModal && this.infoModal.ics) {
      this.icsService.createIcs(this.infoModal.ics);
    }
  }
}
