import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Highlights, HighlightsJson, HighlightsState, Language } from './highlights.model';
import { HttpClient } from '@angular/common/http';
import { mergeMap, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { CoreService } from '../core.service';
import { CalendarEvent } from '../calendar/calendar.model';

@Injectable({
  providedIn: 'root',
})
export class HighlightsStore extends ComponentStore<HighlightsState> {
  language = this.coreService.getLanguageFromCookie();
  constructor(private http: HttpClient, private coreService: CoreService) {
    super({
      highlights: undefined,
    });
  }

  readonly highlights$ = this.select(state => state.highlights);

  readonly addHighlights = this.updater((state, highlights: Highlights[]) => ({
    ...state,
    highlights,
  }));

  readonly getHighlights = this.effect<string>(path$ => {
    return path$.pipe(
      distinctUntilChanged(),
      switchMap(path => this.http.get<HighlightsJson>(path)),
      map(json => json.compHighlights),
      tap((highlights: Highlights[]) => {
        this.addHighlights(highlights);
      })
    );
  });

  getHighLightsInLanguage(highlights: Highlights[]) {
    return highlights?.map(highlight => highlight.languages.find(language => language.lang === this.language));
  }
}
