import { Component, Input, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';

import { VisitorStore } from '../core-services/visitor/visitor.store';
import { BaseComponent } from '../base.component';
import { DeviceType } from '../core-services/visitor/visitor.model';
import { CountryCodesType, CurrencyCodesType } from '../gamification-widget/country-currency-codes';
import { environment } from '../../environments/environment';

export const MAIN_BANNER_SELECTOR = 'whg-main-banner';

@Component({
  selector: MAIN_BANNER_SELECTOR,
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss'],
})
export class MainBannerComponent extends BaseComponent implements OnInit {
  @Input() text1 = '';
  @Input() step1 = '';
  @Input() text2 = '';
  @Input() text3 = '';
  @Input() step2 = '';
  @Input() text4 = '';
  @Input() pragmatic1 = '';
  @Input() pragmatic2 = '';
  @Input() pragmatic2a = '';
  @Input() pragmatic3 = '';
  @Input() pragmatic4 = '';
  @Input() pragmatic5 = '';
  @Input() pragmatic6 = '';
  @Input() pragmatic7 = '';
  @Input() cruise1 = '';
  @Input() cruise2 = '';
  @Input() cruise3 = '';
  @Input() cruise4 = '';
  @Input() cruise5 = '';
  @Input() cruise6 = '';
  @Input() buttonText = '';
  @Input() videoSrc = '';
  _videoLoop = true;
  @Input() set videoLoop(videoLoop: string) {
    this._videoLoop = /true/i.test(videoLoop);
  }
  @Input() videoMobileSrc = '';
  link = '';
  public currency = '';
  isMobile = false;
  private deviceType?: string;
  isStMaartensPromoActive = false;
  isDecPromoActive = false;
  isJanPromoActive = false;
  isFebPromoActive = false;
  countryCode!: CountryCodesType;
  currencyCode!: CurrencyCodesType;

  constructor(private visitorStore: VisitorStore) {
    super();
  }

  ngOnInit() {
    this.subscribeUser();
    this.subscribeDeviceType();
    this.setStMaartensPromoBanner();
    this.setDecPromoBanner();
    this.setJanPromoBanner();
    this.setFebPromoBanner();
    this.visitorStore.countryCode$.pipe(take(1)).subscribe((v) => (this.countryCode = v as CountryCodesType));
    this.visitorStore.currencySymbol$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currency = res as string;
    });
  }

  subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
      this.link = this.link = isLoggedIn ? '/deposit' : '/register';
    });
  }

  private subscribeDeviceType(): void {
    this.visitorStore.deviceType$.pipe(takeUntil(this.destroy$)).subscribe((deviceType) => {
      this.deviceType = deviceType;
      this.setIsMobile();
    });
  }

  private setIsMobile(): void {
    this.isMobile = this.deviceType === DeviceType.Mobile;
  }

  private setStMaartensPromoBanner(): void {
    let currentDateWithTimeZone = new Date();

    if (!environment.production) {
      const params = new URLSearchParams(window.location.search);
      const datetest = params.get('datetest');
      if (datetest) {
        // example date: '2023-06-22_02:00:02'
        const last = datetest.lastIndexOf('-');
        currentDateWithTimeZone = new Date(datetest.replace('_', ' '));
      }
    }

    const fromDate = new Date('Sept 01, 2023 00:00:01 GMT+0000');
    const toDate = new Date('Nov 30, 2023 23:59:59 GMT+0000');

    this.isStMaartensPromoActive = fromDate < currentDateWithTimeZone && currentDateWithTimeZone < toDate;
  }

  private setDecPromoBanner(): void {
    let currentDateWithTimeZone = new Date();

    if (!environment.production) {
      const params = new URLSearchParams(window.location.search);
      const datetest = params.get('datetest');
      if (datetest) {
        // example date: '2023-06-22_02:00:02'
        const last = datetest.lastIndexOf('-');
        currentDateWithTimeZone = new Date(datetest.replace('_', ' '));
      }
    }

    const fromDate = new Date('Dec 01, 2023 00:00:00 GMT+0000');
    const toDate = new Date('Dec 31, 2023 23:59:59 GMT+0000');

    this.isDecPromoActive = fromDate < currentDateWithTimeZone && currentDateWithTimeZone < toDate;
  }

  private setJanPromoBanner(): void {
    let currentDateWithTimeZone = new Date();

    if (!environment.production) {
      const params = new URLSearchParams(window.location.search);
      const datetest = params.get('datetest');
      if (datetest) {
        // example date: '2023-06-22_02:00:02'
        const last = datetest.lastIndexOf('-');
        currentDateWithTimeZone = new Date(datetest.replace('_', ' '));
      }
    }

    const fromDate = new Date('Jan 01, 2024 00:00:00 GMT+0000');
    const toDate = new Date('Jan 31, 2024 23:59:59 GMT+0000');

    this.isJanPromoActive = fromDate < currentDateWithTimeZone && currentDateWithTimeZone < toDate;
  }

  private setFebPromoBanner(): void {
    let currentDateWithTimeZone = new Date();

    if (!environment.production) {
      const params = new URLSearchParams(window.location.search);
      const datetest = params.get('datetest');
      if (datetest) {
        // example date: '2023-06-22_02:00:02'
        console.log('datetest: ', datetest);
        const last = datetest.lastIndexOf('-');
        currentDateWithTimeZone = new Date(datetest.replace('_', ' '));
      }
    }

    const fromDate = new Date('Feb 01, 2024 00:00:00 GMT+0000');
    const toDate = new Date('Feb 29, 2024 23:59:59 GMT+0000');

    this.isFebPromoActive = fromDate < currentDateWithTimeZone && currentDateWithTimeZone < toDate;
  }

  public getLearnMoreLink() {
    const LatamCountryCodes = ['BR', 'AR', 'PE'];
    if (this.countryCode === 'GB') {
      this.currencyCode = 'GBP';
    } else if (this.countryCode === 'NO') {
      this.currencyCode = 'NOK';
    } else if (this.countryCode === 'CA') {
      this.currencyCode = 'CAD';
    } else if (this.countryCode === 'NZ') {
      this.currencyCode = 'NZD';
    } else if (LatamCountryCodes.includes(this.countryCode)) {
      this.currencyCode = 'USD';
    } else {
      this.currencyCode = 'EUR';
    }

    return `https://www.viphonorsclub.com/media/lp/cruisethemed/vegasloungecasino_cruisethemed_EN_${this.currencyCode}.html`;
  }

  public getSigTermsLink() {
    const LatamCountryCodes = ['BR', 'AR', 'PE'];
    if (this.countryCode === 'GB') {
      this.currencyCode = 'GBP';
    } else if (this.countryCode === 'NO') {
      this.currencyCode = 'NOK';
    } else if (this.countryCode === 'CA') {
      this.currencyCode = 'CAD';
    } else if (this.countryCode === 'NZ') {
      this.currencyCode = 'NZD';
    } else if (LatamCountryCodes.includes(this.countryCode)) {
      this.currencyCode = 'USD';
    } else {
      this.currencyCode = 'EUR';
    }

    return `https://www.viphonorsclub.com/media/lp/cruisethemed/vegasloungecasino_cruisethemed_EN_${this.currencyCode}.html#cruise-terms`;
  }
}
