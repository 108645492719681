import { GameNavConfig } from './game-nav.models';

export const baseUrl = '/games';

export const gameNavConfig: GameNavConfig = {
  categories: [
    {
      text: 'All',
      url: `${baseUrl}`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Recommended',
      url: `${baseUrl}/recommended`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Live Casino',
      url: `${baseUrl}/live-casino`,
      style: 'live',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'New Games',
      url: `${baseUrl}/new-games`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Slots',
      url: `${baseUrl}/slots`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Table Games',
      url: `${baseUrl}/table-games`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Jackpots',
      url: `${baseUrl}/jackpots`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Daily Jackpots',
      url: `${baseUrl}/daily-jackpots`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Scratch Cards',
      url: `${baseUrl}/scratch-cards`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Free Play',
      url: `${baseUrl}/free-play`,
      style: '',
      excludedJurisdictions: ['de'],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
    {
      text: 'Providers',
      url: `${baseUrl}/providers`,
      style: '',
      excludedJurisdictions: [],
      includedOnlyLanguages: [],
      excludedOnlyLanguages: [],
    },
  ],
};
