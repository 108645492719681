<div class="promo-card">
  <div class="promo-card__wrapper">
    <div class="promo-card__image">
      <img class="open-widget" [src]="promo.image" (click)="openWidget()" alt="promo image" />
    </div>

    <div class="promo-card__info">
      <!-- <div class="legend"><span *ngFor="let promo of currentPromo" [ngStyle]="{ background: promo.color }"></span></div> -->
      <div class="legend"><span [ngStyle]="{ background: promo.color }"></span></div>
      <h3 class="open-widget" (click)="openWidget()">{{ promo.title }}</h3>
      <!-- <div class="open-widget subtitle" (click)="openWidget()">{{ promo.subtitle }}</div> -->
    </div>

    <div class="promo-card__action d-flex flex-column">
      <button *ngIf="isTodayPromotion" class="btn btn-primary btn-sm" (click)="openWidget()">
        {{ 'Read More' | vegastranslate }}
      </button>
    </div>
  </div>
  <div class="promo-card__terms">
    <div *ngIf="promo.sigTerms" class="terms-text">
      <div [innerHTML]="promo.sigTerms"></div>
    </div>
  </div>
</div>
