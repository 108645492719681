<div *ngIf="infoModal" class="whg-info-modal">
  <button class="btn whg-info-modal__close" (click)="closeModal()" [inlineSVG]="'/assets/icons/close.svg'"></button>
  <img [src]="infoModal.imageSrc" [alt]="infoModal.title" />
  <div class="whg-info-modal__content">
    <h3>{{ infoModal.title | vegastranslate }}</h3>
    <div class="whg-info-modal__sub-title">{{ infoModal.subTitle }}</div>
    <ng-scrollbar class="whg-info-modal__description">
      <div class="whg-info-modal__description-content" [innerHTML]="infoModal.description"></div>
    </ng-scrollbar>
    <a *ngIf="infoModal.button" class="btn btn-primary btn-block whg-info-modal__button" [href]="infoModal.button.link">
      {{ infoModal.button.label | vegastranslate }}
    </a>
    <button *ngIf="infoModal.callback" class="btn btn-primary btn-block whg-info-modal__callback" (click)="infoModal.callback.function()">
      {{ infoModal.callback.label | vegastranslate }}
    </button>
    <button *ngIf="infoModal.ics" class="whg-info-modal__reminder" (click)="downloadReminder()">
      <i class="fa fa-bell"></i>{{ 'Set a reminder' | vegastranslate }}
    </button>
  </div>
</div>
