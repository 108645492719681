import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FavoritesStore } from '../core-services/favorites/favorites.store';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { Game } from '../core-services/games/games.model';
import { TileInterface } from '../tile/tile.model';
import { CoreService } from '../core-services/core.service';
import { PlayService } from '../core-services/play/play.service';
import { GameModeEnum } from '../core-services/play/play.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { TileComponent } from '../tile/tile.component';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../core-services/seo-service/seo.service';

export const GAME_FAVOURITE_SELECTOR = 'whg-game-favourite';

@Component({
  selector: GAME_FAVOURITE_SELECTOR,
  templateUrl: './game-favourite.component.html',
  styleUrls: ['./game-favourite.component.scss'],
})
export class GameFavouriteComponent extends BaseComponent implements OnInit {
  games: Game[] = [];
  tiles: TileInterface[] = [];
  emptyTilesArray = Array(18).fill(0);
  isLoading = true;
  isLoggedIn = false;
  redirectLink = '/games/recommended';
  iconPath = '/assets/icons/plus.svg';
  seoJSONfileName = 'favourite_recent.json';
  jsonHTMLSEO$: any;
  @ViewChildren('tiles') tilesList!: QueryList<TileComponent>;

  constructor(
    private favouritesStore: FavoritesStore,
    private coreService: CoreService,
    private playService: PlayService,
    private visitorStore: VisitorStore,
    private seoService: SeoService,
    private route: ActivatedRoute
  ) {
    super();
    this.jsonHTMLSEO$ = this.seoService.getHTMLJSON(this.seoJSONfileName);
  }

  ngOnInit() {
    this.getFavouriteGames();
    this.subscribeUser();
  }

  setGameTiles(): void {
    if (this.games.length < 1) {
      this.tiles = [];
      return;
    }
    this.tiles = this.games.map((game) => {
      const { id, launchcode, name, provider, hot, new: n, image } = game;
      return {
        id,
        image: {
          src: `${this.coreService.scontentUrl}images/portraitdt/${image}.jpg`,
          alt: name,
        },
        title: name,
        subTitle: provider,
        game,
        hot,
        new: n,
        link: this.playService.getGameUrl(launchcode, true, GameModeEnum.Real),
      };
    });
    this.isLoading = false;
  }

  resize() {
    this.tilesList.forEach((tile) => tile.truncateText());
  }

  private getFavouriteGames() {
    this.favouritesStore.favorites$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.games = Array.from(res);
      this.setGameTiles();
    });
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }
}
