import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';
import { CoreService } from '../core-services/core.service';
import { Game, GameProvider } from '../core-services/games/games.model';
import { GamesStore } from '../core-services/games/games.store';
import { GameModeEnum } from '../core-services/play/play.model';
import { PlayService } from '../core-services/play/play.service';
import { SeoService } from '../core-services/seo-service/seo.service';
import { DeviceType } from '../core-services/visitor/visitor.model';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { TileComponent } from '../tile/tile.component';
import { TileInterface } from '../tile/tile.model';
import { GameTilesConfig, GamesTilesContentType } from './game-tiles.model';

export const GAME_TILES_SELECTOR: string = 'whg-game-tiles';

@Component({
  selector: GAME_TILES_SELECTOR,
  templateUrl: './game-tiles.component.html',
  styleUrls: ['./game-tiles.component.scss'],
})
export class GameTilesComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('bottom') public bottom?: ElementRef;
  @ViewChildren('tiles') public tilesList!: QueryList<TileComponent>;

  public tiles: TileInterface[] = [];
  public gameTilesConfig!: GameTilesConfig;
  // eslint-disable-next-line no-magic-numbers
  public emptyTilesArray: number[] = Array(24).fill(0);
  public isLoading: boolean = true;
  public trackLastTile: number = 0;
  public loadCount: number = 42;
  public seoJSONfileName: string = this.route.snapshot.data.jsonFile;
  public jsonHTMLSEO$: any;
  public isLoggedIn: boolean = false;

  private deviceType?: string;
  private countryCode: string = '';

  constructor(
    private coreService: CoreService,
    private gamesStore: GamesStore,
    private playService: PlayService,
    private visitorStore: VisitorStore,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    super();
    this.jsonHTMLSEO$ = this.seoService.getHTMLJSON(this.seoJSONfileName);
    this.gameTilesConfig = {
      imageSrc: this.route.snapshot.data.imageSrc ? this.route.snapshot.data.imageSrc.replace('%s', coreService.getCurrentLanguage()) : '',
      contentType: this.route.snapshot.data.contentType,
      category: this.route.snapshot.data.category,
      provider: this.route.snapshot.params.provider,
    };
  }

  public ngAfterViewInit(): void {
    const callback = (): void => {
      this.getGames(this.trackLastTile, (this.loadCount += this.trackLastTile));
    };

    if (this.bottom) {
      const observer: IntersectionObserver = new IntersectionObserver(callback);

      observer.observe(this.bottom.nativeElement);
    }
  }

  public ngOnInit(): void {
    this.getDeviceType();
    this.subscribeUser();
    this.gamesStore.allGameCategories$.subscribe();
  }

  public resize(): void {
    this.tilesList.forEach((tile: TileComponent) => tile.truncateText());
  }

  public setProviderTiles(gameProviders: GameProvider[]): any {
    const ONE: number = 1;

    if (gameProviders.length < ONE) {
      this.isLoading = false;

      return [];
    }

    const tiles: TileInterface[] = gameProviders.map((provider: GameProvider, index: number): TileInterface => {
      // eslint-disable-next-line @typescript-eslint/typedef
      const { title, amount } = provider;

      return {
        id: index,
        title,
        subTitle: `${amount} Games`,
        image: {
          src: this.getProviderImage(title),
          alt: title,
        },
        link: `/games/${title.toLowerCase().split(' ').join('-')}`,
      };
    });

    this.isLoading = false;

    return tiles;
  }

  public getProviderImage(title: string): string {
    return `${this.coreService.scontentUrl}images/providers-white/${title}.png`;
  }

  private getDeviceType(): void {
    this.visitorStore.deviceType$.pipe(first()).subscribe((deviceType: DeviceType | undefined) => {
      this.deviceType = deviceType;
      this.getCountryCode();
    });
  }

  private getCountryCode(): void {
    this.visitorStore.countryCode$.pipe(first()).subscribe((countryCode: string) => {
      this.countryCode = countryCode;
    });
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      // TODO update tile urls after a user logged in
    });
  }

  private getGames(from: number, to: number): void {
    switch (this.gameTilesConfig.contentType) {
      case GamesTilesContentType.ALL:
        this.getAllGames(from, to);
        break;

      case GamesTilesContentType.CATEGORY:
        this.getGameByCategory(from, to);
        break;

      case GamesTilesContentType.PROVIDER_LIST:
        this.getProviderList(from, to);
        break;

      case GamesTilesContentType.PROVIDER:
        this.getProvider(from, to);
        break;
    }
  }

  private getAllGames(from: number, to: number): void {
    this.gamesStore.allGames$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: Game[]) => !!x && x.length > 0)
      )
      .subscribe((games: Game[]) => {
        const nextGames: Game[] = games.slice(from, to);

        this.trackLastTile = to;

        const nextTiles: TileInterface[] = this.setGameTiles(nextGames);

        this.tiles = [...this.tiles, ...nextTiles];
      });
  }

  private getGameByCategory(from: number, to: number): void {
    if (!this.gameTilesConfig.category) {
      return;
    }
    this.gamesStore
      .getGamesCategory(this.gameTilesConfig.category, this.countryCode, this.deviceType === DeviceType.Mobile)
      .pipe(
        switchMap((gamesCategory: string) =>
          this.gamesStore.getGamesOfCategory(gamesCategory).pipe(
            takeUntil(this.destroy$),
            filter((x: Game[]) => !!x && x.length > 0)
          )
        )
      )
      .subscribe((games: Game[]) => {
        const nextGames: Game[] = games.slice(from, to);

        this.trackLastTile = to;

        const nextTiles: TileInterface[] = this.setGameTiles(nextGames);

        this.tiles = [...this.tiles, ...nextTiles];
      });
  }

  private getProvider(from: number, to: number): void {
    if (!this.gameTilesConfig.provider) {
      return;
    }

    this.gamesStore
      .getGamesOfProviderBySeoName(this.gameTilesConfig.provider)
      .pipe(
        takeUntil(this.destroy$),
        filter((x: Game[]) => !!x && x.length > 0)
      )
      .subscribe((games: Game[]) => {
        const nextGames: Game[] = games.slice(from, to);

        this.trackLastTile = to;

        const nextTiles: TileInterface[] = this.setGameTiles(nextGames);

        this.tiles = [...this.tiles, ...nextTiles];
      });

    // TODO: Remove subscribition after light provider (& seo friendly) images are added to scontent
    this.gamesStore.allGameProviders$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: GameProvider[]) => !!x && x.length > 0),
        map((providers: GameProvider[]) =>
          providers.find((provider: GameProvider) => provider.title.toLowerCase().split(' ').join('-') === this.gameTilesConfig.provider)
        ),
        filter((provider: GameProvider | undefined): provider is GameProvider => !!provider)
      )
      .subscribe((provider: GameProvider) => {
        this.gameTilesConfig.imageSrc = this.getProviderImage(provider.title);
      });
  }

  private getProviderList(from: number, to: number): void {
    this.gamesStore.getGameProviders();
    this.gamesStore.allGameProviders$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: GameProvider[]) => !!x && x.length > 0)
      )
      .subscribe((games: GameProvider[]) => {
        const nextGames: GameProvider[] = games.slice(from, to);

        this.trackLastTile = to;

        const nextTiles: TileInterface[] = this.setProviderTiles(nextGames);

        this.tiles = [...this.tiles, ...nextTiles];
      });
  }

  private setGameTiles(games: Game[]): TileInterface[] {
    if (games.length < 1) {
      this.isLoading = false;

      return [];
    }

    const tiles: TileInterface[] = games.map((game: Game) => {
      // eslint-disable-next-line @typescript-eslint/typedef
      const { id, launchcode, name, provider, hot, new: n, image, sub_category } = game;
      // eslint-disable-next-line @typescript-eslint/typedef
      const mode =
        this.gameTilesConfig.contentType === GamesTilesContentType.CATEGORY && this.gameTilesConfig.category === 'freeplay'
          ? GameModeEnum.Fun
          : GameModeEnum.Real;

      const extension: string = sub_category === 'animated' ? 'svg' : 'jpg';

      return {
        id,
        image: {
          src: `${this.coreService.scontentUrl}images/portraitdt/${image}.${extension}`,
          alt: name,
        },
        title: name,
        subTitle: provider,
        game,
        hot,
        new: n,
        link: this.playService.getGameUrl(launchcode, this.isLoggedIn, mode),
      };
    });

    this.isLoading = false;

    return tiles;
  }
}
