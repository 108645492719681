import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Card } from './card.model';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { InfoModalService } from '../info-modal/info-modal.service';

const CARD_SELECTOR = 'whg-card';

@Component({
  selector: CARD_SELECTOR,
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() card!: Card;
  @Input() currencyCode!: string;
  modalRef?: NgbModalRef;

  constructor(private infoModalService: InfoModalService, private ngbModal: NgbModal) { }

  public openModal(): void {
    const resolver = () => {
      this.modalRef = undefined;
      this.infoModalService.setConfig(undefined);
    };

    if (this.card.infoModal) {
      this.infoModalService.setConfig(this.card.infoModal);
    }

    this.modalRef = this.ngbModal.open(InfoModalComponent, { windowClass: 'whg-info-modal--open' });
    this.modalRef.result.then(resolver, resolver);
  }
}
