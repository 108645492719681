import { CurrencyPipe, registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import localeFi from '@angular/common/locales/fi';
import localeDe from '@angular/common/locales/de';
import { countryCurrencyCodes, CountryCodesType, CurrencyCodesType } from './country-currency-codes';

export class CurrencyConverter {
  private static CURRENCY_DEFAULT = 'EUR';

  static to(
    amount: number,
    countryCode: CountryCodesType,
    display: 'symbol' | 'code' | 'symbol-narrow' = 'symbol',
    locale = 'en-US'
  ) {
    locale = this.getLocale(countryCode, locale);
    const currencyCode = countryCurrencyCodes[countryCode] ?? this.CURRENCY_DEFAULT;
    const scale = this.calculateCurrencyScale(currencyCode);

    if (currencyCode === 'BRL') {
      display = 'code';
    }
    if (currencyCode === 'NOK') {
      display = 'symbol-narrow';
    }

    return new CurrencyPipe(locale).transform(amount * scale, currencyCode, display, '1.0-2', locale);
  }

  private static calculateCurrencyScale(currencyCode: CurrencyCodesType): number {
    switch (currencyCode) {
      case 'BRL':
      case 'ZAR':
      case 'NOK':
      case 'SEK':
      case 'PEN':
        return 10;
      case 'INR':
      case 'ARS':
        return 100;
      case 'CLP':
        return 1000;
      default:
        return 1;
    }
  }

  private static getLocale(countryCode: CountryCodesType, locale: string) {
    switch (countryCode.toUpperCase()) {
      case 'DE':
        registerLocaleData(localeDe);
        return 'de-DE';
      case 'FR':
        registerLocaleData(localeFr);
        return 'fr-FR';
      case 'FI':
        registerLocaleData(localeFi);
        return 'fi-FI';
      case 'NO':
      case 'SE':
        registerLocaleData(localeFr);
        return 'fr-FR';
      default:
        return locale;
    }
  }
}
