import { Moment } from 'moment';
import { Promotion, PromotionEnum } from '../promotions-today/promotions-today.model';

export interface CalendarDay {
  moment: Moment;
  day: string;
  isToday: boolean;
  isYesterdayOrBefore: boolean;
  isThisMonth: boolean;
  promotions: Promotion[];
  legends: PromotionLegend[];
}

export interface PromotionLegend {
  text: PromotionEnum;
  color: string;
}

export interface CalendarWeekDay {
  day: string;
  promotions: Promotion[];
}

export enum CalendarViewEnum {
  Month,
  Week,
}
