import { Game } from '../games/games.model';

export interface BiggestPayer {
  amount: number,
  currency: string,
  gameLaunchCode: string,
}

export interface LatestWinsResponse {
  biggestPayers: BiggestPayer[];
}

export class BigWin {
  game: Game;
  biggestPayer: BiggestPayer;

  constructor(game: Game, biggestPayer: BiggestPayer) {
    this.game = game;
    this.biggestPayer = biggestPayer;
  }
}

export const enum ComponentSize {
  FullSize = 'full-size',
  HalfSize = 'half-size'
}
