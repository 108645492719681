import { Injectable } from '@angular/core';
import { WidgetItemTypeEnum } from './gamification-widget.model';

import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GamificationWidgetService {
  public toggle: BehaviorSubject<{ open: boolean; type: WidgetItemTypeEnum | null; id: string | null }> = new BehaviorSubject<{
    open: boolean;
    type: WidgetItemTypeEnum | null;
    id: string | null;
  }>({
    open: false,
    type: null,
    id: null,
  });

  public openWidget(type: WidgetItemTypeEnum | null = null, id: string | null = null): void {
    this.toggle.next({ open: true, type, id });
  }

  public closeWidget(): void {
    this.toggle.next({ open: false, type: null, id: null });
  }
}
