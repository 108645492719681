import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WidgetConfig, WidgetItem, WidgetTypeEnum } from '../gamification-widget/gamification-widget.model';
import { AchievementFilterType, AchievementLink } from '../core-services/achievements/achievements.model';

const CHALLENGES_WIDGET_SELECTOR: string = 'whg-challenges-widget';

@Component({
  selector: CHALLENGES_WIDGET_SELECTOR,
  templateUrl: './challenges-widget.component.html',
  styleUrls: ['./challenges-widget.component.scss'],
})
export class ChallengesWidgetComponent implements OnInit {
  @Input() public config!: WidgetConfig;
  @Input() public isLoggedIn: boolean = false;
  @Input() public userId: number | undefined;
  @Input() public challengeId!: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();
  public widgetTypeEnumList: WidgetTypeEnum = WidgetTypeEnum.list;
  public widgetTypeEnumItem: WidgetTypeEnum = WidgetTypeEnum.item;
  public activeChallenge!: WidgetItem | null;
  public challenges!: WidgetItem[];
  public requireDependentChallenge!: WidgetItem | null;
  public dependencyTree: WidgetItem[] = [];

  public ngOnInit(): void {
    this.setInitialItems();

    if (this.challengeId) {
      const challenge: WidgetItem | undefined = this.challenges.find((c: WidgetItem) => c.id === this.challengeId);

      if (challenge) {
        this.setChallengeItem(challenge);
      }
    }
  }

  public setChallengeItem(challenge: WidgetItem): void {
    this.dependencyTree = [];
    this.activeChallenge = challenge;
    this.requireDependentChallenge = challenge;
    this.config.type = WidgetTypeEnum.item;
    this.setDependentTree();
  }

  public showList(): void {
    this.activeChallenge = null;
    this.config.type = WidgetTypeEnum.list;
  }

  public closeWidget(): void {
    this.close.emit();
  }

  public handleDependencyTreeWidth(): string {
    const TWO: number = 2;
    const THREE: number = 3;
    const FOUR: number = 4;

    return this.dependencyTree.length === TWO
      ? '220px'
      : this.dependencyTree.length === THREE
      ? '80px'
      : this.dependencyTree.length === FOUR
      ? '34px'
      : '12px';
  }

  private setDependentTree(): void {
    this.config.items.forEach((item: WidgetItem) => {
      if (
        item.dependantOn &&
        item.dependantOn.some(
          (i: AchievementLink) => i.achievementId === this.requireDependentChallenge?.id && i.linkType === AchievementFilterType.Must
        )
      ) {
        this.dependencyTree.push(item);
        this.requireDependentChallenge = item;
      }
    });

    // eslint-disable-next-line no-magic-numbers
    if (this.dependencyTree.length > 0 && this.activeChallenge) {
      this.dependencyTree.unshift(this.activeChallenge);
    }
  }

  private setInitialItems(): void {
    this.challenges = this.config?.items.filter((challenge: WidgetItem) => challenge.dependantOn && !challenge.dependantOn.length);
  }
}
