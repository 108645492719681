/*
 *   @TODO - this will be replaced by an endpoint eventually
 */
import { PaymentOption } from '../payment-options/payment-options.model';

export const paymentMethods = [
  {
    name: PaymentOption.ApplePay,
    icon: 'Apple_Pay.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.BancoEstado,
    icon: 'Banco_Estado.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.BankTransfer,
    icon: 'Bank_Transfer.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.BankTransferGB,
    icon: 'Banktransfer_GB.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.BankTransferIBAN,
    icon: 'Banktransfer_IBAN.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.BCP,
    icon: 'BCP.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },

  {
    name: PaymentOption.CajaVecina,
    icon: 'Caja_Vecina.svg',
    excludedJurisdictions: ['sga', 'ukgc'],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.EBankingFI,
    icon: 'EbankingFI.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.EPS,
    icon: 'EPS.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.InteracEtransfer,
    icon: 'Interac_Etransfer.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Interac,
    icon: 'Interac.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Interbank,
    icon: 'Interbank.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Itau,
    icon: 'Itau.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Klarna,
    icon: 'Klarna.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Maestro,
    icon: 'Maestro.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.MastercardDebit,
    icon: 'Mastercard_debit.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Mastercard,
    icon: 'Mastercard.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.MuchBetter,
    icon: 'MuchBetter.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Neosurf,
    icon: 'Neosurf.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Neteller,
    icon: 'Neteller.svg',
    excludedJurisdictions: ['de'],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.OnlineBanking,
    icon: 'Online_Banking.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.OnlineUberwesiung,
    icon: 'Online_Uberwesiung.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.OpenBanking,
    icon: 'Open_Banking.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.PagoEfectivo,
    icon: 'Pago_Efectivo.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.PaySafeCard,
    icon: 'Pay_Safe_Card.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Paypal,
    icon: 'Paypal.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.RapidTransfer,
    icon: 'Rapid_Transfer.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Revolut,
    icon: 'Revolut.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Rupay,
    icon: 'Rupay.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Santander,
    icon: 'Santander.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Sencillit,
    icon: 'Sencillit.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Skrill,
    icon: 'Skrill.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Sofort,
    icon: 'Sofort.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Trustly,
    icon: 'Trustly.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.VisaDebit,
    icon: 'Visa_Debit.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.Visa,
    icon: 'Visa.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.WebPlay,
    icon: 'WebPlay.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
  {
    name: PaymentOption.WesternUnion,
    icon: 'Western_Union.svg',
    excludedJurisdictions: [],
    includedOnlyLanguages: [],
    excludedOnlyLanguages: [],
  },
];
