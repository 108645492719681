<div class="d-flex">
  <div class="d-flex flex-column flex-grow-1">
    <h2>{{ title }}</h2>
    <div class="text-muted subtitle-text">{{ subtitle }}</div>
  </div>
  <div class="actions">
    <span>
      <button
        [ngClass]="{ 'arrow-disable': sliderScrollLeft === 0 }"
        [inlineSVG]="arrowLeft"
        (click)="scrollLeft()"
      ></button>
      <button
        [ngClass]="{ 'arrow-disable': sliderScrollRight === 0 }"
        [inlineSVG]="arrowRight"
        (click)="scrollRight()"
      ></button>
    </span>
  </div>
</div>
<div #slider class="highlights-tiles">
  <ng-container *ngFor="let tile of highLights">
    <whg-highlights-tile [tile]="tile"></whg-highlights-tile>
  </ng-container>
</div>
