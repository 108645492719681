export enum PromotionEnum {
  Cashback = 'cashback',
  CashDrop = 'cash drop',
  LoyaltyBooster = 'loyalty booster',
  WinAnExperience = 'win an experience',
  SuperSlotsSunday = 'super slots sunday',
  RealWorldRewards = 'real world rewards',
  NewGameSpin = 'new game spins',
  VegasLoungeVault = 'vegas lounge vault',
  MonthEndDoubleTake = 'month end Double take',
  RushHour = 'Rush Hour',
  SpinTheWheel = 'spin the wheel',
  ScratchCard = 'scratch card',
}

export interface Promotion {
  id: string;
  type: PromotionType;
  promoType: PromotionEnum;
  color: string;
  scheduledStart: Date;
  scheduledEnd: Date;
  title: string;
  image: string;
  terms?: string;
  sigTerms: string;
}

export type PromotionType = 'tournament' | 'challenge';

export interface PromotionLegend {
  text: PromotionEnum;
  color: string;
}
